const EMOJIS = [
    ["grinning_face", "😀"],
    ["grinning_face_with_big_eyes", "😃"],
    ["grinning_face_with_smiling_eyes", "😄"],
    ["beaming_face_with_smiling_eyes", "😁"],
    ["grinning_squinting_face", "😆"],
    ["grinning_face_with_sweat", "😅"],
    ["rolling_on_the_floor_laughing", "🤣"],
    ["face_with_tears_of_joy", "😂"],
    ["slightly_smiling_face", "🙂"],
    ["upside-down_face", "🙃"],
    ["winking_face", "😉"],
    ["smiling_face_with_smiling_eyes", "😊"],
    ["smiling_face_with_halo", "😇"],
    ["smiling_face_with_hearts", "🥰"],
    ["smiling_face_with_heart-eyes", "😍"],
    ["star-struck", "🤩"],
    ["face_blowing_a_kiss", "😘"],
    ["kissing_face", "😗"],
    ["smiling_face", "☺️"],
    ["kissing_face_with_closed_eyes", "😚"],
    ["kissing_face_with_smiling_eyes", "😙"],
    ["smiling_face_with_tear", "🥲"],
    ["face_savoring_food", "😋"],
    ["face_with_tongue", "😛"],
    ["winking_face_with_tongue", "😜"],
    ["zany_face", "🤪"],
    ["squinting_face_with_tongue", "😝"],
    ["money-mouth_face", "🤑"],
    ["hugging_face", "🤗"],
    ["face_with_hand_over_mouth", "🤭"],
    ["shushing_face", "🤫"],
    ["thinking_face", "🤔"],
    ["zipper-mouth_face", "🤐"],
    ["face_with_raised_eyebrow", "🤨"],
    ["neutral_face", "😐"],
    ["expressionless_face", "😑"],
    ["face_without_mouth", "😶"],
    ["face_in_clouds", "😶‍🌫️"],
    ["smirking_face", "😏"],
    ["unamused_face", "😒"],
    ["face_with_rolling_eyes", "🙄"],
    ["grimacing_face", "😬"],
    ["face_exhaling", "😮‍💨"],
    ["lying_face", "🤥"],
    ["relieved_face", "😌"],
    ["pensive_face", "😔"],
    ["sleepy_face", "😪"],
    ["drooling_face", "🤤"],
    ["sleeping_face", "😴"],
    ["face_with_medical_mask", "😷"],
    ["face_with_thermometer", "🤒"],
    ["face_with_head-bandage", "🤕"],
    ["nauseated_face", "🤢"],
    ["face_vomiting", "🤮"],
    ["sneezing_face", "🤧"],
    ["hot_face", "🥵"],
    ["cold_face", "🥶"],
    ["woozy_face", "🥴"],
    ["knocked-out_face", "😵"],
    ["face_with_spiral_eyes", "😵‍💫"],
    ["exploding_head", "🤯"],
    ["cowboy_hat_face", "🤠"],
    ["partying_face", "🥳"],
    ["disguised_face", "🥸"],
    ["smiling_face_with_sunglasses", "😎"],
    ["nerd_face", "🤓"],
    ["face_with_monocle", "🧐"],
    ["confused_face", "😕"],
    ["worried_face", "😟"],
    ["slightly_frowning_face", "🙁"],
    ["frowning_face", "☹️"],
    ["face_with_open_mouth", "😮"],
    ["hushed_face", "😯"],
    ["astonished_face", "😲"],
    ["flushed_face", "😳"],
    ["pleading_face", "🥺"],
    ["frowning_face_with_open_mouth", "😦"],
    ["anguished_face", "😧"],
    ["fearful_face", "😨"],
    ["anxious_face_with_sweat", "😰"],
    ["sad_but_relieved_face", "😥"],
    ["crying_face", "😢"],
    ["loudly_crying_face", "😭"],
    ["face_screaming_in_fear", "😱"],
    ["confounded_face", "😖"],
    ["persevering_face", "😣"],
    ["disappointed_face", "😞"],
    ["downcast_face_with_sweat", "😓"],
    ["weary_face", "😩"],
    ["tired_face", "😫"],
    ["yawning_face", "🥱"],
    ["face_with_steam_from_nose", "😤"],
    ["pouting_face", "😡"],
    ["angry_face", "😠"],
    ["face_with_symbols_on_mouth", "🤬"],
    ["smiling_face_with_horns", "😈"],
    ["angry_face_with_horns", "👿"],
    ["skull", "💀"],
    ["skull_and_crossbones", "☠️"],
    ["pile_of_poo", "💩"],
    ["clown_face", "🤡"],
    ["ogre", "👹"],
    ["goblin", "👺"],
    ["ghost", "👻"],
    ["alien", "👽"],
    ["alien_monster", "👾"],
    ["robot", "🤖"],
    ["grinning_cat", "😺"],
    ["grinning_cat_with_smiling_eyes", "😸"],
    ["cat_with_tears_of_joy", "😹"],
    ["smiling_cat_with_heart-eyes", "😻"],
    ["cat_with_wry_smile", "😼"],
    ["kissing_cat", "😽"],
    ["weary_cat", "🙀"],
    ["crying_cat", "😿"],
    ["pouting_cat", "😾"],
    ["see-no-evil_monkey", "🙈"],
    ["hear-no-evil_monkey", "🙉"],
    ["speak-no-evil_monkey", "🙊"],
    ["kiss_mark", "💋"],
    ["love_letter", "💌"],
    ["heart_with_arrow", "💘"],
    ["heart_with_ribbon", "💝"],
    ["sparkling_heart", "💖"],
    ["growing_heart", "💗"],
    ["beating_heart", "💓"],
    ["revolving_hearts", "💞"],
    ["two_hearts", "💕"],
    ["heart_decoration", "💟"],
    ["heart_exclamation", "❣️"],
    ["broken_heart", "💔"],
    ["heart_on_fire", "❤️‍🔥"],
    ["mending_heart", "❤️‍🩹"],
    ["red_heart", "❤️"],
    ["orange_heart", "🧡"],
    ["yellow_heart", "💛"],
    ["green_heart", "💚"],
    ["blue_heart", "💙"],
    ["purple_heart", "💜"],
    ["brown_heart", "🤎"],
    ["black_heart", "🖤"],
    ["white_heart", "🤍"],
    ["hundred_points", "💯"],
    ["anger_symbol", "💢"],
    ["collision", "💥"],
    ["dizzy", "💫"],
    ["sweat_droplets", "💦"],
    ["dashing_away", "💨"],
    ["hole", "🕳️"],
    ["bomb", "💣"],
    ["speech_balloon", "💬"],
    ["eye_in_speech_bubble", "👁️‍🗨️"],
    ["left_speech_bubble", "🗨️"],
    ["right_anger_bubble", "🗯️"],
    ["thought_balloon", "💭"],
    ["zzz", "💤"],
    ["waving_hand", "👋"],
    ["waving_hand_light_skin_tone", "👋🏻"],
    ["waving_hand_medium-light_skin_tone", "👋🏼"],
    ["waving_hand_medium_skin_tone", "👋🏽"],
    ["waving_hand_medium-dark_skin_tone", "👋🏾"],
    ["waving_hand_dark_skin_tone", "👋🏿"],
    ["raised_back_of_hand", "🤚"],
    ["raised_back_of_hand_light_skin_tone", "🤚🏻"],
    ["raised_back_of_hand_medium-light_skin_tone", "🤚🏼"],
    ["raised_back_of_hand_medium_skin_tone", "🤚🏽"],
    ["raised_back_of_hand_medium-dark_skin_tone", "🤚🏾"],
    ["raised_back_of_hand_dark_skin_tone", "🤚🏿"],
    ["hand_with_fingers_splayed", "🖐️"],
    ["hand_with_fingers_splayed_light_skin_tone", "🖐🏻"],
    ["hand_with_fingers_splayed_medium-light_skin_tone", "🖐🏼"],
    ["hand_with_fingers_splayed_medium_skin_tone", "🖐🏽"],
    ["hand_with_fingers_splayed_medium-dark_skin_tone", "🖐🏾"],
    ["hand_with_fingers_splayed_dark_skin_tone", "🖐🏿"],
    ["raised_hand", "✋"],
    ["raised_hand_light_skin_tone", "✋🏻"],
    ["raised_hand_medium-light_skin_tone", "✋🏼"],
    ["raised_hand_medium_skin_tone", "✋🏽"],
    ["raised_hand_medium-dark_skin_tone", "✋🏾"],
    ["raised_hand_dark_skin_tone", "✋🏿"],
    ["vulcan_salute", "🖖"],
    ["vulcan_salute_light_skin_tone", "🖖🏻"],
    ["vulcan_salute_medium-light_skin_tone", "🖖🏼"],
    ["vulcan_salute_medium_skin_tone", "🖖🏽"],
    ["vulcan_salute_medium-dark_skin_tone", "🖖🏾"],
    ["vulcan_salute_dark_skin_tone", "🖖🏿"],
    ["OK_hand", "👌"],
    ["OK_hand_light_skin_tone", "👌🏻"],
    ["OK_hand_medium-light_skin_tone", "👌🏼"],
    ["OK_hand_medium_skin_tone", "👌🏽"],
    ["OK_hand_medium-dark_skin_tone", "👌🏾"],
    ["OK_hand_dark_skin_tone", "👌🏿"],
    ["pinched_fingers", "🤌"],
    ["pinched_fingers_light_skin_tone", "🤌🏻"],
    ["pinched_fingers_medium-light_skin_tone", "🤌🏼"],
    ["pinched_fingers_medium_skin_tone", "🤌🏽"],
    ["pinched_fingers_medium-dark_skin_tone", "🤌🏾"],
    ["pinched_fingers_dark_skin_tone", "🤌🏿"],
    ["pinching_hand", "🤏"],
    ["pinching_hand_light_skin_tone", "🤏🏻"],
    ["pinching_hand_medium-light_skin_tone", "🤏🏼"],
    ["pinching_hand_medium_skin_tone", "🤏🏽"],
    ["pinching_hand_medium-dark_skin_tone", "🤏🏾"],
    ["pinching_hand_dark_skin_tone", "🤏🏿"],
    ["victory_hand", "✌️"],
    ["victory_hand_light_skin_tone", "✌🏻"],
    ["victory_hand_medium-light_skin_tone", "✌🏼"],
    ["victory_hand_medium_skin_tone", "✌🏽"],
    ["victory_hand_medium-dark_skin_tone", "✌🏾"],
    ["victory_hand_dark_skin_tone", "✌🏿"],
    ["crossed_fingers", "🤞"],
    ["crossed_fingers_light_skin_tone", "🤞🏻"],
    ["crossed_fingers_medium-light_skin_tone", "🤞🏼"],
    ["crossed_fingers_medium_skin_tone", "🤞🏽"],
    ["crossed_fingers_medium-dark_skin_tone", "🤞🏾"],
    ["crossed_fingers_dark_skin_tone", "🤞🏿"],
    ["love-you_gesture", "🤟"],
    ["love-you_gesture_light_skin_tone", "🤟🏻"],
    ["love-you_gesture_medium-light_skin_tone", "🤟🏼"],
    ["love-you_gesture_medium_skin_tone", "🤟🏽"],
    ["love-you_gesture_medium-dark_skin_tone", "🤟🏾"],
    ["love-you_gesture_dark_skin_tone", "🤟🏿"],
    ["sign_of_the_horns", "🤘"],
    ["sign_of_the_horns_light_skin_tone", "🤘🏻"],
    ["sign_of_the_horns_medium-light_skin_tone", "🤘🏼"],
    ["sign_of_the_horns_medium_skin_tone", "🤘🏽"],
    ["sign_of_the_horns_medium-dark_skin_tone", "🤘🏾"],
    ["sign_of_the_horns_dark_skin_tone", "🤘🏿"],
    ["call_me_hand", "🤙"],
    ["call_me_hand_light_skin_tone", "🤙🏻"],
    ["call_me_hand_medium-light_skin_tone", "🤙🏼"],
    ["call_me_hand_medium_skin_tone", "🤙🏽"],
    ["call_me_hand_medium-dark_skin_tone", "🤙🏾"],
    ["call_me_hand_dark_skin_tone", "🤙🏿"],
    ["backhand_index_pointing_left", "👈"],
    ["backhand_index_pointing_left_light_skin_tone", "👈🏻"],
    ["backhand_index_pointing_left_medium-light_skin_tone", "👈🏼"],
    ["backhand_index_pointing_left_medium_skin_tone", "👈🏽"],
    ["backhand_index_pointing_left_medium-dark_skin_tone", "👈🏾"],
    ["backhand_index_pointing_left_dark_skin_tone", "👈🏿"],
    ["backhand_index_pointing_right", "👉"],
    ["backhand_index_pointing_right_light_skin_tone", "👉🏻"],
    ["backhand_index_pointing_right_medium-light_skin_tone", "👉🏼"],
    ["backhand_index_pointing_right_medium_skin_tone", "👉🏽"],
    ["backhand_index_pointing_right_medium-dark_skin_tone", "👉🏾"],
    ["backhand_index_pointing_right_dark_skin_tone", "👉🏿"],
    ["backhand_index_pointing_up", "👆"],
    ["backhand_index_pointing_up_light_skin_tone", "👆🏻"],
    ["backhand_index_pointing_up_medium-light_skin_tone", "👆🏼"],
    ["backhand_index_pointing_up_medium_skin_tone", "👆🏽"],
    ["backhand_index_pointing_up_medium-dark_skin_tone", "👆🏾"],
    ["backhand_index_pointing_up_dark_skin_tone", "👆🏿"],
    ["middle_finger", "🖕"],
    ["middle_finger_light_skin_tone", "🖕🏻"],
    ["middle_finger_medium-light_skin_tone", "🖕🏼"],
    ["middle_finger_medium_skin_tone", "🖕🏽"],
    ["middle_finger_medium-dark_skin_tone", "🖕🏾"],
    ["middle_finger_dark_skin_tone", "🖕🏿"],
    ["backhand_index_pointing_down", "👇"],
    ["backhand_index_pointing_down_light_skin_tone", "👇🏻"],
    ["backhand_index_pointing_down_medium-light_skin_tone", "👇🏼"],
    ["backhand_index_pointing_down_medium_skin_tone", "👇🏽"],
    ["backhand_index_pointing_down_medium-dark_skin_tone", "👇🏾"],
    ["backhand_index_pointing_down_dark_skin_tone", "👇🏿"],
    ["index_pointing_up", "☝️"],
    ["index_pointing_up_light_skin_tone", "☝🏻"],
    ["index_pointing_up_medium-light_skin_tone", "☝🏼"],
    ["index_pointing_up_medium_skin_tone", "☝🏽"],
    ["index_pointing_up_medium-dark_skin_tone", "☝🏾"],
    ["index_pointing_up_dark_skin_tone", "☝🏿"],
    ["thumbs_up", "👍"],
    ["thumbs_up_light_skin_tone", "👍🏻"],
    ["thumbs_up_medium-light_skin_tone", "👍🏼"],
    ["thumbs_up_medium_skin_tone", "👍🏽"],
    ["thumbs_up_medium-dark_skin_tone", "👍🏾"],
    ["thumbs_up_dark_skin_tone", "👍🏿"],
    ["thumbs_down", "👎"],
    ["thumbs_down_light_skin_tone", "👎🏻"],
    ["thumbs_down_medium-light_skin_tone", "👎🏼"],
    ["thumbs_down_medium_skin_tone", "👎🏽"],
    ["thumbs_down_medium-dark_skin_tone", "👎🏾"],
    ["thumbs_down_dark_skin_tone", "👎🏿"],
    ["raised_fist", "✊"],
    ["raised_fist_light_skin_tone", "✊🏻"],
    ["raised_fist_medium-light_skin_tone", "✊🏼"],
    ["raised_fist_medium_skin_tone", "✊🏽"],
    ["raised_fist_medium-dark_skin_tone", "✊🏾"],
    ["raised_fist_dark_skin_tone", "✊🏿"],
    ["oncoming_fist", "👊"],
    ["oncoming_fist_light_skin_tone", "👊🏻"],
    ["oncoming_fist_medium-light_skin_tone", "👊🏼"],
    ["oncoming_fist_medium_skin_tone", "👊🏽"],
    ["oncoming_fist_medium-dark_skin_tone", "👊🏾"],
    ["oncoming_fist_dark_skin_tone", "👊🏿"],
    ["left-facing_fist", "🤛"],
    ["left-facing_fist_light_skin_tone", "🤛🏻"],
    ["left-facing_fist_medium-light_skin_tone", "🤛🏼"],
    ["left-facing_fist_medium_skin_tone", "🤛🏽"],
    ["left-facing_fist_medium-dark_skin_tone", "🤛🏾"],
    ["left-facing_fist_dark_skin_tone", "🤛🏿"],
    ["right-facing_fist", "🤜"],
    ["right-facing_fist_light_skin_tone", "🤜🏻"],
    ["right-facing_fist_medium-light_skin_tone", "🤜🏼"],
    ["right-facing_fist_medium_skin_tone", "🤜🏽"],
    ["right-facing_fist_medium-dark_skin_tone", "🤜🏾"],
    ["right-facing_fist_dark_skin_tone", "🤜🏿"],
    ["clapping_hands", "👏"],
    ["clapping_hands_light_skin_tone", "👏🏻"],
    ["clapping_hands_medium-light_skin_tone", "👏🏼"],
    ["clapping_hands_medium_skin_tone", "👏🏽"],
    ["clapping_hands_medium-dark_skin_tone", "👏🏾"],
    ["clapping_hands_dark_skin_tone", "👏🏿"],
    ["raising_hands", "🙌"],
    ["raising_hands_light_skin_tone", "🙌🏻"],
    ["raising_hands_medium-light_skin_tone", "🙌🏼"],
    ["raising_hands_medium_skin_tone", "🙌🏽"],
    ["raising_hands_medium-dark_skin_tone", "🙌🏾"],
    ["raising_hands_dark_skin_tone", "🙌🏿"],
    ["open_hands", "👐"],
    ["open_hands_light_skin_tone", "👐🏻"],
    ["open_hands_medium-light_skin_tone", "👐🏼"],
    ["open_hands_medium_skin_tone", "👐🏽"],
    ["open_hands_medium-dark_skin_tone", "👐🏾"],
    ["open_hands_dark_skin_tone", "👐🏿"],
    ["palms_up_together", "🤲"],
    ["palms_up_together_light_skin_tone", "🤲🏻"],
    ["palms_up_together_medium-light_skin_tone", "🤲🏼"],
    ["palms_up_together_medium_skin_tone", "🤲🏽"],
    ["palms_up_together_medium-dark_skin_tone", "🤲🏾"],
    ["palms_up_together_dark_skin_tone", "🤲🏿"],
    ["handshake", "🤝"],
    ["folded_hands", "🙏"],
    ["folded_hands_light_skin_tone", "🙏🏻"],
    ["folded_hands_medium-light_skin_tone", "🙏🏼"],
    ["folded_hands_medium_skin_tone", "🙏🏽"],
    ["folded_hands_medium-dark_skin_tone", "🙏🏾"],
    ["folded_hands_dark_skin_tone", "🙏🏿"],
    ["writing_hand", "✍️"],
    ["writing_hand_light_skin_tone", "✍🏻"],
    ["writing_hand_medium-light_skin_tone", "✍🏼"],
    ["writing_hand_medium_skin_tone", "✍🏽"],
    ["writing_hand_medium-dark_skin_tone", "✍🏾"],
    ["writing_hand_dark_skin_tone", "✍🏿"],
    ["nail_polish", "💅"],
    ["nail_polish_light_skin_tone", "💅🏻"],
    ["nail_polish_medium-light_skin_tone", "💅🏼"],
    ["nail_polish_medium_skin_tone", "💅🏽"],
    ["nail_polish_medium-dark_skin_tone", "💅🏾"],
    ["nail_polish_dark_skin_tone", "💅🏿"],
    ["selfie", "🤳"],
    ["selfie_light_skin_tone", "🤳🏻"],
    ["selfie_medium-light_skin_tone", "🤳🏼"],
    ["selfie_medium_skin_tone", "🤳🏽"],
    ["selfie_medium-dark_skin_tone", "🤳🏾"],
    ["selfie_dark_skin_tone", "🤳🏿"],
    ["flexed_biceps", "💪"],
    ["flexed_biceps_light_skin_tone", "💪🏻"],
    ["flexed_biceps_medium-light_skin_tone", "💪🏼"],
    ["flexed_biceps_medium_skin_tone", "💪🏽"],
    ["flexed_biceps_medium-dark_skin_tone", "💪🏾"],
    ["flexed_biceps_dark_skin_tone", "💪🏿"],
    ["mechanical_arm", "🦾"],
    ["mechanical_leg", "🦿"],
    ["leg", "🦵"],
    ["leg_light_skin_tone", "🦵🏻"],
    ["leg_medium-light_skin_tone", "🦵🏼"],
    ["leg_medium_skin_tone", "🦵🏽"],
    ["leg_medium-dark_skin_tone", "🦵🏾"],
    ["leg_dark_skin_tone", "🦵🏿"],
    ["foot", "🦶"],
    ["foot_light_skin_tone", "🦶🏻"],
    ["foot_medium-light_skin_tone", "🦶🏼"],
    ["foot_medium_skin_tone", "🦶🏽"],
    ["foot_medium-dark_skin_tone", "🦶🏾"],
    ["foot_dark_skin_tone", "🦶🏿"],
    ["ear", "👂"],
    ["ear_light_skin_tone", "👂🏻"],
    ["ear_medium-light_skin_tone", "👂🏼"],
    ["ear_medium_skin_tone", "👂🏽"],
    ["ear_medium-dark_skin_tone", "👂🏾"],
    ["ear_dark_skin_tone", "👂🏿"],
    ["ear_with_hearing_aid", "🦻"],
    ["ear_with_hearing_aid_light_skin_tone", "🦻🏻"],
    ["ear_with_hearing_aid_medium-light_skin_tone", "🦻🏼"],
    ["ear_with_hearing_aid_medium_skin_tone", "🦻🏽"],
    ["ear_with_hearing_aid_medium-dark_skin_tone", "🦻🏾"],
    ["ear_with_hearing_aid_dark_skin_tone", "🦻🏿"],
    ["nose", "👃"],
    ["nose_light_skin_tone", "👃🏻"],
    ["nose_medium-light_skin_tone", "👃🏼"],
    ["nose_medium_skin_tone", "👃🏽"],
    ["nose_medium-dark_skin_tone", "👃🏾"],
    ["nose_dark_skin_tone", "👃🏿"],
    ["brain", "🧠"],
    ["anatomical_heart", "🫀"],
    ["lungs", "🫁"],
    ["tooth", "🦷"],
    ["bone", "🦴"],
    ["eyes", "👀"],
    ["eye", "👁️"],
    ["tongue", "👅"],
    ["mouth", "👄"],
    ["baby", "👶"],
    ["baby_light_skin_tone", "👶🏻"],
    ["baby_medium-light_skin_tone", "👶🏼"],
    ["baby_medium_skin_tone", "👶🏽"],
    ["baby_medium-dark_skin_tone", "👶🏾"],
    ["baby_dark_skin_tone", "👶🏿"],
    ["child", "🧒"],
    ["child_light_skin_tone", "🧒🏻"],
    ["child_medium-light_skin_tone", "🧒🏼"],
    ["child_medium_skin_tone", "🧒🏽"],
    ["child_medium-dark_skin_tone", "🧒🏾"],
    ["child_dark_skin_tone", "🧒🏿"],
    ["boy", "👦"],
    ["boy_light_skin_tone", "👦🏻"],
    ["boy_medium-light_skin_tone", "👦🏼"],
    ["boy_medium_skin_tone", "👦🏽"],
    ["boy_medium-dark_skin_tone", "👦🏾"],
    ["boy_dark_skin_tone", "👦🏿"],
    ["girl", "👧"],
    ["girl_light_skin_tone", "👧🏻"],
    ["girl_medium-light_skin_tone", "👧🏼"],
    ["girl_medium_skin_tone", "👧🏽"],
    ["girl_medium-dark_skin_tone", "👧🏾"],
    ["girl_dark_skin_tone", "👧🏿"],
    ["person", "🧑"],
    ["person_light_skin_tone", "🧑🏻"],
    ["person_medium-light_skin_tone", "🧑🏼"],
    ["person_medium_skin_tone", "🧑🏽"],
    ["person_medium-dark_skin_tone", "🧑🏾"],
    ["person_dark_skin_tone", "🧑🏿"],
    ["person_blond_hair", "👱"],
    ["person_light_skin_tone,_blond_hair", "👱🏻"],
    ["person_medium-light_skin_tone,_blond_hair", "👱🏼"],
    ["person_medium_skin_tone,_blond_hair", "👱🏽"],
    ["person_medium-dark_skin_tone,_blond_hair", "👱🏾"],
    ["person_dark_skin_tone,_blond_hair", "👱🏿"],
    ["man", "👨"],
    ["man_light_skin_tone", "👨🏻"],
    ["man_medium-light_skin_tone", "👨🏼"],
    ["man_medium_skin_tone", "👨🏽"],
    ["man_medium-dark_skin_tone", "👨🏾"],
    ["man_dark_skin_tone", "👨🏿"],
    ["person_beard", "🧔"],
    ["person_light_skin_tone,_beard", "🧔🏻"],
    ["person_medium-light_skin_tone,_beard", "🧔🏼"],
    ["person_medium_skin_tone,_beard", "🧔🏽"],
    ["person_medium-dark_skin_tone,_beard", "🧔🏾"],
    ["person_dark_skin_tone,_beard", "🧔🏿"],
    ["man_beard", "🧔‍♂️"],
    ["man_light_skin_tone,_beard", "🧔🏻‍♂️"],
    ["man_medium-light_skin_tone,_beard", "🧔🏼‍♂️"],
    ["man_medium_skin_tone,_beard", "🧔🏽‍♂️"],
    ["man_medium-dark_skin_tone,_beard", "🧔🏾‍♂️"],
    ["man_dark_skin_tone,_beard", "🧔🏿‍♂️"],
    ["woman_beard", "🧔‍♀️"],
    ["woman_light_skin_tone,_beard", "🧔🏻‍♀️"],
    ["woman_medium-light_skin_tone,_beard", "🧔🏼‍♀️"],
    ["woman_medium_skin_tone,_beard", "🧔🏽‍♀️"],
    ["woman_medium-dark_skin_tone,_beard", "🧔🏾‍♀️"],
    ["woman_dark_skin_tone,_beard", "🧔🏿‍♀️"],
    ["man_red_hair", "👨‍🦰"],
    ["man_light_skin_tone,_red_hair", "👨🏻‍🦰"],
    ["man_medium-light_skin_tone,_red_hair", "👨🏼‍🦰"],
    ["man_medium_skin_tone,_red_hair", "👨🏽‍🦰"],
    ["man_medium-dark_skin_tone,_red_hair", "👨🏾‍🦰"],
    ["man_dark_skin_tone,_red_hair", "👨🏿‍🦰"],
    ["man_curly_hair", "👨‍🦱"],
    ["man_light_skin_tone,_curly_hair", "👨🏻‍🦱"],
    ["man_medium-light_skin_tone,_curly_hair", "👨🏼‍🦱"],
    ["man_medium_skin_tone,_curly_hair", "👨🏽‍🦱"],
    ["man_medium-dark_skin_tone,_curly_hair", "👨🏾‍🦱"],
    ["man_dark_skin_tone,_curly_hair", "👨🏿‍🦱"],
    ["man_white_hair", "👨‍🦳"],
    ["man_light_skin_tone,_white_hair", "👨🏻‍🦳"],
    ["man_medium-light_skin_tone,_white_hair", "👨🏼‍🦳"],
    ["man_medium_skin_tone,_white_hair", "👨🏽‍🦳"],
    ["man_medium-dark_skin_tone,_white_hair", "👨🏾‍🦳"],
    ["man_dark_skin_tone,_white_hair", "👨🏿‍🦳"],
    ["man_bald", "👨‍🦲"],
    ["man_light_skin_tone,_bald", "👨🏻‍🦲"],
    ["man_medium-light_skin_tone,_bald", "👨🏼‍🦲"],
    ["man_medium_skin_tone,_bald", "👨🏽‍🦲"],
    ["man_medium-dark_skin_tone,_bald", "👨🏾‍🦲"],
    ["man_dark_skin_tone,_bald", "👨🏿‍🦲"],
    ["woman", "👩"],
    ["woman_light_skin_tone", "👩🏻"],
    ["woman_medium-light_skin_tone", "👩🏼"],
    ["woman_medium_skin_tone", "👩🏽"],
    ["woman_medium-dark_skin_tone", "👩🏾"],
    ["woman_dark_skin_tone", "👩🏿"],
    ["woman_red_hair", "👩‍🦰"],
    ["woman_light_skin_tone,_red_hair", "👩🏻‍🦰"],
    ["woman_medium-light_skin_tone,_red_hair", "👩🏼‍🦰"],
    ["woman_medium_skin_tone,_red_hair", "👩🏽‍🦰"],
    ["woman_medium-dark_skin_tone,_red_hair", "👩🏾‍🦰"],
    ["woman_dark_skin_tone,_red_hair", "👩🏿‍🦰"],
    ["person_red_hair", "🧑‍🦰"],
    ["person_light_skin_tone,_red_hair", "🧑🏻‍🦰"],
    ["person_medium-light_skin_tone,_red_hair", "🧑🏼‍🦰"],
    ["person_medium_skin_tone,_red_hair", "🧑🏽‍🦰"],
    ["person_medium-dark_skin_tone,_red_hair", "🧑🏾‍🦰"],
    ["person_dark_skin_tone,_red_hair", "🧑🏿‍🦰"],
    ["woman_curly_hair", "👩‍🦱"],
    ["woman_light_skin_tone,_curly_hair", "👩🏻‍🦱"],
    ["woman_medium-light_skin_tone,_curly_hair", "👩🏼‍🦱"],
    ["woman_medium_skin_tone,_curly_hair", "👩🏽‍🦱"],
    ["woman_medium-dark_skin_tone,_curly_hair", "👩🏾‍🦱"],
    ["woman_dark_skin_tone,_curly_hair", "👩🏿‍🦱"],
    ["person_curly_hair", "🧑‍🦱"],
    ["person_light_skin_tone,_curly_hair", "🧑🏻‍🦱"],
    ["person_medium-light_skin_tone,_curly_hair", "🧑🏼‍🦱"],
    ["person_medium_skin_tone,_curly_hair", "🧑🏽‍🦱"],
    ["person_medium-dark_skin_tone,_curly_hair", "🧑🏾‍🦱"],
    ["person_dark_skin_tone,_curly_hair", "🧑🏿‍🦱"],
    ["woman_white_hair", "👩‍🦳"],
    ["woman_light_skin_tone,_white_hair", "👩🏻‍🦳"],
    ["woman_medium-light_skin_tone,_white_hair", "👩🏼‍🦳"],
    ["woman_medium_skin_tone,_white_hair", "👩🏽‍🦳"],
    ["woman_medium-dark_skin_tone,_white_hair", "👩🏾‍🦳"],
    ["woman_dark_skin_tone,_white_hair", "👩🏿‍🦳"],
    ["person_white_hair", "🧑‍🦳"],
    ["person_light_skin_tone,_white_hair", "🧑🏻‍🦳"],
    ["person_medium-light_skin_tone,_white_hair", "🧑🏼‍🦳"],
    ["person_medium_skin_tone,_white_hair", "🧑🏽‍🦳"],
    ["person_medium-dark_skin_tone,_white_hair", "🧑🏾‍🦳"],
    ["person_dark_skin_tone,_white_hair", "🧑🏿‍🦳"],
    ["woman_bald", "👩‍🦲"],
    ["woman_light_skin_tone,_bald", "👩🏻‍🦲"],
    ["woman_medium-light_skin_tone,_bald", "👩🏼‍🦲"],
    ["woman_medium_skin_tone,_bald", "👩🏽‍🦲"],
    ["woman_medium-dark_skin_tone,_bald", "👩🏾‍🦲"],
    ["woman_dark_skin_tone,_bald", "👩🏿‍🦲"],
    ["person_bald", "🧑‍🦲"],
    ["person_light_skin_tone,_bald", "🧑🏻‍🦲"],
    ["person_medium-light_skin_tone,_bald", "🧑🏼‍🦲"],
    ["person_medium_skin_tone,_bald", "🧑🏽‍🦲"],
    ["person_medium-dark_skin_tone,_bald", "🧑🏾‍🦲"],
    ["person_dark_skin_tone,_bald", "🧑🏿‍🦲"],
    ["woman_blond_hair", "👱‍♀️"],
    ["woman_light_skin_tone,_blond_hair", "👱🏻‍♀️"],
    ["woman_medium-light_skin_tone,_blond_hair", "👱🏼‍♀️"],
    ["woman_medium_skin_tone,_blond_hair", "👱🏽‍♀️"],
    ["woman_medium-dark_skin_tone,_blond_hair", "👱🏾‍♀️"],
    ["woman_dark_skin_tone,_blond_hair", "👱🏿‍♀️"],
    ["man_blond_hair", "👱‍♂️"],
    ["man_light_skin_tone,_blond_hair", "👱🏻‍♂️"],
    ["man_medium-light_skin_tone,_blond_hair", "👱🏼‍♂️"],
    ["man_medium_skin_tone,_blond_hair", "👱🏽‍♂️"],
    ["man_medium-dark_skin_tone,_blond_hair", "👱🏾‍♂️"],
    ["man_dark_skin_tone,_blond_hair", "👱🏿‍♂️"],
    ["older_person", "🧓"],
    ["older_person_light_skin_tone", "🧓🏻"],
    ["older_person_medium-light_skin_tone", "🧓🏼"],
    ["older_person_medium_skin_tone", "🧓🏽"],
    ["older_person_medium-dark_skin_tone", "🧓🏾"],
    ["older_person_dark_skin_tone", "🧓🏿"],
    ["old_man", "👴"],
    ["old_man_light_skin_tone", "👴🏻"],
    ["old_man_medium-light_skin_tone", "👴🏼"],
    ["old_man_medium_skin_tone", "👴🏽"],
    ["old_man_medium-dark_skin_tone", "👴🏾"],
    ["old_man_dark_skin_tone", "👴🏿"],
    ["old_woman", "👵"],
    ["old_woman_light_skin_tone", "👵🏻"],
    ["old_woman_medium-light_skin_tone", "👵🏼"],
    ["old_woman_medium_skin_tone", "👵🏽"],
    ["old_woman_medium-dark_skin_tone", "👵🏾"],
    ["old_woman_dark_skin_tone", "👵🏿"],
    ["person_frowning", "🙍"],
    ["person_frowning_light_skin_tone", "🙍🏻"],
    ["person_frowning_medium-light_skin_tone", "🙍🏼"],
    ["person_frowning_medium_skin_tone", "🙍🏽"],
    ["person_frowning_medium-dark_skin_tone", "🙍🏾"],
    ["person_frowning_dark_skin_tone", "🙍🏿"],
    ["man_frowning", "🙍‍♂️"],
    ["man_frowning_light_skin_tone", "🙍🏻‍♂️"],
    ["man_frowning_medium-light_skin_tone", "🙍🏼‍♂️"],
    ["man_frowning_medium_skin_tone", "🙍🏽‍♂️"],
    ["man_frowning_medium-dark_skin_tone", "🙍🏾‍♂️"],
    ["man_frowning_dark_skin_tone", "🙍🏿‍♂️"],
    ["woman_frowning", "🙍‍♀️"],
    ["woman_frowning_light_skin_tone", "🙍🏻‍♀️"],
    ["woman_frowning_medium-light_skin_tone", "🙍🏼‍♀️"],
    ["woman_frowning_medium_skin_tone", "🙍🏽‍♀️"],
    ["woman_frowning_medium-dark_skin_tone", "🙍🏾‍♀️"],
    ["woman_frowning_dark_skin_tone", "🙍🏿‍♀️"],
    ["person_pouting", "🙎"],
    ["person_pouting_light_skin_tone", "🙎🏻"],
    ["person_pouting_medium-light_skin_tone", "🙎🏼"],
    ["person_pouting_medium_skin_tone", "🙎🏽"],
    ["person_pouting_medium-dark_skin_tone", "🙎🏾"],
    ["person_pouting_dark_skin_tone", "🙎🏿"],
    ["man_pouting", "🙎‍♂️"],
    ["man_pouting_light_skin_tone", "🙎🏻‍♂️"],
    ["man_pouting_medium-light_skin_tone", "🙎🏼‍♂️"],
    ["man_pouting_medium_skin_tone", "🙎🏽‍♂️"],
    ["man_pouting_medium-dark_skin_tone", "🙎🏾‍♂️"],
    ["man_pouting_dark_skin_tone", "🙎🏿‍♂️"],
    ["woman_pouting", "🙎‍♀️"],
    ["woman_pouting_light_skin_tone", "🙎🏻‍♀️"],
    ["woman_pouting_medium-light_skin_tone", "🙎🏼‍♀️"],
    ["woman_pouting_medium_skin_tone", "🙎🏽‍♀️"],
    ["woman_pouting_medium-dark_skin_tone", "🙎🏾‍♀️"],
    ["woman_pouting_dark_skin_tone", "🙎🏿‍♀️"],
    ["person_gesturing_NO", "🙅"],
    ["person_gesturing_NO_light_skin_tone", "🙅🏻"],
    ["person_gesturing_NO_medium-light_skin_tone", "🙅🏼"],
    ["person_gesturing_NO_medium_skin_tone", "🙅🏽"],
    ["person_gesturing_NO_medium-dark_skin_tone", "🙅🏾"],
    ["person_gesturing_NO_dark_skin_tone", "🙅🏿"],
    ["man_gesturing_NO", "🙅‍♂️"],
    ["man_gesturing_NO_light_skin_tone", "🙅🏻‍♂️"],
    ["man_gesturing_NO_medium-light_skin_tone", "🙅🏼‍♂️"],
    ["man_gesturing_NO_medium_skin_tone", "🙅🏽‍♂️"],
    ["man_gesturing_NO_medium-dark_skin_tone", "🙅🏾‍♂️"],
    ["man_gesturing_NO_dark_skin_tone", "🙅🏿‍♂️"],
    ["woman_gesturing_NO", "🙅‍♀️"],
    ["woman_gesturing_NO_light_skin_tone", "🙅🏻‍♀️"],
    ["woman_gesturing_NO_medium-light_skin_tone", "🙅🏼‍♀️"],
    ["woman_gesturing_NO_medium_skin_tone", "🙅🏽‍♀️"],
    ["woman_gesturing_NO_medium-dark_skin_tone", "🙅🏾‍♀️"],
    ["woman_gesturing_NO_dark_skin_tone", "🙅🏿‍♀️"],
    ["person_gesturing_OK", "🙆"],
    ["person_gesturing_OK_light_skin_tone", "🙆🏻"],
    ["person_gesturing_OK_medium-light_skin_tone", "🙆🏼"],
    ["person_gesturing_OK_medium_skin_tone", "🙆🏽"],
    ["person_gesturing_OK_medium-dark_skin_tone", "🙆🏾"],
    ["person_gesturing_OK_dark_skin_tone", "🙆🏿"],
    ["man_gesturing_OK", "🙆‍♂️"],
    ["man_gesturing_OK_light_skin_tone", "🙆🏻‍♂️"],
    ["man_gesturing_OK_medium-light_skin_tone", "🙆🏼‍♂️"],
    ["man_gesturing_OK_medium_skin_tone", "🙆🏽‍♂️"],
    ["man_gesturing_OK_medium-dark_skin_tone", "🙆🏾‍♂️"],
    ["man_gesturing_OK_dark_skin_tone", "🙆🏿‍♂️"],
    ["woman_gesturing_OK", "🙆‍♀️"],
    ["woman_gesturing_OK_light_skin_tone", "🙆🏻‍♀️"],
    ["woman_gesturing_OK_medium-light_skin_tone", "🙆🏼‍♀️"],
    ["woman_gesturing_OK_medium_skin_tone", "🙆🏽‍♀️"],
    ["woman_gesturing_OK_medium-dark_skin_tone", "🙆🏾‍♀️"],
    ["woman_gesturing_OK_dark_skin_tone", "🙆🏿‍♀️"],
    ["person_tipping_hand", "💁"],
    ["person_tipping_hand_light_skin_tone", "💁🏻"],
    ["person_tipping_hand_medium-light_skin_tone", "💁🏼"],
    ["person_tipping_hand_medium_skin_tone", "💁🏽"],
    ["person_tipping_hand_medium-dark_skin_tone", "💁🏾"],
    ["person_tipping_hand_dark_skin_tone", "💁🏿"],
    ["man_tipping_hand", "💁‍♂️"],
    ["man_tipping_hand_light_skin_tone", "💁🏻‍♂️"],
    ["man_tipping_hand_medium-light_skin_tone", "💁🏼‍♂️"],
    ["man_tipping_hand_medium_skin_tone", "💁🏽‍♂️"],
    ["man_tipping_hand_medium-dark_skin_tone", "💁🏾‍♂️"],
    ["man_tipping_hand_dark_skin_tone", "💁🏿‍♂️"],
    ["woman_tipping_hand", "💁‍♀️"],
    ["woman_tipping_hand_light_skin_tone", "💁🏻‍♀️"],
    ["woman_tipping_hand_medium-light_skin_tone", "💁🏼‍♀️"],
    ["woman_tipping_hand_medium_skin_tone", "💁🏽‍♀️"],
    ["woman_tipping_hand_medium-dark_skin_tone", "💁🏾‍♀️"],
    ["woman_tipping_hand_dark_skin_tone", "💁🏿‍♀️"],
    ["person_raising_hand", "🙋"],
    ["person_raising_hand_light_skin_tone", "🙋🏻"],
    ["person_raising_hand_medium-light_skin_tone", "🙋🏼"],
    ["person_raising_hand_medium_skin_tone", "🙋🏽"],
    ["person_raising_hand_medium-dark_skin_tone", "🙋🏾"],
    ["person_raising_hand_dark_skin_tone", "🙋🏿"],
    ["man_raising_hand", "🙋‍♂️"],
    ["man_raising_hand_light_skin_tone", "🙋🏻‍♂️"],
    ["man_raising_hand_medium-light_skin_tone", "🙋🏼‍♂️"],
    ["man_raising_hand_medium_skin_tone", "🙋🏽‍♂️"],
    ["man_raising_hand_medium-dark_skin_tone", "🙋🏾‍♂️"],
    ["man_raising_hand_dark_skin_tone", "🙋🏿‍♂️"],
    ["woman_raising_hand", "🙋‍♀️"],
    ["woman_raising_hand_light_skin_tone", "🙋🏻‍♀️"],
    ["woman_raising_hand_medium-light_skin_tone", "🙋🏼‍♀️"],
    ["woman_raising_hand_medium_skin_tone", "🙋🏽‍♀️"],
    ["woman_raising_hand_medium-dark_skin_tone", "🙋🏾‍♀️"],
    ["woman_raising_hand_dark_skin_tone", "🙋🏿‍♀️"],
    ["deaf_person", "🧏"],
    ["deaf_person_light_skin_tone", "🧏🏻"],
    ["deaf_person_medium-light_skin_tone", "🧏🏼"],
    ["deaf_person_medium_skin_tone", "🧏🏽"],
    ["deaf_person_medium-dark_skin_tone", "🧏🏾"],
    ["deaf_person_dark_skin_tone", "🧏🏿"],
    ["deaf_man", "🧏‍♂️"],
    ["deaf_man_light_skin_tone", "🧏🏻‍♂️"],
    ["deaf_man_medium-light_skin_tone", "🧏🏼‍♂️"],
    ["deaf_man_medium_skin_tone", "🧏🏽‍♂️"],
    ["deaf_man_medium-dark_skin_tone", "🧏🏾‍♂️"],
    ["deaf_man_dark_skin_tone", "🧏🏿‍♂️"],
    ["deaf_woman", "🧏‍♀️"],
    ["deaf_woman_light_skin_tone", "🧏🏻‍♀️"],
    ["deaf_woman_medium-light_skin_tone", "🧏🏼‍♀️"],
    ["deaf_woman_medium_skin_tone", "🧏🏽‍♀️"],
    ["deaf_woman_medium-dark_skin_tone", "🧏🏾‍♀️"],
    ["deaf_woman_dark_skin_tone", "🧏🏿‍♀️"],
    ["person_bowing", "🙇"],
    ["person_bowing_light_skin_tone", "🙇🏻"],
    ["person_bowing_medium-light_skin_tone", "🙇🏼"],
    ["person_bowing_medium_skin_tone", "🙇🏽"],
    ["person_bowing_medium-dark_skin_tone", "🙇🏾"],
    ["person_bowing_dark_skin_tone", "🙇🏿"],
    ["man_bowing", "🙇‍♂️"],
    ["man_bowing_light_skin_tone", "🙇🏻‍♂️"],
    ["man_bowing_medium-light_skin_tone", "🙇🏼‍♂️"],
    ["man_bowing_medium_skin_tone", "🙇🏽‍♂️"],
    ["man_bowing_medium-dark_skin_tone", "🙇🏾‍♂️"],
    ["man_bowing_dark_skin_tone", "🙇🏿‍♂️"],
    ["woman_bowing", "🙇‍♀️"],
    ["woman_bowing_light_skin_tone", "🙇🏻‍♀️"],
    ["woman_bowing_medium-light_skin_tone", "🙇🏼‍♀️"],
    ["woman_bowing_medium_skin_tone", "🙇🏽‍♀️"],
    ["woman_bowing_medium-dark_skin_tone", "🙇🏾‍♀️"],
    ["woman_bowing_dark_skin_tone", "🙇🏿‍♀️"],
    ["person_facepalming", "🤦"],
    ["person_facepalming_light_skin_tone", "🤦🏻"],
    ["person_facepalming_medium-light_skin_tone", "🤦🏼"],
    ["person_facepalming_medium_skin_tone", "🤦🏽"],
    ["person_facepalming_medium-dark_skin_tone", "🤦🏾"],
    ["person_facepalming_dark_skin_tone", "🤦🏿"],
    ["man_facepalming", "🤦‍♂️"],
    ["man_facepalming_light_skin_tone", "🤦🏻‍♂️"],
    ["man_facepalming_medium-light_skin_tone", "🤦🏼‍♂️"],
    ["man_facepalming_medium_skin_tone", "🤦🏽‍♂️"],
    ["man_facepalming_medium-dark_skin_tone", "🤦🏾‍♂️"],
    ["man_facepalming_dark_skin_tone", "🤦🏿‍♂️"],
    ["woman_facepalming", "🤦‍♀️"],
    ["woman_facepalming_light_skin_tone", "🤦🏻‍♀️"],
    ["woman_facepalming_medium-light_skin_tone", "🤦🏼‍♀️"],
    ["woman_facepalming_medium_skin_tone", "🤦🏽‍♀️"],
    ["woman_facepalming_medium-dark_skin_tone", "🤦🏾‍♀️"],
    ["woman_facepalming_dark_skin_tone", "🤦🏿‍♀️"],
    ["person_shrugging", "🤷"],
    ["person_shrugging_light_skin_tone", "🤷🏻"],
    ["person_shrugging_medium-light_skin_tone", "🤷🏼"],
    ["person_shrugging_medium_skin_tone", "🤷🏽"],
    ["person_shrugging_medium-dark_skin_tone", "🤷🏾"],
    ["person_shrugging_dark_skin_tone", "🤷🏿"],
    ["man_shrugging", "🤷‍♂️"],
    ["man_shrugging_light_skin_tone", "🤷🏻‍♂️"],
    ["man_shrugging_medium-light_skin_tone", "🤷🏼‍♂️"],
    ["man_shrugging_medium_skin_tone", "🤷🏽‍♂️"],
    ["man_shrugging_medium-dark_skin_tone", "🤷🏾‍♂️"],
    ["man_shrugging_dark_skin_tone", "🤷🏿‍♂️"],
    ["woman_shrugging", "🤷‍♀️"],
    ["woman_shrugging_light_skin_tone", "🤷🏻‍♀️"],
    ["woman_shrugging_medium-light_skin_tone", "🤷🏼‍♀️"],
    ["woman_shrugging_medium_skin_tone", "🤷🏽‍♀️"],
    ["woman_shrugging_medium-dark_skin_tone", "🤷🏾‍♀️"],
    ["woman_shrugging_dark_skin_tone", "🤷🏿‍♀️"],
    ["health_worker", "🧑‍⚕️"],
    ["health_worker_light_skin_tone", "🧑🏻‍⚕️"],
    ["health_worker_medium-light_skin_tone", "🧑🏼‍⚕️"],
    ["health_worker_medium_skin_tone", "🧑🏽‍⚕️"],
    ["health_worker_medium-dark_skin_tone", "🧑🏾‍⚕️"],
    ["health_worker_dark_skin_tone", "🧑🏿‍⚕️"],
    ["man_health_worker", "👨‍⚕️"],
    ["man_health_worker_light_skin_tone", "👨🏻‍⚕️"],
    ["man_health_worker_medium-light_skin_tone", "👨🏼‍⚕️"],
    ["man_health_worker_medium_skin_tone", "👨🏽‍⚕️"],
    ["man_health_worker_medium-dark_skin_tone", "👨🏾‍⚕️"],
    ["man_health_worker_dark_skin_tone", "👨🏿‍⚕️"],
    ["woman_health_worker", "👩‍⚕️"],
    ["woman_health_worker_light_skin_tone", "👩🏻‍⚕️"],
    ["woman_health_worker_medium-light_skin_tone", "👩🏼‍⚕️"],
    ["woman_health_worker_medium_skin_tone", "👩🏽‍⚕️"],
    ["woman_health_worker_medium-dark_skin_tone", "👩🏾‍⚕️"],
    ["woman_health_worker_dark_skin_tone", "👩🏿‍⚕️"],
    ["student", "🧑‍🎓"],
    ["student_light_skin_tone", "🧑🏻‍🎓"],
    ["student_medium-light_skin_tone", "🧑🏼‍🎓"],
    ["student_medium_skin_tone", "🧑🏽‍🎓"],
    ["student_medium-dark_skin_tone", "🧑🏾‍🎓"],
    ["student_dark_skin_tone", "🧑🏿‍🎓"],
    ["man_student", "👨‍🎓"],
    ["man_student_light_skin_tone", "👨🏻‍🎓"],
    ["man_student_medium-light_skin_tone", "👨🏼‍🎓"],
    ["man_student_medium_skin_tone", "👨🏽‍🎓"],
    ["man_student_medium-dark_skin_tone", "👨🏾‍🎓"],
    ["man_student_dark_skin_tone", "👨🏿‍🎓"],
    ["woman_student", "👩‍🎓"],
    ["woman_student_light_skin_tone", "👩🏻‍🎓"],
    ["woman_student_medium-light_skin_tone", "👩🏼‍🎓"],
    ["woman_student_medium_skin_tone", "👩🏽‍🎓"],
    ["woman_student_medium-dark_skin_tone", "👩🏾‍🎓"],
    ["woman_student_dark_skin_tone", "👩🏿‍🎓"],
    ["teacher", "🧑‍🏫"],
    ["teacher_light_skin_tone", "🧑🏻‍🏫"],
    ["teacher_medium-light_skin_tone", "🧑🏼‍🏫"],
    ["teacher_medium_skin_tone", "🧑🏽‍🏫"],
    ["teacher_medium-dark_skin_tone", "🧑🏾‍🏫"],
    ["teacher_dark_skin_tone", "🧑🏿‍🏫"],
    ["man_teacher", "👨‍🏫"],
    ["man_teacher_light_skin_tone", "👨🏻‍🏫"],
    ["man_teacher_medium-light_skin_tone", "👨🏼‍🏫"],
    ["man_teacher_medium_skin_tone", "👨🏽‍🏫"],
    ["man_teacher_medium-dark_skin_tone", "👨🏾‍🏫"],
    ["man_teacher_dark_skin_tone", "👨🏿‍🏫"],
    ["woman_teacher", "👩‍🏫"],
    ["woman_teacher_light_skin_tone", "👩🏻‍🏫"],
    ["woman_teacher_medium-light_skin_tone", "👩🏼‍🏫"],
    ["woman_teacher_medium_skin_tone", "👩🏽‍🏫"],
    ["woman_teacher_medium-dark_skin_tone", "👩🏾‍🏫"],
    ["woman_teacher_dark_skin_tone", "👩🏿‍🏫"],
    ["judge", "🧑‍⚖️"],
    ["judge_light_skin_tone", "🧑🏻‍⚖️"],
    ["judge_medium-light_skin_tone", "🧑🏼‍⚖️"],
    ["judge_medium_skin_tone", "🧑🏽‍⚖️"],
    ["judge_medium-dark_skin_tone", "🧑🏾‍⚖️"],
    ["judge_dark_skin_tone", "🧑🏿‍⚖️"],
    ["man_judge", "👨‍⚖️"],
    ["man_judge_light_skin_tone", "👨🏻‍⚖️"],
    ["man_judge_medium-light_skin_tone", "👨🏼‍⚖️"],
    ["man_judge_medium_skin_tone", "👨🏽‍⚖️"],
    ["man_judge_medium-dark_skin_tone", "👨🏾‍⚖️"],
    ["man_judge_dark_skin_tone", "👨🏿‍⚖️"],
    ["woman_judge", "👩‍⚖️"],
    ["woman_judge_light_skin_tone", "👩🏻‍⚖️"],
    ["woman_judge_medium-light_skin_tone", "👩🏼‍⚖️"],
    ["woman_judge_medium_skin_tone", "👩🏽‍⚖️"],
    ["woman_judge_medium-dark_skin_tone", "👩🏾‍⚖️"],
    ["woman_judge_dark_skin_tone", "👩🏿‍⚖️"],
    ["farmer", "🧑‍🌾"],
    ["farmer_light_skin_tone", "🧑🏻‍🌾"],
    ["farmer_medium-light_skin_tone", "🧑🏼‍🌾"],
    ["farmer_medium_skin_tone", "🧑🏽‍🌾"],
    ["farmer_medium-dark_skin_tone", "🧑🏾‍🌾"],
    ["farmer_dark_skin_tone", "🧑🏿‍🌾"],
    ["man_farmer", "👨‍🌾"],
    ["man_farmer_light_skin_tone", "👨🏻‍🌾"],
    ["man_farmer_medium-light_skin_tone", "👨🏼‍🌾"],
    ["man_farmer_medium_skin_tone", "👨🏽‍🌾"],
    ["man_farmer_medium-dark_skin_tone", "👨🏾‍🌾"],
    ["man_farmer_dark_skin_tone", "👨🏿‍🌾"],
    ["woman_farmer", "👩‍🌾"],
    ["woman_farmer_light_skin_tone", "👩🏻‍🌾"],
    ["woman_farmer_medium-light_skin_tone", "👩🏼‍🌾"],
    ["woman_farmer_medium_skin_tone", "👩🏽‍🌾"],
    ["woman_farmer_medium-dark_skin_tone", "👩🏾‍🌾"],
    ["woman_farmer_dark_skin_tone", "👩🏿‍🌾"],
    ["cook", "🧑‍🍳"],
    ["cook_light_skin_tone", "🧑🏻‍🍳"],
    ["cook_medium-light_skin_tone", "🧑🏼‍🍳"],
    ["cook_medium_skin_tone", "🧑🏽‍🍳"],
    ["cook_medium-dark_skin_tone", "🧑🏾‍🍳"],
    ["cook_dark_skin_tone", "🧑🏿‍🍳"],
    ["man_cook", "👨‍🍳"],
    ["man_cook_light_skin_tone", "👨🏻‍🍳"],
    ["man_cook_medium-light_skin_tone", "👨🏼‍🍳"],
    ["man_cook_medium_skin_tone", "👨🏽‍🍳"],
    ["man_cook_medium-dark_skin_tone", "👨🏾‍🍳"],
    ["man_cook_dark_skin_tone", "👨🏿‍🍳"],
    ["woman_cook", "👩‍🍳"],
    ["woman_cook_light_skin_tone", "👩🏻‍🍳"],
    ["woman_cook_medium-light_skin_tone", "👩🏼‍🍳"],
    ["woman_cook_medium_skin_tone", "👩🏽‍🍳"],
    ["woman_cook_medium-dark_skin_tone", "👩🏾‍🍳"],
    ["woman_cook_dark_skin_tone", "👩🏿‍🍳"],
    ["mechanic", "🧑‍🔧"],
    ["mechanic_light_skin_tone", "🧑🏻‍🔧"],
    ["mechanic_medium-light_skin_tone", "🧑🏼‍🔧"],
    ["mechanic_medium_skin_tone", "🧑🏽‍🔧"],
    ["mechanic_medium-dark_skin_tone", "🧑🏾‍🔧"],
    ["mechanic_dark_skin_tone", "🧑🏿‍🔧"],
    ["man_mechanic", "👨‍🔧"],
    ["man_mechanic_light_skin_tone", "👨🏻‍🔧"],
    ["man_mechanic_medium-light_skin_tone", "👨🏼‍🔧"],
    ["man_mechanic_medium_skin_tone", "👨🏽‍🔧"],
    ["man_mechanic_medium-dark_skin_tone", "👨🏾‍🔧"],
    ["man_mechanic_dark_skin_tone", "👨🏿‍🔧"],
    ["woman_mechanic", "👩‍🔧"],
    ["woman_mechanic_light_skin_tone", "👩🏻‍🔧"],
    ["woman_mechanic_medium-light_skin_tone", "👩🏼‍🔧"],
    ["woman_mechanic_medium_skin_tone", "👩🏽‍🔧"],
    ["woman_mechanic_medium-dark_skin_tone", "👩🏾‍🔧"],
    ["woman_mechanic_dark_skin_tone", "👩🏿‍🔧"],
    ["factory_worker", "🧑‍🏭"],
    ["factory_worker_light_skin_tone", "🧑🏻‍🏭"],
    ["factory_worker_medium-light_skin_tone", "🧑🏼‍🏭"],
    ["factory_worker_medium_skin_tone", "🧑🏽‍🏭"],
    ["factory_worker_medium-dark_skin_tone", "🧑🏾‍🏭"],
    ["factory_worker_dark_skin_tone", "🧑🏿‍🏭"],
    ["man_factory_worker", "👨‍🏭"],
    ["man_factory_worker_light_skin_tone", "👨🏻‍🏭"],
    ["man_factory_worker_medium-light_skin_tone", "👨🏼‍🏭"],
    ["man_factory_worker_medium_skin_tone", "👨🏽‍🏭"],
    ["man_factory_worker_medium-dark_skin_tone", "👨🏾‍🏭"],
    ["man_factory_worker_dark_skin_tone", "👨🏿‍🏭"],
    ["woman_factory_worker", "👩‍🏭"],
    ["woman_factory_worker_light_skin_tone", "👩🏻‍🏭"],
    ["woman_factory_worker_medium-light_skin_tone", "👩🏼‍🏭"],
    ["woman_factory_worker_medium_skin_tone", "👩🏽‍🏭"],
    ["woman_factory_worker_medium-dark_skin_tone", "👩🏾‍🏭"],
    ["woman_factory_worker_dark_skin_tone", "👩🏿‍🏭"],
    ["office_worker", "🧑‍💼"],
    ["office_worker_light_skin_tone", "🧑🏻‍💼"],
    ["office_worker_medium-light_skin_tone", "🧑🏼‍💼"],
    ["office_worker_medium_skin_tone", "🧑🏽‍💼"],
    ["office_worker_medium-dark_skin_tone", "🧑🏾‍💼"],
    ["office_worker_dark_skin_tone", "🧑🏿‍💼"],
    ["man_office_worker", "👨‍💼"],
    ["man_office_worker_light_skin_tone", "👨🏻‍💼"],
    ["man_office_worker_medium-light_skin_tone", "👨🏼‍💼"],
    ["man_office_worker_medium_skin_tone", "👨🏽‍💼"],
    ["man_office_worker_medium-dark_skin_tone", "👨🏾‍💼"],
    ["man_office_worker_dark_skin_tone", "👨🏿‍💼"],
    ["woman_office_worker", "👩‍💼"],
    ["woman_office_worker_light_skin_tone", "👩🏻‍💼"],
    ["woman_office_worker_medium-light_skin_tone", "👩🏼‍💼"],
    ["woman_office_worker_medium_skin_tone", "👩🏽‍💼"],
    ["woman_office_worker_medium-dark_skin_tone", "👩🏾‍💼"],
    ["woman_office_worker_dark_skin_tone", "👩🏿‍💼"],
    ["scientist", "🧑‍🔬"],
    ["scientist_light_skin_tone", "🧑🏻‍🔬"],
    ["scientist_medium-light_skin_tone", "🧑🏼‍🔬"],
    ["scientist_medium_skin_tone", "🧑🏽‍🔬"],
    ["scientist_medium-dark_skin_tone", "🧑🏾‍🔬"],
    ["scientist_dark_skin_tone", "🧑🏿‍🔬"],
    ["man_scientist", "👨‍🔬"],
    ["man_scientist_light_skin_tone", "👨🏻‍🔬"],
    ["man_scientist_medium-light_skin_tone", "👨🏼‍🔬"],
    ["man_scientist_medium_skin_tone", "👨🏽‍🔬"],
    ["man_scientist_medium-dark_skin_tone", "👨🏾‍🔬"],
    ["man_scientist_dark_skin_tone", "👨🏿‍🔬"],
    ["woman_scientist", "👩‍🔬"],
    ["woman_scientist_light_skin_tone", "👩🏻‍🔬"],
    ["woman_scientist_medium-light_skin_tone", "👩🏼‍🔬"],
    ["woman_scientist_medium_skin_tone", "👩🏽‍🔬"],
    ["woman_scientist_medium-dark_skin_tone", "👩🏾‍🔬"],
    ["woman_scientist_dark_skin_tone", "👩🏿‍🔬"],
    ["technologist", "🧑‍💻"],
    ["technologist_light_skin_tone", "🧑🏻‍💻"],
    ["technologist_medium-light_skin_tone", "🧑🏼‍💻"],
    ["technologist_medium_skin_tone", "🧑🏽‍💻"],
    ["technologist_medium-dark_skin_tone", "🧑🏾‍💻"],
    ["technologist_dark_skin_tone", "🧑🏿‍💻"],
    ["man_technologist", "👨‍💻"],
    ["man_technologist_light_skin_tone", "👨🏻‍💻"],
    ["man_technologist_medium-light_skin_tone", "👨🏼‍💻"],
    ["man_technologist_medium_skin_tone", "👨🏽‍💻"],
    ["man_technologist_medium-dark_skin_tone", "👨🏾‍💻"],
    ["man_technologist_dark_skin_tone", "👨🏿‍💻"],
    ["woman_technologist", "👩‍💻"],
    ["woman_technologist_light_skin_tone", "👩🏻‍💻"],
    ["woman_technologist_medium-light_skin_tone", "👩🏼‍💻"],
    ["woman_technologist_medium_skin_tone", "👩🏽‍💻"],
    ["woman_technologist_medium-dark_skin_tone", "👩🏾‍💻"],
    ["woman_technologist_dark_skin_tone", "👩🏿‍💻"],
    ["singer", "🧑‍🎤"],
    ["singer_light_skin_tone", "🧑🏻‍🎤"],
    ["singer_medium-light_skin_tone", "🧑🏼‍🎤"],
    ["singer_medium_skin_tone", "🧑🏽‍🎤"],
    ["singer_medium-dark_skin_tone", "🧑🏾‍🎤"],
    ["singer_dark_skin_tone", "🧑🏿‍🎤"],
    ["man_singer", "👨‍🎤"],
    ["man_singer_light_skin_tone", "👨🏻‍🎤"],
    ["man_singer_medium-light_skin_tone", "👨🏼‍🎤"],
    ["man_singer_medium_skin_tone", "👨🏽‍🎤"],
    ["man_singer_medium-dark_skin_tone", "👨🏾‍🎤"],
    ["man_singer_dark_skin_tone", "👨🏿‍🎤"],
    ["woman_singer", "👩‍🎤"],
    ["woman_singer_light_skin_tone", "👩🏻‍🎤"],
    ["woman_singer_medium-light_skin_tone", "👩🏼‍🎤"],
    ["woman_singer_medium_skin_tone", "👩🏽‍🎤"],
    ["woman_singer_medium-dark_skin_tone", "👩🏾‍🎤"],
    ["woman_singer_dark_skin_tone", "👩🏿‍🎤"],
    ["artist", "🧑‍🎨"],
    ["artist_light_skin_tone", "🧑🏻‍🎨"],
    ["artist_medium-light_skin_tone", "🧑🏼‍🎨"],
    ["artist_medium_skin_tone", "🧑🏽‍🎨"],
    ["artist_medium-dark_skin_tone", "🧑🏾‍🎨"],
    ["artist_dark_skin_tone", "🧑🏿‍🎨"],
    ["man_artist", "👨‍🎨"],
    ["man_artist_light_skin_tone", "👨🏻‍🎨"],
    ["man_artist_medium-light_skin_tone", "👨🏼‍🎨"],
    ["man_artist_medium_skin_tone", "👨🏽‍🎨"],
    ["man_artist_medium-dark_skin_tone", "👨🏾‍🎨"],
    ["man_artist_dark_skin_tone", "👨🏿‍🎨"],
    ["woman_artist", "👩‍🎨"],
    ["woman_artist_light_skin_tone", "👩🏻‍🎨"],
    ["woman_artist_medium-light_skin_tone", "👩🏼‍🎨"],
    ["woman_artist_medium_skin_tone", "👩🏽‍🎨"],
    ["woman_artist_medium-dark_skin_tone", "👩🏾‍🎨"],
    ["woman_artist_dark_skin_tone", "👩🏿‍🎨"],
    ["pilot", "🧑‍✈️"],
    ["pilot_light_skin_tone", "🧑🏻‍✈️"],
    ["pilot_medium-light_skin_tone", "🧑🏼‍✈️"],
    ["pilot_medium_skin_tone", "🧑🏽‍✈️"],
    ["pilot_medium-dark_skin_tone", "🧑🏾‍✈️"],
    ["pilot_dark_skin_tone", "🧑🏿‍✈️"],
    ["man_pilot", "👨‍✈️"],
    ["man_pilot_light_skin_tone", "👨🏻‍✈️"],
    ["man_pilot_medium-light_skin_tone", "👨🏼‍✈️"],
    ["man_pilot_medium_skin_tone", "👨🏽‍✈️"],
    ["man_pilot_medium-dark_skin_tone", "👨🏾‍✈️"],
    ["man_pilot_dark_skin_tone", "👨🏿‍✈️"],
    ["woman_pilot", "👩‍✈️"],
    ["woman_pilot_light_skin_tone", "👩🏻‍✈️"],
    ["woman_pilot_medium-light_skin_tone", "👩🏼‍✈️"],
    ["woman_pilot_medium_skin_tone", "👩🏽‍✈️"],
    ["woman_pilot_medium-dark_skin_tone", "👩🏾‍✈️"],
    ["woman_pilot_dark_skin_tone", "👩🏿‍✈️"],
    ["astronaut", "🧑‍🚀"],
    ["astronaut_light_skin_tone", "🧑🏻‍🚀"],
    ["astronaut_medium-light_skin_tone", "🧑🏼‍🚀"],
    ["astronaut_medium_skin_tone", "🧑🏽‍🚀"],
    ["astronaut_medium-dark_skin_tone", "🧑🏾‍🚀"],
    ["astronaut_dark_skin_tone", "🧑🏿‍🚀"],
    ["man_astronaut", "👨‍🚀"],
    ["man_astronaut_light_skin_tone", "👨🏻‍🚀"],
    ["man_astronaut_medium-light_skin_tone", "👨🏼‍🚀"],
    ["man_astronaut_medium_skin_tone", "👨🏽‍🚀"],
    ["man_astronaut_medium-dark_skin_tone", "👨🏾‍🚀"],
    ["man_astronaut_dark_skin_tone", "👨🏿‍🚀"],
    ["woman_astronaut", "👩‍🚀"],
    ["woman_astronaut_light_skin_tone", "👩🏻‍🚀"],
    ["woman_astronaut_medium-light_skin_tone", "👩🏼‍🚀"],
    ["woman_astronaut_medium_skin_tone", "👩🏽‍🚀"],
    ["woman_astronaut_medium-dark_skin_tone", "👩🏾‍🚀"],
    ["woman_astronaut_dark_skin_tone", "👩🏿‍🚀"],
    ["firefighter", "🧑‍🚒"],
    ["firefighter_light_skin_tone", "🧑🏻‍🚒"],
    ["firefighter_medium-light_skin_tone", "🧑🏼‍🚒"],
    ["firefighter_medium_skin_tone", "🧑🏽‍🚒"],
    ["firefighter_medium-dark_skin_tone", "🧑🏾‍🚒"],
    ["firefighter_dark_skin_tone", "🧑🏿‍🚒"],
    ["man_firefighter", "👨‍🚒"],
    ["man_firefighter_light_skin_tone", "👨🏻‍🚒"],
    ["man_firefighter_medium-light_skin_tone", "👨🏼‍🚒"],
    ["man_firefighter_medium_skin_tone", "👨🏽‍🚒"],
    ["man_firefighter_medium-dark_skin_tone", "👨🏾‍🚒"],
    ["man_firefighter_dark_skin_tone", "👨🏿‍🚒"],
    ["woman_firefighter", "👩‍🚒"],
    ["woman_firefighter_light_skin_tone", "👩🏻‍🚒"],
    ["woman_firefighter_medium-light_skin_tone", "👩🏼‍🚒"],
    ["woman_firefighter_medium_skin_tone", "👩🏽‍🚒"],
    ["woman_firefighter_medium-dark_skin_tone", "👩🏾‍🚒"],
    ["woman_firefighter_dark_skin_tone", "👩🏿‍🚒"],
    ["police_officer", "👮"],
    ["police_officer_light_skin_tone", "👮🏻"],
    ["police_officer_medium-light_skin_tone", "👮🏼"],
    ["police_officer_medium_skin_tone", "👮🏽"],
    ["police_officer_medium-dark_skin_tone", "👮🏾"],
    ["police_officer_dark_skin_tone", "👮🏿"],
    ["man_police_officer", "👮‍♂️"],
    ["man_police_officer_light_skin_tone", "👮🏻‍♂️"],
    ["man_police_officer_medium-light_skin_tone", "👮🏼‍♂️"],
    ["man_police_officer_medium_skin_tone", "👮🏽‍♂️"],
    ["man_police_officer_medium-dark_skin_tone", "👮🏾‍♂️"],
    ["man_police_officer_dark_skin_tone", "👮🏿‍♂️"],
    ["woman_police_officer", "👮‍♀️"],
    ["woman_police_officer_light_skin_tone", "👮🏻‍♀️"],
    ["woman_police_officer_medium-light_skin_tone", "👮🏼‍♀️"],
    ["woman_police_officer_medium_skin_tone", "👮🏽‍♀️"],
    ["woman_police_officer_medium-dark_skin_tone", "👮🏾‍♀️"],
    ["woman_police_officer_dark_skin_tone", "👮🏿‍♀️"],
    ["detective", "🕵️"],
    ["detective_light_skin_tone", "🕵🏻"],
    ["detective_medium-light_skin_tone", "🕵🏼"],
    ["detective_medium_skin_tone", "🕵🏽"],
    ["detective_medium-dark_skin_tone", "🕵🏾"],
    ["detective_dark_skin_tone", "🕵🏿"],
    ["man_detective", "🕵️‍♂️"],
    ["man_detective_light_skin_tone", "🕵🏻‍♂️"],
    ["man_detective_medium-light_skin_tone", "🕵🏼‍♂️"],
    ["man_detective_medium_skin_tone", "🕵🏽‍♂️"],
    ["man_detective_medium-dark_skin_tone", "🕵🏾‍♂️"],
    ["man_detective_dark_skin_tone", "🕵🏿‍♂️"],
    ["woman_detective", "🕵️‍♀️"],
    ["woman_detective_light_skin_tone", "🕵🏻‍♀️"],
    ["woman_detective_medium-light_skin_tone", "🕵🏼‍♀️"],
    ["woman_detective_medium_skin_tone", "🕵🏽‍♀️"],
    ["woman_detective_medium-dark_skin_tone", "🕵🏾‍♀️"],
    ["woman_detective_dark_skin_tone", "🕵🏿‍♀️"],
    ["guard", "💂"],
    ["guard_light_skin_tone", "💂🏻"],
    ["guard_medium-light_skin_tone", "💂🏼"],
    ["guard_medium_skin_tone", "💂🏽"],
    ["guard_medium-dark_skin_tone", "💂🏾"],
    ["guard_dark_skin_tone", "💂🏿"],
    ["man_guard", "💂‍♂️"],
    ["man_guard_light_skin_tone", "💂🏻‍♂️"],
    ["man_guard_medium-light_skin_tone", "💂🏼‍♂️"],
    ["man_guard_medium_skin_tone", "💂🏽‍♂️"],
    ["man_guard_medium-dark_skin_tone", "💂🏾‍♂️"],
    ["man_guard_dark_skin_tone", "💂🏿‍♂️"],
    ["woman_guard", "💂‍♀️"],
    ["woman_guard_light_skin_tone", "💂🏻‍♀️"],
    ["woman_guard_medium-light_skin_tone", "💂🏼‍♀️"],
    ["woman_guard_medium_skin_tone", "💂🏽‍♀️"],
    ["woman_guard_medium-dark_skin_tone", "💂🏾‍♀️"],
    ["woman_guard_dark_skin_tone", "💂🏿‍♀️"],
    ["ninja", "🥷"],
    ["ninja_light_skin_tone", "🥷🏻"],
    ["ninja_medium-light_skin_tone", "🥷🏼"],
    ["ninja_medium_skin_tone", "🥷🏽"],
    ["ninja_medium-dark_skin_tone", "🥷🏾"],
    ["ninja_dark_skin_tone", "🥷🏿"],
    ["construction_worker", "👷"],
    ["construction_worker_light_skin_tone", "👷🏻"],
    ["construction_worker_medium-light_skin_tone", "👷🏼"],
    ["construction_worker_medium_skin_tone", "👷🏽"],
    ["construction_worker_medium-dark_skin_tone", "👷🏾"],
    ["construction_worker_dark_skin_tone", "👷🏿"],
    ["man_construction_worker", "👷‍♂️"],
    ["man_construction_worker_light_skin_tone", "👷🏻‍♂️"],
    ["man_construction_worker_medium-light_skin_tone", "👷🏼‍♂️"],
    ["man_construction_worker_medium_skin_tone", "👷🏽‍♂️"],
    ["man_construction_worker_medium-dark_skin_tone", "👷🏾‍♂️"],
    ["man_construction_worker_dark_skin_tone", "👷🏿‍♂️"],
    ["woman_construction_worker", "👷‍♀️"],
    ["woman_construction_worker_light_skin_tone", "👷🏻‍♀️"],
    ["woman_construction_worker_medium-light_skin_tone", "👷🏼‍♀️"],
    ["woman_construction_worker_medium_skin_tone", "👷🏽‍♀️"],
    ["woman_construction_worker_medium-dark_skin_tone", "👷🏾‍♀️"],
    ["woman_construction_worker_dark_skin_tone", "👷🏿‍♀️"],
    ["prince", "🤴"],
    ["prince_light_skin_tone", "🤴🏻"],
    ["prince_medium-light_skin_tone", "🤴🏼"],
    ["prince_medium_skin_tone", "🤴🏽"],
    ["prince_medium-dark_skin_tone", "🤴🏾"],
    ["prince_dark_skin_tone", "🤴🏿"],
    ["princess", "👸"],
    ["princess_light_skin_tone", "👸🏻"],
    ["princess_medium-light_skin_tone", "👸🏼"],
    ["princess_medium_skin_tone", "👸🏽"],
    ["princess_medium-dark_skin_tone", "👸🏾"],
    ["princess_dark_skin_tone", "👸🏿"],
    ["person_wearing_turban", "👳"],
    ["person_wearing_turban_light_skin_tone", "👳🏻"],
    ["person_wearing_turban_medium-light_skin_tone", "👳🏼"],
    ["person_wearing_turban_medium_skin_tone", "👳🏽"],
    ["person_wearing_turban_medium-dark_skin_tone", "👳🏾"],
    ["person_wearing_turban_dark_skin_tone", "👳🏿"],
    ["man_wearing_turban", "👳‍♂️"],
    ["man_wearing_turban_light_skin_tone", "👳🏻‍♂️"],
    ["man_wearing_turban_medium-light_skin_tone", "👳🏼‍♂️"],
    ["man_wearing_turban_medium_skin_tone", "👳🏽‍♂️"],
    ["man_wearing_turban_medium-dark_skin_tone", "👳🏾‍♂️"],
    ["man_wearing_turban_dark_skin_tone", "👳🏿‍♂️"],
    ["woman_wearing_turban", "👳‍♀️"],
    ["woman_wearing_turban_light_skin_tone", "👳🏻‍♀️"],
    ["woman_wearing_turban_medium-light_skin_tone", "👳🏼‍♀️"],
    ["woman_wearing_turban_medium_skin_tone", "👳🏽‍♀️"],
    ["woman_wearing_turban_medium-dark_skin_tone", "👳🏾‍♀️"],
    ["woman_wearing_turban_dark_skin_tone", "👳🏿‍♀️"],
    ["person_with_skullcap", "👲"],
    ["person_with_skullcap_light_skin_tone", "👲🏻"],
    ["person_with_skullcap_medium-light_skin_tone", "👲🏼"],
    ["person_with_skullcap_medium_skin_tone", "👲🏽"],
    ["person_with_skullcap_medium-dark_skin_tone", "👲🏾"],
    ["person_with_skullcap_dark_skin_tone", "👲🏿"],
    ["woman_with_headscarf", "🧕"],
    ["woman_with_headscarf_light_skin_tone", "🧕🏻"],
    ["woman_with_headscarf_medium-light_skin_tone", "🧕🏼"],
    ["woman_with_headscarf_medium_skin_tone", "🧕🏽"],
    ["woman_with_headscarf_medium-dark_skin_tone", "🧕🏾"],
    ["woman_with_headscarf_dark_skin_tone", "🧕🏿"],
    ["person_in_tuxedo", "🤵"],
    ["person_in_tuxedo_light_skin_tone", "🤵🏻"],
    ["person_in_tuxedo_medium-light_skin_tone", "🤵🏼"],
    ["person_in_tuxedo_medium_skin_tone", "🤵🏽"],
    ["person_in_tuxedo_medium-dark_skin_tone", "🤵🏾"],
    ["person_in_tuxedo_dark_skin_tone", "🤵🏿"],
    ["man_in_tuxedo", "🤵‍♂️"],
    ["man_in_tuxedo_light_skin_tone", "🤵🏻‍♂️"],
    ["man_in_tuxedo_medium-light_skin_tone", "🤵🏼‍♂️"],
    ["man_in_tuxedo_medium_skin_tone", "🤵🏽‍♂️"],
    ["man_in_tuxedo_medium-dark_skin_tone", "🤵🏾‍♂️"],
    ["man_in_tuxedo_dark_skin_tone", "🤵🏿‍♂️"],
    ["woman_in_tuxedo", "🤵‍♀️"],
    ["woman_in_tuxedo_light_skin_tone", "🤵🏻‍♀️"],
    ["woman_in_tuxedo_medium-light_skin_tone", "🤵🏼‍♀️"],
    ["woman_in_tuxedo_medium_skin_tone", "🤵🏽‍♀️"],
    ["woman_in_tuxedo_medium-dark_skin_tone", "🤵🏾‍♀️"],
    ["woman_in_tuxedo_dark_skin_tone", "🤵🏿‍♀️"],
    ["person_with_veil", "👰"],
    ["person_with_veil_light_skin_tone", "👰🏻"],
    ["person_with_veil_medium-light_skin_tone", "👰🏼"],
    ["person_with_veil_medium_skin_tone", "👰🏽"],
    ["person_with_veil_medium-dark_skin_tone", "👰🏾"],
    ["person_with_veil_dark_skin_tone", "👰🏿"],
    ["man_with_veil", "👰‍♂️"],
    ["man_with_veil_light_skin_tone", "👰🏻‍♂️"],
    ["man_with_veil_medium-light_skin_tone", "👰🏼‍♂️"],
    ["man_with_veil_medium_skin_tone", "👰🏽‍♂️"],
    ["man_with_veil_medium-dark_skin_tone", "👰🏾‍♂️"],
    ["man_with_veil_dark_skin_tone", "👰🏿‍♂️"],
    ["woman_with_veil", "👰‍♀️"],
    ["woman_with_veil_light_skin_tone", "👰🏻‍♀️"],
    ["woman_with_veil_medium-light_skin_tone", "👰🏼‍♀️"],
    ["woman_with_veil_medium_skin_tone", "👰🏽‍♀️"],
    ["woman_with_veil_medium-dark_skin_tone", "👰🏾‍♀️"],
    ["woman_with_veil_dark_skin_tone", "👰🏿‍♀️"],
    ["pregnant_woman", "🤰"],
    ["pregnant_woman_light_skin_tone", "🤰🏻"],
    ["pregnant_woman_medium-light_skin_tone", "🤰🏼"],
    ["pregnant_woman_medium_skin_tone", "🤰🏽"],
    ["pregnant_woman_medium-dark_skin_tone", "🤰🏾"],
    ["pregnant_woman_dark_skin_tone", "🤰🏿"],
    ["breast-feeding", "🤱"],
    ["breast-feeding_light_skin_tone", "🤱🏻"],
    ["breast-feeding_medium-light_skin_tone", "🤱🏼"],
    ["breast-feeding_medium_skin_tone", "🤱🏽"],
    ["breast-feeding_medium-dark_skin_tone", "🤱🏾"],
    ["breast-feeding_dark_skin_tone", "🤱🏿"],
    ["woman_feeding_baby", "👩‍🍼"],
    ["woman_feeding_baby_light_skin_tone", "👩🏻‍🍼"],
    ["woman_feeding_baby_medium-light_skin_tone", "👩🏼‍🍼"],
    ["woman_feeding_baby_medium_skin_tone", "👩🏽‍🍼"],
    ["woman_feeding_baby_medium-dark_skin_tone", "👩🏾‍🍼"],
    ["woman_feeding_baby_dark_skin_tone", "👩🏿‍🍼"],
    ["man_feeding_baby", "👨‍🍼"],
    ["man_feeding_baby_light_skin_tone", "👨🏻‍🍼"],
    ["man_feeding_baby_medium-light_skin_tone", "👨🏼‍🍼"],
    ["man_feeding_baby_medium_skin_tone", "👨🏽‍🍼"],
    ["man_feeding_baby_medium-dark_skin_tone", "👨🏾‍🍼"],
    ["man_feeding_baby_dark_skin_tone", "👨🏿‍🍼"],
    ["person_feeding_baby", "🧑‍🍼"],
    ["person_feeding_baby_light_skin_tone", "🧑🏻‍🍼"],
    ["person_feeding_baby_medium-light_skin_tone", "🧑🏼‍🍼"],
    ["person_feeding_baby_medium_skin_tone", "🧑🏽‍🍼"],
    ["person_feeding_baby_medium-dark_skin_tone", "🧑🏾‍🍼"],
    ["person_feeding_baby_dark_skin_tone", "🧑🏿‍🍼"],
    ["baby_angel", "👼"],
    ["baby_angel_light_skin_tone", "👼🏻"],
    ["baby_angel_medium-light_skin_tone", "👼🏼"],
    ["baby_angel_medium_skin_tone", "👼🏽"],
    ["baby_angel_medium-dark_skin_tone", "👼🏾"],
    ["baby_angel_dark_skin_tone", "👼🏿"],
    ["Santa_Claus", "🎅"],
    ["Santa_Claus_light_skin_tone", "🎅🏻"],
    ["Santa_Claus_medium-light_skin_tone", "🎅🏼"],
    ["Santa_Claus_medium_skin_tone", "🎅🏽"],
    ["Santa_Claus_medium-dark_skin_tone", "🎅🏾"],
    ["Santa_Claus_dark_skin_tone", "🎅🏿"],
    ["Mrs._Claus", "🤶"],
    ["Mrs._Claus_light_skin_tone", "🤶🏻"],
    ["Mrs._Claus_medium-light_skin_tone", "🤶🏼"],
    ["Mrs._Claus_medium_skin_tone", "🤶🏽"],
    ["Mrs._Claus_medium-dark_skin_tone", "🤶🏾"],
    ["Mrs._Claus_dark_skin_tone", "🤶🏿"],
    ["mx_claus", "🧑‍🎄"],
    ["mx_claus_light_skin_tone", "🧑🏻‍🎄"],
    ["mx_claus_medium-light_skin_tone", "🧑🏼‍🎄"],
    ["mx_claus_medium_skin_tone", "🧑🏽‍🎄"],
    ["mx_claus_medium-dark_skin_tone", "🧑🏾‍🎄"],
    ["mx_claus_dark_skin_tone", "🧑🏿‍🎄"],
    ["superhero", "🦸"],
    ["superhero_light_skin_tone", "🦸🏻"],
    ["superhero_medium-light_skin_tone", "🦸🏼"],
    ["superhero_medium_skin_tone", "🦸🏽"],
    ["superhero_medium-dark_skin_tone", "🦸🏾"],
    ["superhero_dark_skin_tone", "🦸🏿"],
    ["man_superhero", "🦸‍♂️"],
    ["man_superhero_light_skin_tone", "🦸🏻‍♂️"],
    ["man_superhero_medium-light_skin_tone", "🦸🏼‍♂️"],
    ["man_superhero_medium_skin_tone", "🦸🏽‍♂️"],
    ["man_superhero_medium-dark_skin_tone", "🦸🏾‍♂️"],
    ["man_superhero_dark_skin_tone", "🦸🏿‍♂️"],
    ["woman_superhero", "🦸‍♀️"],
    ["woman_superhero_light_skin_tone", "🦸🏻‍♀️"],
    ["woman_superhero_medium-light_skin_tone", "🦸🏼‍♀️"],
    ["woman_superhero_medium_skin_tone", "🦸🏽‍♀️"],
    ["woman_superhero_medium-dark_skin_tone", "🦸🏾‍♀️"],
    ["woman_superhero_dark_skin_tone", "🦸🏿‍♀️"],
    ["supervillain", "🦹"],
    ["supervillain_light_skin_tone", "🦹🏻"],
    ["supervillain_medium-light_skin_tone", "🦹🏼"],
    ["supervillain_medium_skin_tone", "🦹🏽"],
    ["supervillain_medium-dark_skin_tone", "🦹🏾"],
    ["supervillain_dark_skin_tone", "🦹🏿"],
    ["man_supervillain", "🦹‍♂️"],
    ["man_supervillain_light_skin_tone", "🦹🏻‍♂️"],
    ["man_supervillain_medium-light_skin_tone", "🦹🏼‍♂️"],
    ["man_supervillain_medium_skin_tone", "🦹🏽‍♂️"],
    ["man_supervillain_medium-dark_skin_tone", "🦹🏾‍♂️"],
    ["man_supervillain_dark_skin_tone", "🦹🏿‍♂️"],
    ["woman_supervillain", "🦹‍♀️"],
    ["woman_supervillain_light_skin_tone", "🦹🏻‍♀️"],
    ["woman_supervillain_medium-light_skin_tone", "🦹🏼‍♀️"],
    ["woman_supervillain_medium_skin_tone", "🦹🏽‍♀️"],
    ["woman_supervillain_medium-dark_skin_tone", "🦹🏾‍♀️"],
    ["woman_supervillain_dark_skin_tone", "🦹🏿‍♀️"],
    ["mage", "🧙"],
    ["mage_light_skin_tone", "🧙🏻"],
    ["mage_medium-light_skin_tone", "🧙🏼"],
    ["mage_medium_skin_tone", "🧙🏽"],
    ["mage_medium-dark_skin_tone", "🧙🏾"],
    ["mage_dark_skin_tone", "🧙🏿"],
    ["man_mage", "🧙‍♂️"],
    ["man_mage_light_skin_tone", "🧙🏻‍♂️"],
    ["man_mage_medium-light_skin_tone", "🧙🏼‍♂️"],
    ["man_mage_medium_skin_tone", "🧙🏽‍♂️"],
    ["man_mage_medium-dark_skin_tone", "🧙🏾‍♂️"],
    ["man_mage_dark_skin_tone", "🧙🏿‍♂️"],
    ["woman_mage", "🧙‍♀️"],
    ["woman_mage_light_skin_tone", "🧙🏻‍♀️"],
    ["woman_mage_medium-light_skin_tone", "🧙🏼‍♀️"],
    ["woman_mage_medium_skin_tone", "🧙🏽‍♀️"],
    ["woman_mage_medium-dark_skin_tone", "🧙🏾‍♀️"],
    ["woman_mage_dark_skin_tone", "🧙🏿‍♀️"],
    ["fairy", "🧚"],
    ["fairy_light_skin_tone", "🧚🏻"],
    ["fairy_medium-light_skin_tone", "🧚🏼"],
    ["fairy_medium_skin_tone", "🧚🏽"],
    ["fairy_medium-dark_skin_tone", "🧚🏾"],
    ["fairy_dark_skin_tone", "🧚🏿"],
    ["man_fairy", "🧚‍♂️"],
    ["man_fairy_light_skin_tone", "🧚🏻‍♂️"],
    ["man_fairy_medium-light_skin_tone", "🧚🏼‍♂️"],
    ["man_fairy_medium_skin_tone", "🧚🏽‍♂️"],
    ["man_fairy_medium-dark_skin_tone", "🧚🏾‍♂️"],
    ["man_fairy_dark_skin_tone", "🧚🏿‍♂️"],
    ["woman_fairy", "🧚‍♀️"],
    ["woman_fairy_light_skin_tone", "🧚🏻‍♀️"],
    ["woman_fairy_medium-light_skin_tone", "🧚🏼‍♀️"],
    ["woman_fairy_medium_skin_tone", "🧚🏽‍♀️"],
    ["woman_fairy_medium-dark_skin_tone", "🧚🏾‍♀️"],
    ["woman_fairy_dark_skin_tone", "🧚🏿‍♀️"],
    ["vampire", "🧛"],
    ["vampire_light_skin_tone", "🧛🏻"],
    ["vampire_medium-light_skin_tone", "🧛🏼"],
    ["vampire_medium_skin_tone", "🧛🏽"],
    ["vampire_medium-dark_skin_tone", "🧛🏾"],
    ["vampire_dark_skin_tone", "🧛🏿"],
    ["man_vampire", "🧛‍♂️"],
    ["man_vampire_light_skin_tone", "🧛🏻‍♂️"],
    ["man_vampire_medium-light_skin_tone", "🧛🏼‍♂️"],
    ["man_vampire_medium_skin_tone", "🧛🏽‍♂️"],
    ["man_vampire_medium-dark_skin_tone", "🧛🏾‍♂️"],
    ["man_vampire_dark_skin_tone", "🧛🏿‍♂️"],
    ["woman_vampire", "🧛‍♀️"],
    ["woman_vampire_light_skin_tone", "🧛🏻‍♀️"],
    ["woman_vampire_medium-light_skin_tone", "🧛🏼‍♀️"],
    ["woman_vampire_medium_skin_tone", "🧛🏽‍♀️"],
    ["woman_vampire_medium-dark_skin_tone", "🧛🏾‍♀️"],
    ["woman_vampire_dark_skin_tone", "🧛🏿‍♀️"],
    ["merperson", "🧜"],
    ["merperson_light_skin_tone", "🧜🏻"],
    ["merperson_medium-light_skin_tone", "🧜🏼"],
    ["merperson_medium_skin_tone", "🧜🏽"],
    ["merperson_medium-dark_skin_tone", "🧜🏾"],
    ["merperson_dark_skin_tone", "🧜🏿"],
    ["merman", "🧜‍♂️"],
    ["merman_light_skin_tone", "🧜🏻‍♂️"],
    ["merman_medium-light_skin_tone", "🧜🏼‍♂️"],
    ["merman_medium_skin_tone", "🧜🏽‍♂️"],
    ["merman_medium-dark_skin_tone", "🧜🏾‍♂️"],
    ["merman_dark_skin_tone", "🧜🏿‍♂️"],
    ["mermaid", "🧜‍♀️"],
    ["mermaid_light_skin_tone", "🧜🏻‍♀️"],
    ["mermaid_medium-light_skin_tone", "🧜🏼‍♀️"],
    ["mermaid_medium_skin_tone", "🧜🏽‍♀️"],
    ["mermaid_medium-dark_skin_tone", "🧜🏾‍♀️"],
    ["mermaid_dark_skin_tone", "🧜🏿‍♀️"],
    ["elf", "🧝"],
    ["elf_light_skin_tone", "🧝🏻"],
    ["elf_medium-light_skin_tone", "🧝🏼"],
    ["elf_medium_skin_tone", "🧝🏽"],
    ["elf_medium-dark_skin_tone", "🧝🏾"],
    ["elf_dark_skin_tone", "🧝🏿"],
    ["man_elf", "🧝‍♂️"],
    ["man_elf_light_skin_tone", "🧝🏻‍♂️"],
    ["man_elf_medium-light_skin_tone", "🧝🏼‍♂️"],
    ["man_elf_medium_skin_tone", "🧝🏽‍♂️"],
    ["man_elf_medium-dark_skin_tone", "🧝🏾‍♂️"],
    ["man_elf_dark_skin_tone", "🧝🏿‍♂️"],
    ["woman_elf", "🧝‍♀️"],
    ["woman_elf_light_skin_tone", "🧝🏻‍♀️"],
    ["woman_elf_medium-light_skin_tone", "🧝🏼‍♀️"],
    ["woman_elf_medium_skin_tone", "🧝🏽‍♀️"],
    ["woman_elf_medium-dark_skin_tone", "🧝🏾‍♀️"],
    ["woman_elf_dark_skin_tone", "🧝🏿‍♀️"],
    ["genie", "🧞"],
    ["man_genie", "🧞‍♂️"],
    ["woman_genie", "🧞‍♀️"],
    ["zombie", "🧟"],
    ["man_zombie", "🧟‍♂️"],
    ["woman_zombie", "🧟‍♀️"],
    ["person_getting_massage", "💆"],
    ["person_getting_massage_light_skin_tone", "💆🏻"],
    ["person_getting_massage_medium-light_skin_tone", "💆🏼"],
    ["person_getting_massage_medium_skin_tone", "💆🏽"],
    ["person_getting_massage_medium-dark_skin_tone", "💆🏾"],
    ["person_getting_massage_dark_skin_tone", "💆🏿"],
    ["man_getting_massage", "💆‍♂️"],
    ["man_getting_massage_light_skin_tone", "💆🏻‍♂️"],
    ["man_getting_massage_medium-light_skin_tone", "💆🏼‍♂️"],
    ["man_getting_massage_medium_skin_tone", "💆🏽‍♂️"],
    ["man_getting_massage_medium-dark_skin_tone", "💆🏾‍♂️"],
    ["man_getting_massage_dark_skin_tone", "💆🏿‍♂️"],
    ["woman_getting_massage", "💆‍♀️"],
    ["woman_getting_massage_light_skin_tone", "💆🏻‍♀️"],
    ["woman_getting_massage_medium-light_skin_tone", "💆🏼‍♀️"],
    ["woman_getting_massage_medium_skin_tone", "💆🏽‍♀️"],
    ["woman_getting_massage_medium-dark_skin_tone", "💆🏾‍♀️"],
    ["woman_getting_massage_dark_skin_tone", "💆🏿‍♀️"],
    ["person_getting_haircut", "💇"],
    ["person_getting_haircut_light_skin_tone", "💇🏻"],
    ["person_getting_haircut_medium-light_skin_tone", "💇🏼"],
    ["person_getting_haircut_medium_skin_tone", "💇🏽"],
    ["person_getting_haircut_medium-dark_skin_tone", "💇🏾"],
    ["person_getting_haircut_dark_skin_tone", "💇🏿"],
    ["man_getting_haircut", "💇‍♂️"],
    ["man_getting_haircut_light_skin_tone", "💇🏻‍♂️"],
    ["man_getting_haircut_medium-light_skin_tone", "💇🏼‍♂️"],
    ["man_getting_haircut_medium_skin_tone", "💇🏽‍♂️"],
    ["man_getting_haircut_medium-dark_skin_tone", "💇🏾‍♂️"],
    ["man_getting_haircut_dark_skin_tone", "💇🏿‍♂️"],
    ["woman_getting_haircut", "💇‍♀️"],
    ["woman_getting_haircut_light_skin_tone", "💇🏻‍♀️"],
    ["woman_getting_haircut_medium-light_skin_tone", "💇🏼‍♀️"],
    ["woman_getting_haircut_medium_skin_tone", "💇🏽‍♀️"],
    ["woman_getting_haircut_medium-dark_skin_tone", "💇🏾‍♀️"],
    ["woman_getting_haircut_dark_skin_tone", "💇🏿‍♀️"],
    ["person_walking", "🚶"],
    ["person_walking_light_skin_tone", "🚶🏻"],
    ["person_walking_medium-light_skin_tone", "🚶🏼"],
    ["person_walking_medium_skin_tone", "🚶🏽"],
    ["person_walking_medium-dark_skin_tone", "🚶🏾"],
    ["person_walking_dark_skin_tone", "🚶🏿"],
    ["man_walking", "🚶‍♂️"],
    ["man_walking_light_skin_tone", "🚶🏻‍♂️"],
    ["man_walking_medium-light_skin_tone", "🚶🏼‍♂️"],
    ["man_walking_medium_skin_tone", "🚶🏽‍♂️"],
    ["man_walking_medium-dark_skin_tone", "🚶🏾‍♂️"],
    ["man_walking_dark_skin_tone", "🚶🏿‍♂️"],
    ["woman_walking", "🚶‍♀️"],
    ["woman_walking_light_skin_tone", "🚶🏻‍♀️"],
    ["woman_walking_medium-light_skin_tone", "🚶🏼‍♀️"],
    ["woman_walking_medium_skin_tone", "🚶🏽‍♀️"],
    ["woman_walking_medium-dark_skin_tone", "🚶🏾‍♀️"],
    ["woman_walking_dark_skin_tone", "🚶🏿‍♀️"],
    ["person_standing", "🧍"],
    ["person_standing_light_skin_tone", "🧍🏻"],
    ["person_standing_medium-light_skin_tone", "🧍🏼"],
    ["person_standing_medium_skin_tone", "🧍🏽"],
    ["person_standing_medium-dark_skin_tone", "🧍🏾"],
    ["person_standing_dark_skin_tone", "🧍🏿"],
    ["man_standing", "🧍‍♂️"],
    ["man_standing_light_skin_tone", "🧍🏻‍♂️"],
    ["man_standing_medium-light_skin_tone", "🧍🏼‍♂️"],
    ["man_standing_medium_skin_tone", "🧍🏽‍♂️"],
    ["man_standing_medium-dark_skin_tone", "🧍🏾‍♂️"],
    ["man_standing_dark_skin_tone", "🧍🏿‍♂️"],
    ["woman_standing", "🧍‍♀️"],
    ["woman_standing_light_skin_tone", "🧍🏻‍♀️"],
    ["woman_standing_medium-light_skin_tone", "🧍🏼‍♀️"],
    ["woman_standing_medium_skin_tone", "🧍🏽‍♀️"],
    ["woman_standing_medium-dark_skin_tone", "🧍🏾‍♀️"],
    ["woman_standing_dark_skin_tone", "🧍🏿‍♀️"],
    ["person_kneeling", "🧎"],
    ["person_kneeling_light_skin_tone", "🧎🏻"],
    ["person_kneeling_medium-light_skin_tone", "🧎🏼"],
    ["person_kneeling_medium_skin_tone", "🧎🏽"],
    ["person_kneeling_medium-dark_skin_tone", "🧎🏾"],
    ["person_kneeling_dark_skin_tone", "🧎🏿"],
    ["man_kneeling", "🧎‍♂️"],
    ["man_kneeling_light_skin_tone", "🧎🏻‍♂️"],
    ["man_kneeling_medium-light_skin_tone", "🧎🏼‍♂️"],
    ["man_kneeling_medium_skin_tone", "🧎🏽‍♂️"],
    ["man_kneeling_medium-dark_skin_tone", "🧎🏾‍♂️"],
    ["man_kneeling_dark_skin_tone", "🧎🏿‍♂️"],
    ["woman_kneeling", "🧎‍♀️"],
    ["woman_kneeling_light_skin_tone", "🧎🏻‍♀️"],
    ["woman_kneeling_medium-light_skin_tone", "🧎🏼‍♀️"],
    ["woman_kneeling_medium_skin_tone", "🧎🏽‍♀️"],
    ["woman_kneeling_medium-dark_skin_tone", "🧎🏾‍♀️"],
    ["woman_kneeling_dark_skin_tone", "🧎🏿‍♀️"],
    ["person_with_white_cane", "🧑‍🦯"],
    ["person_with_white_cane_light_skin_tone", "🧑🏻‍🦯"],
    ["person_with_white_cane_medium-light_skin_tone", "🧑🏼‍🦯"],
    ["person_with_white_cane_medium_skin_tone", "🧑🏽‍🦯"],
    ["person_with_white_cane_medium-dark_skin_tone", "🧑🏾‍🦯"],
    ["person_with_white_cane_dark_skin_tone", "🧑🏿‍🦯"],
    ["man_with_white_cane", "👨‍🦯"],
    ["man_with_white_cane_light_skin_tone", "👨🏻‍🦯"],
    ["man_with_white_cane_medium-light_skin_tone", "👨🏼‍🦯"],
    ["man_with_white_cane_medium_skin_tone", "👨🏽‍🦯"],
    ["man_with_white_cane_medium-dark_skin_tone", "👨🏾‍🦯"],
    ["man_with_white_cane_dark_skin_tone", "👨🏿‍🦯"],
    ["woman_with_white_cane", "👩‍🦯"],
    ["woman_with_white_cane_light_skin_tone", "👩🏻‍🦯"],
    ["woman_with_white_cane_medium-light_skin_tone", "👩🏼‍🦯"],
    ["woman_with_white_cane_medium_skin_tone", "👩🏽‍🦯"],
    ["woman_with_white_cane_medium-dark_skin_tone", "👩🏾‍🦯"],
    ["woman_with_white_cane_dark_skin_tone", "👩🏿‍🦯"],
    ["person_in_motorized_wheelchair", "🧑‍🦼"],
    ["person_in_motorized_wheelchair_light_skin_tone", "🧑🏻‍🦼"],
    ["person_in_motorized_wheelchair_medium-light_skin_tone", "🧑🏼‍🦼"],
    ["person_in_motorized_wheelchair_medium_skin_tone", "🧑🏽‍🦼"],
    ["person_in_motorized_wheelchair_medium-dark_skin_tone", "🧑🏾‍🦼"],
    ["person_in_motorized_wheelchair_dark_skin_tone", "🧑🏿‍🦼"],
    ["man_in_motorized_wheelchair", "👨‍🦼"],
    ["man_in_motorized_wheelchair_light_skin_tone", "👨🏻‍🦼"],
    ["man_in_motorized_wheelchair_medium-light_skin_tone", "👨🏼‍🦼"],
    ["man_in_motorized_wheelchair_medium_skin_tone", "👨🏽‍🦼"],
    ["man_in_motorized_wheelchair_medium-dark_skin_tone", "👨🏾‍🦼"],
    ["man_in_motorized_wheelchair_dark_skin_tone", "👨🏿‍🦼"],
    ["woman_in_motorized_wheelchair", "👩‍🦼"],
    ["woman_in_motorized_wheelchair_light_skin_tone", "👩🏻‍🦼"],
    ["woman_in_motorized_wheelchair_medium-light_skin_tone", "👩🏼‍🦼"],
    ["woman_in_motorized_wheelchair_medium_skin_tone", "👩🏽‍🦼"],
    ["woman_in_motorized_wheelchair_medium-dark_skin_tone", "👩🏾‍🦼"],
    ["woman_in_motorized_wheelchair_dark_skin_tone", "👩🏿‍🦼"],
    ["person_in_manual_wheelchair", "🧑‍🦽"],
    ["person_in_manual_wheelchair_light_skin_tone", "🧑🏻‍🦽"],
    ["person_in_manual_wheelchair_medium-light_skin_tone", "🧑🏼‍🦽"],
    ["person_in_manual_wheelchair_medium_skin_tone", "🧑🏽‍🦽"],
    ["person_in_manual_wheelchair_medium-dark_skin_tone", "🧑🏾‍🦽"],
    ["person_in_manual_wheelchair_dark_skin_tone", "🧑🏿‍🦽"],
    ["man_in_manual_wheelchair", "👨‍🦽"],
    ["man_in_manual_wheelchair_light_skin_tone", "👨🏻‍🦽"],
    ["man_in_manual_wheelchair_medium-light_skin_tone", "👨🏼‍🦽"],
    ["man_in_manual_wheelchair_medium_skin_tone", "👨🏽‍🦽"],
    ["man_in_manual_wheelchair_medium-dark_skin_tone", "👨🏾‍🦽"],
    ["man_in_manual_wheelchair_dark_skin_tone", "👨🏿‍🦽"],
    ["woman_in_manual_wheelchair", "👩‍🦽"],
    ["woman_in_manual_wheelchair_light_skin_tone", "👩🏻‍🦽"],
    ["woman_in_manual_wheelchair_medium-light_skin_tone", "👩🏼‍🦽"],
    ["woman_in_manual_wheelchair_medium_skin_tone", "👩🏽‍🦽"],
    ["woman_in_manual_wheelchair_medium-dark_skin_tone", "👩🏾‍🦽"],
    ["woman_in_manual_wheelchair_dark_skin_tone", "👩🏿‍🦽"],
    ["person_running", "🏃"],
    ["person_running_light_skin_tone", "🏃🏻"],
    ["person_running_medium-light_skin_tone", "🏃🏼"],
    ["person_running_medium_skin_tone", "🏃🏽"],
    ["person_running_medium-dark_skin_tone", "🏃🏾"],
    ["person_running_dark_skin_tone", "🏃🏿"],
    ["man_running", "🏃‍♂️"],
    ["man_running_light_skin_tone", "🏃🏻‍♂️"],
    ["man_running_medium-light_skin_tone", "🏃🏼‍♂️"],
    ["man_running_medium_skin_tone", "🏃🏽‍♂️"],
    ["man_running_medium-dark_skin_tone", "🏃🏾‍♂️"],
    ["man_running_dark_skin_tone", "🏃🏿‍♂️"],
    ["woman_running", "🏃‍♀️"],
    ["woman_running_light_skin_tone", "🏃🏻‍♀️"],
    ["woman_running_medium-light_skin_tone", "🏃🏼‍♀️"],
    ["woman_running_medium_skin_tone", "🏃🏽‍♀️"],
    ["woman_running_medium-dark_skin_tone", "🏃🏾‍♀️"],
    ["woman_running_dark_skin_tone", "🏃🏿‍♀️"],
    ["woman_dancing", "💃"],
    ["woman_dancing_light_skin_tone", "💃🏻"],
    ["woman_dancing_medium-light_skin_tone", "💃🏼"],
    ["woman_dancing_medium_skin_tone", "💃🏽"],
    ["woman_dancing_medium-dark_skin_tone", "💃🏾"],
    ["woman_dancing_dark_skin_tone", "💃🏿"],
    ["man_dancing", "🕺"],
    ["man_dancing_light_skin_tone", "🕺🏻"],
    ["man_dancing_medium-light_skin_tone", "🕺🏼"],
    ["man_dancing_medium_skin_tone", "🕺🏽"],
    ["man_dancing_medium-dark_skin_tone", "🕺🏾"],
    ["man_dancing_dark_skin_tone", "🕺🏿"],
    ["person_in_suit_levitating", "🕴️"],
    ["person_in_suit_levitating_light_skin_tone", "🕴🏻"],
    ["person_in_suit_levitating_medium-light_skin_tone", "🕴🏼"],
    ["person_in_suit_levitating_medium_skin_tone", "🕴🏽"],
    ["person_in_suit_levitating_medium-dark_skin_tone", "🕴🏾"],
    ["person_in_suit_levitating_dark_skin_tone", "🕴🏿"],
    ["people_with_bunny_ears", "👯"],
    ["men_with_bunny_ears", "👯‍♂️"],
    ["women_with_bunny_ears", "👯‍♀️"],
    ["person_in_steamy_room", "🧖"],
    ["person_in_steamy_room_light_skin_tone", "🧖🏻"],
    ["person_in_steamy_room_medium-light_skin_tone", "🧖🏼"],
    ["person_in_steamy_room_medium_skin_tone", "🧖🏽"],
    ["person_in_steamy_room_medium-dark_skin_tone", "🧖🏾"],
    ["person_in_steamy_room_dark_skin_tone", "🧖🏿"],
    ["man_in_steamy_room", "🧖‍♂️"],
    ["man_in_steamy_room_light_skin_tone", "🧖🏻‍♂️"],
    ["man_in_steamy_room_medium-light_skin_tone", "🧖🏼‍♂️"],
    ["man_in_steamy_room_medium_skin_tone", "🧖🏽‍♂️"],
    ["man_in_steamy_room_medium-dark_skin_tone", "🧖🏾‍♂️"],
    ["man_in_steamy_room_dark_skin_tone", "🧖🏿‍♂️"],
    ["woman_in_steamy_room", "🧖‍♀️"],
    ["woman_in_steamy_room_light_skin_tone", "🧖🏻‍♀️"],
    ["woman_in_steamy_room_medium-light_skin_tone", "🧖🏼‍♀️"],
    ["woman_in_steamy_room_medium_skin_tone", "🧖🏽‍♀️"],
    ["woman_in_steamy_room_medium-dark_skin_tone", "🧖🏾‍♀️"],
    ["woman_in_steamy_room_dark_skin_tone", "🧖🏿‍♀️"],
    ["person_climbing", "🧗"],
    ["person_climbing_light_skin_tone", "🧗🏻"],
    ["person_climbing_medium-light_skin_tone", "🧗🏼"],
    ["person_climbing_medium_skin_tone", "🧗🏽"],
    ["person_climbing_medium-dark_skin_tone", "🧗🏾"],
    ["person_climbing_dark_skin_tone", "🧗🏿"],
    ["man_climbing", "🧗‍♂️"],
    ["man_climbing_light_skin_tone", "🧗🏻‍♂️"],
    ["man_climbing_medium-light_skin_tone", "🧗🏼‍♂️"],
    ["man_climbing_medium_skin_tone", "🧗🏽‍♂️"],
    ["man_climbing_medium-dark_skin_tone", "🧗🏾‍♂️"],
    ["man_climbing_dark_skin_tone", "🧗🏿‍♂️"],
    ["woman_climbing", "🧗‍♀️"],
    ["woman_climbing_light_skin_tone", "🧗🏻‍♀️"],
    ["woman_climbing_medium-light_skin_tone", "🧗🏼‍♀️"],
    ["woman_climbing_medium_skin_tone", "🧗🏽‍♀️"],
    ["woman_climbing_medium-dark_skin_tone", "🧗🏾‍♀️"],
    ["woman_climbing_dark_skin_tone", "🧗🏿‍♀️"],
    ["person_fencing", "🤺"],
    ["horse_racing", "🏇"],
    ["horse_racing_light_skin_tone", "🏇🏻"],
    ["horse_racing_medium-light_skin_tone", "🏇🏼"],
    ["horse_racing_medium_skin_tone", "🏇🏽"],
    ["horse_racing_medium-dark_skin_tone", "🏇🏾"],
    ["horse_racing_dark_skin_tone", "🏇🏿"],
    ["skier", "⛷️"],
    ["snowboarder", "🏂"],
    ["snowboarder_light_skin_tone", "🏂🏻"],
    ["snowboarder_medium-light_skin_tone", "🏂🏼"],
    ["snowboarder_medium_skin_tone", "🏂🏽"],
    ["snowboarder_medium-dark_skin_tone", "🏂🏾"],
    ["snowboarder_dark_skin_tone", "🏂🏿"],
    ["person_golfing", "🏌️"],
    ["person_golfing_light_skin_tone", "🏌🏻"],
    ["person_golfing_medium-light_skin_tone", "🏌🏼"],
    ["person_golfing_medium_skin_tone", "🏌🏽"],
    ["person_golfing_medium-dark_skin_tone", "🏌🏾"],
    ["person_golfing_dark_skin_tone", "🏌🏿"],
    ["man_golfing", "🏌️‍♂️"],
    ["man_golfing_light_skin_tone", "🏌🏻‍♂️"],
    ["man_golfing_medium-light_skin_tone", "🏌🏼‍♂️"],
    ["man_golfing_medium_skin_tone", "🏌🏽‍♂️"],
    ["man_golfing_medium-dark_skin_tone", "🏌🏾‍♂️"],
    ["man_golfing_dark_skin_tone", "🏌🏿‍♂️"],
    ["woman_golfing", "🏌️‍♀️"],
    ["woman_golfing_light_skin_tone", "🏌🏻‍♀️"],
    ["woman_golfing_medium-light_skin_tone", "🏌🏼‍♀️"],
    ["woman_golfing_medium_skin_tone", "🏌🏽‍♀️"],
    ["woman_golfing_medium-dark_skin_tone", "🏌🏾‍♀️"],
    ["woman_golfing_dark_skin_tone", "🏌🏿‍♀️"],
    ["person_surfing", "🏄"],
    ["person_surfing_light_skin_tone", "🏄🏻"],
    ["person_surfing_medium-light_skin_tone", "🏄🏼"],
    ["person_surfing_medium_skin_tone", "🏄🏽"],
    ["person_surfing_medium-dark_skin_tone", "🏄🏾"],
    ["person_surfing_dark_skin_tone", "🏄🏿"],
    ["man_surfing", "🏄‍♂️"],
    ["man_surfing_light_skin_tone", "🏄🏻‍♂️"],
    ["man_surfing_medium-light_skin_tone", "🏄🏼‍♂️"],
    ["man_surfing_medium_skin_tone", "🏄🏽‍♂️"],
    ["man_surfing_medium-dark_skin_tone", "🏄🏾‍♂️"],
    ["man_surfing_dark_skin_tone", "🏄🏿‍♂️"],
    ["woman_surfing", "🏄‍♀️"],
    ["woman_surfing_light_skin_tone", "🏄🏻‍♀️"],
    ["woman_surfing_medium-light_skin_tone", "🏄🏼‍♀️"],
    ["woman_surfing_medium_skin_tone", "🏄🏽‍♀️"],
    ["woman_surfing_medium-dark_skin_tone", "🏄🏾‍♀️"],
    ["woman_surfing_dark_skin_tone", "🏄🏿‍♀️"],
    ["person_rowing_boat", "🚣"],
    ["person_rowing_boat_light_skin_tone", "🚣🏻"],
    ["person_rowing_boat_medium-light_skin_tone", "🚣🏼"],
    ["person_rowing_boat_medium_skin_tone", "🚣🏽"],
    ["person_rowing_boat_medium-dark_skin_tone", "🚣🏾"],
    ["person_rowing_boat_dark_skin_tone", "🚣🏿"],
    ["man_rowing_boat", "🚣‍♂️"],
    ["man_rowing_boat_light_skin_tone", "🚣🏻‍♂️"],
    ["man_rowing_boat_medium-light_skin_tone", "🚣🏼‍♂️"],
    ["man_rowing_boat_medium_skin_tone", "🚣🏽‍♂️"],
    ["man_rowing_boat_medium-dark_skin_tone", "🚣🏾‍♂️"],
    ["man_rowing_boat_dark_skin_tone", "🚣🏿‍♂️"],
    ["woman_rowing_boat", "🚣‍♀️"],
    ["woman_rowing_boat_light_skin_tone", "🚣🏻‍♀️"],
    ["woman_rowing_boat_medium-light_skin_tone", "🚣🏼‍♀️"],
    ["woman_rowing_boat_medium_skin_tone", "🚣🏽‍♀️"],
    ["woman_rowing_boat_medium-dark_skin_tone", "🚣🏾‍♀️"],
    ["woman_rowing_boat_dark_skin_tone", "🚣🏿‍♀️"],
    ["person_swimming", "🏊"],
    ["person_swimming_light_skin_tone", "🏊🏻"],
    ["person_swimming_medium-light_skin_tone", "🏊🏼"],
    ["person_swimming_medium_skin_tone", "🏊🏽"],
    ["person_swimming_medium-dark_skin_tone", "🏊🏾"],
    ["person_swimming_dark_skin_tone", "🏊🏿"],
    ["man_swimming", "🏊‍♂️"],
    ["man_swimming_light_skin_tone", "🏊🏻‍♂️"],
    ["man_swimming_medium-light_skin_tone", "🏊🏼‍♂️"],
    ["man_swimming_medium_skin_tone", "🏊🏽‍♂️"],
    ["man_swimming_medium-dark_skin_tone", "🏊🏾‍♂️"],
    ["man_swimming_dark_skin_tone", "🏊🏿‍♂️"],
    ["woman_swimming", "🏊‍♀️"],
    ["woman_swimming_light_skin_tone", "🏊🏻‍♀️"],
    ["woman_swimming_medium-light_skin_tone", "🏊🏼‍♀️"],
    ["woman_swimming_medium_skin_tone", "🏊🏽‍♀️"],
    ["woman_swimming_medium-dark_skin_tone", "🏊🏾‍♀️"],
    ["woman_swimming_dark_skin_tone", "🏊🏿‍♀️"],
    ["person_bouncing_ball", "⛹️"],
    ["person_bouncing_ball_light_skin_tone", "⛹🏻"],
    ["person_bouncing_ball_medium-light_skin_tone", "⛹🏼"],
    ["person_bouncing_ball_medium_skin_tone", "⛹🏽"],
    ["person_bouncing_ball_medium-dark_skin_tone", "⛹🏾"],
    ["person_bouncing_ball_dark_skin_tone", "⛹🏿"],
    ["man_bouncing_ball", "⛹️‍♂️"],
    ["man_bouncing_ball_light_skin_tone", "⛹🏻‍♂️"],
    ["man_bouncing_ball_medium-light_skin_tone", "⛹🏼‍♂️"],
    ["man_bouncing_ball_medium_skin_tone", "⛹🏽‍♂️"],
    ["man_bouncing_ball_medium-dark_skin_tone", "⛹🏾‍♂️"],
    ["man_bouncing_ball_dark_skin_tone", "⛹🏿‍♂️"],
    ["woman_bouncing_ball", "⛹️‍♀️"],
    ["woman_bouncing_ball_light_skin_tone", "⛹🏻‍♀️"],
    ["woman_bouncing_ball_medium-light_skin_tone", "⛹🏼‍♀️"],
    ["woman_bouncing_ball_medium_skin_tone", "⛹🏽‍♀️"],
    ["woman_bouncing_ball_medium-dark_skin_tone", "⛹🏾‍♀️"],
    ["woman_bouncing_ball_dark_skin_tone", "⛹🏿‍♀️"],
    ["person_lifting_weights", "🏋️"],
    ["person_lifting_weights_light_skin_tone", "🏋🏻"],
    ["person_lifting_weights_medium-light_skin_tone", "🏋🏼"],
    ["person_lifting_weights_medium_skin_tone", "🏋🏽"],
    ["person_lifting_weights_medium-dark_skin_tone", "🏋🏾"],
    ["person_lifting_weights_dark_skin_tone", "🏋🏿"],
    ["man_lifting_weights", "🏋️‍♂️"],
    ["man_lifting_weights_light_skin_tone", "🏋🏻‍♂️"],
    ["man_lifting_weights_medium-light_skin_tone", "🏋🏼‍♂️"],
    ["man_lifting_weights_medium_skin_tone", "🏋🏽‍♂️"],
    ["man_lifting_weights_medium-dark_skin_tone", "🏋🏾‍♂️"],
    ["man_lifting_weights_dark_skin_tone", "🏋🏿‍♂️"],
    ["woman_lifting_weights", "🏋️‍♀️"],
    ["woman_lifting_weights_light_skin_tone", "🏋🏻‍♀️"],
    ["woman_lifting_weights_medium-light_skin_tone", "🏋🏼‍♀️"],
    ["woman_lifting_weights_medium_skin_tone", "🏋🏽‍♀️"],
    ["woman_lifting_weights_medium-dark_skin_tone", "🏋🏾‍♀️"],
    ["woman_lifting_weights_dark_skin_tone", "🏋🏿‍♀️"],
    ["person_biking", "🚴"],
    ["person_biking_light_skin_tone", "🚴🏻"],
    ["person_biking_medium-light_skin_tone", "🚴🏼"],
    ["person_biking_medium_skin_tone", "🚴🏽"],
    ["person_biking_medium-dark_skin_tone", "🚴🏾"],
    ["person_biking_dark_skin_tone", "🚴🏿"],
    ["man_biking", "🚴‍♂️"],
    ["man_biking_light_skin_tone", "🚴🏻‍♂️"],
    ["man_biking_medium-light_skin_tone", "🚴🏼‍♂️"],
    ["man_biking_medium_skin_tone", "🚴🏽‍♂️"],
    ["man_biking_medium-dark_skin_tone", "🚴🏾‍♂️"],
    ["man_biking_dark_skin_tone", "🚴🏿‍♂️"],
    ["woman_biking", "🚴‍♀️"],
    ["woman_biking_light_skin_tone", "🚴🏻‍♀️"],
    ["woman_biking_medium-light_skin_tone", "🚴🏼‍♀️"],
    ["woman_biking_medium_skin_tone", "🚴🏽‍♀️"],
    ["woman_biking_medium-dark_skin_tone", "🚴🏾‍♀️"],
    ["woman_biking_dark_skin_tone", "🚴🏿‍♀️"],
    ["person_mountain_biking", "🚵"],
    ["person_mountain_biking_light_skin_tone", "🚵🏻"],
    ["person_mountain_biking_medium-light_skin_tone", "🚵🏼"],
    ["person_mountain_biking_medium_skin_tone", "🚵🏽"],
    ["person_mountain_biking_medium-dark_skin_tone", "🚵🏾"],
    ["person_mountain_biking_dark_skin_tone", "🚵🏿"],
    ["man_mountain_biking", "🚵‍♂️"],
    ["man_mountain_biking_light_skin_tone", "🚵🏻‍♂️"],
    ["man_mountain_biking_medium-light_skin_tone", "🚵🏼‍♂️"],
    ["man_mountain_biking_medium_skin_tone", "🚵🏽‍♂️"],
    ["man_mountain_biking_medium-dark_skin_tone", "🚵🏾‍♂️"],
    ["man_mountain_biking_dark_skin_tone", "🚵🏿‍♂️"],
    ["woman_mountain_biking", "🚵‍♀️"],
    ["woman_mountain_biking_light_skin_tone", "🚵🏻‍♀️"],
    ["woman_mountain_biking_medium-light_skin_tone", "🚵🏼‍♀️"],
    ["woman_mountain_biking_medium_skin_tone", "🚵🏽‍♀️"],
    ["woman_mountain_biking_medium-dark_skin_tone", "🚵🏾‍♀️"],
    ["woman_mountain_biking_dark_skin_tone", "🚵🏿‍♀️"],
    ["person_cartwheeling", "🤸"],
    ["person_cartwheeling_light_skin_tone", "🤸🏻"],
    ["person_cartwheeling_medium-light_skin_tone", "🤸🏼"],
    ["person_cartwheeling_medium_skin_tone", "🤸🏽"],
    ["person_cartwheeling_medium-dark_skin_tone", "🤸🏾"],
    ["person_cartwheeling_dark_skin_tone", "🤸🏿"],
    ["man_cartwheeling", "🤸‍♂️"],
    ["man_cartwheeling_light_skin_tone", "🤸🏻‍♂️"],
    ["man_cartwheeling_medium-light_skin_tone", "🤸🏼‍♂️"],
    ["man_cartwheeling_medium_skin_tone", "🤸🏽‍♂️"],
    ["man_cartwheeling_medium-dark_skin_tone", "🤸🏾‍♂️"],
    ["man_cartwheeling_dark_skin_tone", "🤸🏿‍♂️"],
    ["woman_cartwheeling", "🤸‍♀️"],
    ["woman_cartwheeling_light_skin_tone", "🤸🏻‍♀️"],
    ["woman_cartwheeling_medium-light_skin_tone", "🤸🏼‍♀️"],
    ["woman_cartwheeling_medium_skin_tone", "🤸🏽‍♀️"],
    ["woman_cartwheeling_medium-dark_skin_tone", "🤸🏾‍♀️"],
    ["woman_cartwheeling_dark_skin_tone", "🤸🏿‍♀️"],
    ["people_wrestling", "🤼"],
    ["men_wrestling", "🤼‍♂️"],
    ["women_wrestling", "🤼‍♀️"],
    ["person_playing_water_polo", "🤽"],
    ["person_playing_water_polo_light_skin_tone", "🤽🏻"],
    ["person_playing_water_polo_medium-light_skin_tone", "🤽🏼"],
    ["person_playing_water_polo_medium_skin_tone", "🤽🏽"],
    ["person_playing_water_polo_medium-dark_skin_tone", "🤽🏾"],
    ["person_playing_water_polo_dark_skin_tone", "🤽🏿"],
    ["man_playing_water_polo", "🤽‍♂️"],
    ["man_playing_water_polo_light_skin_tone", "🤽🏻‍♂️"],
    ["man_playing_water_polo_medium-light_skin_tone", "🤽🏼‍♂️"],
    ["man_playing_water_polo_medium_skin_tone", "🤽🏽‍♂️"],
    ["man_playing_water_polo_medium-dark_skin_tone", "🤽🏾‍♂️"],
    ["man_playing_water_polo_dark_skin_tone", "🤽🏿‍♂️"],
    ["woman_playing_water_polo", "🤽‍♀️"],
    ["woman_playing_water_polo_light_skin_tone", "🤽🏻‍♀️"],
    ["woman_playing_water_polo_medium-light_skin_tone", "🤽🏼‍♀️"],
    ["woman_playing_water_polo_medium_skin_tone", "🤽🏽‍♀️"],
    ["woman_playing_water_polo_medium-dark_skin_tone", "🤽🏾‍♀️"],
    ["woman_playing_water_polo_dark_skin_tone", "🤽🏿‍♀️"],
    ["person_playing_handball", "🤾"],
    ["person_playing_handball_light_skin_tone", "🤾🏻"],
    ["person_playing_handball_medium-light_skin_tone", "🤾🏼"],
    ["person_playing_handball_medium_skin_tone", "🤾🏽"],
    ["person_playing_handball_medium-dark_skin_tone", "🤾🏾"],
    ["person_playing_handball_dark_skin_tone", "🤾🏿"],
    ["man_playing_handball", "🤾‍♂️"],
    ["man_playing_handball_light_skin_tone", "🤾🏻‍♂️"],
    ["man_playing_handball_medium-light_skin_tone", "🤾🏼‍♂️"],
    ["man_playing_handball_medium_skin_tone", "🤾🏽‍♂️"],
    ["man_playing_handball_medium-dark_skin_tone", "🤾🏾‍♂️"],
    ["man_playing_handball_dark_skin_tone", "🤾🏿‍♂️"],
    ["woman_playing_handball", "🤾‍♀️"],
    ["woman_playing_handball_light_skin_tone", "🤾🏻‍♀️"],
    ["woman_playing_handball_medium-light_skin_tone", "🤾🏼‍♀️"],
    ["woman_playing_handball_medium_skin_tone", "🤾🏽‍♀️"],
    ["woman_playing_handball_medium-dark_skin_tone", "🤾🏾‍♀️"],
    ["woman_playing_handball_dark_skin_tone", "🤾🏿‍♀️"],
    ["person_juggling", "🤹"],
    ["person_juggling_light_skin_tone", "🤹🏻"],
    ["person_juggling_medium-light_skin_tone", "🤹🏼"],
    ["person_juggling_medium_skin_tone", "🤹🏽"],
    ["person_juggling_medium-dark_skin_tone", "🤹🏾"],
    ["person_juggling_dark_skin_tone", "🤹🏿"],
    ["man_juggling", "🤹‍♂️"],
    ["man_juggling_light_skin_tone", "🤹🏻‍♂️"],
    ["man_juggling_medium-light_skin_tone", "🤹🏼‍♂️"],
    ["man_juggling_medium_skin_tone", "🤹🏽‍♂️"],
    ["man_juggling_medium-dark_skin_tone", "🤹🏾‍♂️"],
    ["man_juggling_dark_skin_tone", "🤹🏿‍♂️"],
    ["woman_juggling", "🤹‍♀️"],
    ["woman_juggling_light_skin_tone", "🤹🏻‍♀️"],
    ["woman_juggling_medium-light_skin_tone", "🤹🏼‍♀️"],
    ["woman_juggling_medium_skin_tone", "🤹🏽‍♀️"],
    ["woman_juggling_medium-dark_skin_tone", "🤹🏾‍♀️"],
    ["woman_juggling_dark_skin_tone", "🤹🏿‍♀️"],
    ["person_in_lotus_position", "🧘"],
    ["person_in_lotus_position_light_skin_tone", "🧘🏻"],
    ["person_in_lotus_position_medium-light_skin_tone", "🧘🏼"],
    ["person_in_lotus_position_medium_skin_tone", "🧘🏽"],
    ["person_in_lotus_position_medium-dark_skin_tone", "🧘🏾"],
    ["person_in_lotus_position_dark_skin_tone", "🧘🏿"],
    ["man_in_lotus_position", "🧘‍♂️"],
    ["man_in_lotus_position_light_skin_tone", "🧘🏻‍♂️"],
    ["man_in_lotus_position_medium-light_skin_tone", "🧘🏼‍♂️"],
    ["man_in_lotus_position_medium_skin_tone", "🧘🏽‍♂️"],
    ["man_in_lotus_position_medium-dark_skin_tone", "🧘🏾‍♂️"],
    ["man_in_lotus_position_dark_skin_tone", "🧘🏿‍♂️"],
    ["woman_in_lotus_position", "🧘‍♀️"],
    ["woman_in_lotus_position_light_skin_tone", "🧘🏻‍♀️"],
    ["woman_in_lotus_position_medium-light_skin_tone", "🧘🏼‍♀️"],
    ["woman_in_lotus_position_medium_skin_tone", "🧘🏽‍♀️"],
    ["woman_in_lotus_position_medium-dark_skin_tone", "🧘🏾‍♀️"],
    ["woman_in_lotus_position_dark_skin_tone", "🧘🏿‍♀️"],
    ["person_taking_bath", "🛀"],
    ["person_taking_bath_light_skin_tone", "🛀🏻"],
    ["person_taking_bath_medium-light_skin_tone", "🛀🏼"],
    ["person_taking_bath_medium_skin_tone", "🛀🏽"],
    ["person_taking_bath_medium-dark_skin_tone", "🛀🏾"],
    ["person_taking_bath_dark_skin_tone", "🛀🏿"],
    ["person_in_bed", "🛌"],
    ["person_in_bed_light_skin_tone", "🛌🏻"],
    ["person_in_bed_medium-light_skin_tone", "🛌🏼"],
    ["person_in_bed_medium_skin_tone", "🛌🏽"],
    ["person_in_bed_medium-dark_skin_tone", "🛌🏾"],
    ["person_in_bed_dark_skin_tone", "🛌🏿"],
    ["people_holding_hands", "🧑‍🤝‍🧑"],
    ["people_holding_hands_light_skin_tone", "🧑🏻‍🤝‍🧑🏻"],
    ["people_holding_hands_light_skin_tone,_medium-light_skin_tone", "🧑🏻‍🤝‍🧑🏼"],
    ["people_holding_hands_light_skin_tone,_medium_skin_tone", "🧑🏻‍🤝‍🧑🏽"],
    ["people_holding_hands_light_skin_tone,_medium-dark_skin_tone", "🧑🏻‍🤝‍🧑🏾"],
    ["people_holding_hands_light_skin_tone,_dark_skin_tone", "🧑🏻‍🤝‍🧑🏿"],
    ["people_holding_hands_medium-light_skin_tone,_light_skin_tone", "🧑🏼‍🤝‍🧑🏻"],
    ["people_holding_hands_medium-light_skin_tone", "🧑🏼‍🤝‍🧑🏼"],
    ["people_holding_hands_medium-light_skin_tone,_medium_skin_tone", "🧑🏼‍🤝‍🧑🏽"],
    ["people_holding_hands_medium-light_skin_tone,_medium-dark_skin_tone", "🧑🏼‍🤝‍🧑🏾"],
    ["people_holding_hands_medium-light_skin_tone,_dark_skin_tone", "🧑🏼‍🤝‍🧑🏿"],
    ["people_holding_hands_medium_skin_tone,_light_skin_tone", "🧑🏽‍🤝‍🧑🏻"],
    ["people_holding_hands_medium_skin_tone,_medium-light_skin_tone", "🧑🏽‍🤝‍🧑🏼"],
    ["people_holding_hands_medium_skin_tone", "🧑🏽‍🤝‍🧑🏽"],
    ["people_holding_hands_medium_skin_tone,_medium-dark_skin_tone", "🧑🏽‍🤝‍🧑🏾"],
    ["people_holding_hands_medium_skin_tone,_dark_skin_tone", "🧑🏽‍🤝‍🧑🏿"],
    ["people_holding_hands_medium-dark_skin_tone,_light_skin_tone", "🧑🏾‍🤝‍🧑🏻"],
    ["people_holding_hands_medium-dark_skin_tone,_medium-light_skin_tone", "🧑🏾‍🤝‍🧑🏼"],
    ["people_holding_hands_medium-dark_skin_tone,_medium_skin_tone", "🧑🏾‍🤝‍🧑🏽"],
    ["people_holding_hands_medium-dark_skin_tone", "🧑🏾‍🤝‍🧑🏾"],
    ["people_holding_hands_medium-dark_skin_tone,_dark_skin_tone", "🧑🏾‍🤝‍🧑🏿"],
    ["people_holding_hands_dark_skin_tone,_light_skin_tone", "🧑🏿‍🤝‍🧑🏻"],
    ["people_holding_hands_dark_skin_tone,_medium-light_skin_tone", "🧑🏿‍🤝‍🧑🏼"],
    ["people_holding_hands_dark_skin_tone,_medium_skin_tone", "🧑🏿‍🤝‍🧑🏽"],
    ["people_holding_hands_dark_skin_tone,_medium-dark_skin_tone", "🧑🏿‍🤝‍🧑🏾"],
    ["people_holding_hands_dark_skin_tone", "🧑🏿‍🤝‍🧑🏿"],
    ["women_holding_hands", "👭"],
    ["women_holding_hands_light_skin_tone", "👭🏻"],
    ["women_holding_hands_light_skin_tone,_medium-light_skin_tone", "👩🏻‍🤝‍👩🏼"],
    ["women_holding_hands_light_skin_tone,_medium_skin_tone", "👩🏻‍🤝‍👩🏽"],
    ["women_holding_hands_light_skin_tone,_medium-dark_skin_tone", "👩🏻‍🤝‍👩🏾"],
    ["women_holding_hands_light_skin_tone,_dark_skin_tone", "👩🏻‍🤝‍👩🏿"],
    ["women_holding_hands_medium-light_skin_tone,_light_skin_tone", "👩🏼‍🤝‍👩🏻"],
    ["women_holding_hands_medium-light_skin_tone", "👭🏼"],
    ["women_holding_hands_medium-light_skin_tone,_medium_skin_tone", "👩🏼‍🤝‍👩🏽"],
    ["women_holding_hands_medium-light_skin_tone,_medium-dark_skin_tone", "👩🏼‍🤝‍👩🏾"],
    ["women_holding_hands_medium-light_skin_tone,_dark_skin_tone", "👩🏼‍🤝‍👩🏿"],
    ["women_holding_hands_medium_skin_tone,_light_skin_tone", "👩🏽‍🤝‍👩🏻"],
    ["women_holding_hands_medium_skin_tone,_medium-light_skin_tone", "👩🏽‍🤝‍👩🏼"],
    ["women_holding_hands_medium_skin_tone", "👭🏽"],
    ["women_holding_hands_medium_skin_tone,_medium-dark_skin_tone", "👩🏽‍🤝‍👩🏾"],
    ["women_holding_hands_medium_skin_tone,_dark_skin_tone", "👩🏽‍🤝‍👩🏿"],
    ["women_holding_hands_medium-dark_skin_tone,_light_skin_tone", "👩🏾‍🤝‍👩🏻"],
    ["women_holding_hands_medium-dark_skin_tone,_medium-light_skin_tone", "👩🏾‍🤝‍👩🏼"],
    ["women_holding_hands_medium-dark_skin_tone,_medium_skin_tone", "👩🏾‍🤝‍👩🏽"],
    ["women_holding_hands_medium-dark_skin_tone", "👭🏾"],
    ["women_holding_hands_medium-dark_skin_tone,_dark_skin_tone", "👩🏾‍🤝‍👩🏿"],
    ["women_holding_hands_dark_skin_tone,_light_skin_tone", "👩🏿‍🤝‍👩🏻"],
    ["women_holding_hands_dark_skin_tone,_medium-light_skin_tone", "👩🏿‍🤝‍👩🏼"],
    ["women_holding_hands_dark_skin_tone,_medium_skin_tone", "👩🏿‍🤝‍👩🏽"],
    ["women_holding_hands_dark_skin_tone,_medium-dark_skin_tone", "👩🏿‍🤝‍👩🏾"],
    ["women_holding_hands_dark_skin_tone", "👭🏿"],
    ["woman_and_man_holding_hands", "👫"],
    ["woman_and_man_holding_hands_light_skin_tone", "👫🏻"],
    ["woman_and_man_holding_hands_light_skin_tone,_medium-light_skin_tone", "👩🏻‍🤝‍👨🏼"],
    ["woman_and_man_holding_hands_light_skin_tone,_medium_skin_tone", "👩🏻‍🤝‍👨🏽"],
    ["woman_and_man_holding_hands_light_skin_tone,_medium-dark_skin_tone", "👩🏻‍🤝‍👨🏾"],
    ["woman_and_man_holding_hands_light_skin_tone,_dark_skin_tone", "👩🏻‍🤝‍👨🏿"],
    ["woman_and_man_holding_hands_medium-light_skin_tone,_light_skin_tone", "👩🏼‍🤝‍👨🏻"],
    ["woman_and_man_holding_hands_medium-light_skin_tone", "👫🏼"],
    ["woman_and_man_holding_hands_medium-light_skin_tone,_medium_skin_tone", "👩🏼‍🤝‍👨🏽"],
    ["woman_and_man_holding_hands_medium-light_skin_tone,_medium-dark_skin_tone", "👩🏼‍🤝‍👨🏾"],
    ["woman_and_man_holding_hands_medium-light_skin_tone,_dark_skin_tone", "👩🏼‍🤝‍👨🏿"],
    ["woman_and_man_holding_hands_medium_skin_tone,_light_skin_tone", "👩🏽‍🤝‍👨🏻"],
    ["woman_and_man_holding_hands_medium_skin_tone,_medium-light_skin_tone", "👩🏽‍🤝‍👨🏼"],
    ["woman_and_man_holding_hands_medium_skin_tone", "👫🏽"],
    ["woman_and_man_holding_hands_medium_skin_tone,_medium-dark_skin_tone", "👩🏽‍🤝‍👨🏾"],
    ["woman_and_man_holding_hands_medium_skin_tone,_dark_skin_tone", "👩🏽‍🤝‍👨🏿"],
    ["woman_and_man_holding_hands_medium-dark_skin_tone,_light_skin_tone", "👩🏾‍🤝‍👨🏻"],
    ["woman_and_man_holding_hands_medium-dark_skin_tone,_medium-light_skin_tone", "👩🏾‍🤝‍👨🏼"],
    ["woman_and_man_holding_hands_medium-dark_skin_tone,_medium_skin_tone", "👩🏾‍🤝‍👨🏽"],
    ["woman_and_man_holding_hands_medium-dark_skin_tone", "👫🏾"],
    ["woman_and_man_holding_hands_medium-dark_skin_tone,_dark_skin_tone", "👩🏾‍🤝‍👨🏿"],
    ["woman_and_man_holding_hands_dark_skin_tone,_light_skin_tone", "👩🏿‍🤝‍👨🏻"],
    ["woman_and_man_holding_hands_dark_skin_tone,_medium-light_skin_tone", "👩🏿‍🤝‍👨🏼"],
    ["woman_and_man_holding_hands_dark_skin_tone,_medium_skin_tone", "👩🏿‍🤝‍👨🏽"],
    ["woman_and_man_holding_hands_dark_skin_tone,_medium-dark_skin_tone", "👩🏿‍🤝‍👨🏾"],
    ["woman_and_man_holding_hands_dark_skin_tone", "👫🏿"],
    ["men_holding_hands", "👬"],
    ["men_holding_hands_light_skin_tone", "👬🏻"],
    ["men_holding_hands_light_skin_tone,_medium-light_skin_tone", "👨🏻‍🤝‍👨🏼"],
    ["men_holding_hands_light_skin_tone,_medium_skin_tone", "👨🏻‍🤝‍👨🏽"],
    ["men_holding_hands_light_skin_tone,_medium-dark_skin_tone", "👨🏻‍🤝‍👨🏾"],
    ["men_holding_hands_light_skin_tone,_dark_skin_tone", "👨🏻‍🤝‍👨🏿"],
    ["men_holding_hands_medium-light_skin_tone,_light_skin_tone", "👨🏼‍🤝‍👨🏻"],
    ["men_holding_hands_medium-light_skin_tone", "👬🏼"],
    ["men_holding_hands_medium-light_skin_tone,_medium_skin_tone", "👨🏼‍🤝‍👨🏽"],
    ["men_holding_hands_medium-light_skin_tone,_medium-dark_skin_tone", "👨🏼‍🤝‍👨🏾"],
    ["men_holding_hands_medium-light_skin_tone,_dark_skin_tone", "👨🏼‍🤝‍👨🏿"],
    ["men_holding_hands_medium_skin_tone,_light_skin_tone", "👨🏽‍🤝‍👨🏻"],
    ["men_holding_hands_medium_skin_tone,_medium-light_skin_tone", "👨🏽‍🤝‍👨🏼"],
    ["men_holding_hands_medium_skin_tone", "👬🏽"],
    ["men_holding_hands_medium_skin_tone,_medium-dark_skin_tone", "👨🏽‍🤝‍👨🏾"],
    ["men_holding_hands_medium_skin_tone,_dark_skin_tone", "👨🏽‍🤝‍👨🏿"],
    ["men_holding_hands_medium-dark_skin_tone,_light_skin_tone", "👨🏾‍🤝‍👨🏻"],
    ["men_holding_hands_medium-dark_skin_tone,_medium-light_skin_tone", "👨🏾‍🤝‍👨🏼"],
    ["men_holding_hands_medium-dark_skin_tone,_medium_skin_tone", "👨🏾‍🤝‍👨🏽"],
    ["men_holding_hands_medium-dark_skin_tone", "👬🏾"],
    ["men_holding_hands_medium-dark_skin_tone,_dark_skin_tone", "👨🏾‍🤝‍👨🏿"],
    ["men_holding_hands_dark_skin_tone,_light_skin_tone", "👨🏿‍🤝‍👨🏻"],
    ["men_holding_hands_dark_skin_tone,_medium-light_skin_tone", "👨🏿‍🤝‍👨🏼"],
    ["men_holding_hands_dark_skin_tone,_medium_skin_tone", "👨🏿‍🤝‍👨🏽"],
    ["men_holding_hands_dark_skin_tone,_medium-dark_skin_tone", "👨🏿‍🤝‍👨🏾"],
    ["men_holding_hands_dark_skin_tone", "👬🏿"],
    ["kiss", "💏"],
    ["kiss_light_skin_tone", "💏🏻"],
    ["kiss_medium-light_skin_tone", "💏🏼"],
    ["kiss_medium_skin_tone", "💏🏽"],
    ["kiss_medium-dark_skin_tone", "💏🏾"],
    ["kiss_dark_skin_tone", "💏🏿"],
    ["kiss_person,_person,_light_skin_tone,_medium-light_skin_tone", "🧑🏻‍❤️‍💋‍🧑🏼"],
    ["kiss_person,_person,_light_skin_tone,_medium_skin_tone", "🧑🏻‍❤️‍💋‍🧑🏽"],
    ["kiss_person,_person,_light_skin_tone,_medium-dark_skin_tone", "🧑🏻‍❤️‍💋‍🧑🏾"],
    ["kiss_person,_person,_light_skin_tone,_dark_skin_tone", "🧑🏻‍❤️‍💋‍🧑🏿"],
    ["kiss_person,_person,_medium-light_skin_tone,_light_skin_tone", "🧑🏼‍❤️‍💋‍🧑🏻"],
    ["kiss_person,_person,_medium-light_skin_tone,_medium_skin_tone", "🧑🏼‍❤️‍💋‍🧑🏽"],
    ["kiss_person,_person,_medium-light_skin_tone,_medium-dark_skin_tone", "🧑🏼‍❤️‍💋‍🧑🏾"],
    ["kiss_person,_person,_medium-light_skin_tone,_dark_skin_tone", "🧑🏼‍❤️‍💋‍🧑🏿"],
    ["kiss_person,_person,_medium_skin_tone,_light_skin_tone", "🧑🏽‍❤️‍💋‍🧑🏻"],
    ["kiss_person,_person,_medium_skin_tone,_medium-light_skin_tone", "🧑🏽‍❤️‍💋‍🧑🏼"],
    ["kiss_person,_person,_medium_skin_tone,_medium-dark_skin_tone", "🧑🏽‍❤️‍💋‍🧑🏾"],
    ["kiss_person,_person,_medium_skin_tone,_dark_skin_tone", "🧑🏽‍❤️‍💋‍🧑🏿"],
    ["kiss_person,_person,_medium-dark_skin_tone,_light_skin_tone", "🧑🏾‍❤️‍💋‍🧑🏻"],
    ["kiss_person,_person,_medium-dark_skin_tone,_medium-light_skin_tone", "🧑🏾‍❤️‍💋‍🧑🏼"],
    ["kiss_person,_person,_medium-dark_skin_tone,_medium_skin_tone", "🧑🏾‍❤️‍💋‍🧑🏽"],
    ["kiss_person,_person,_medium-dark_skin_tone,_dark_skin_tone", "🧑🏾‍❤️‍💋‍🧑🏿"],
    ["kiss_person,_person,_dark_skin_tone,_light_skin_tone", "🧑🏿‍❤️‍💋‍🧑🏻"],
    ["kiss_person,_person,_dark_skin_tone,_medium-light_skin_tone", "🧑🏿‍❤️‍💋‍🧑🏼"],
    ["kiss_person,_person,_dark_skin_tone,_medium_skin_tone", "🧑🏿‍❤️‍💋‍🧑🏽"],
    ["kiss_person,_person,_dark_skin_tone,_medium-dark_skin_tone", "🧑🏿‍❤️‍💋‍🧑🏾"],
    ["kiss_woman,_man", "👩‍❤️‍💋‍👨"],
    ["kiss_woman,_man,_light_skin_tone", "👩🏻‍❤️‍💋‍👨🏻"],
    ["kiss_woman,_man,_light_skin_tone,_medium-light_skin_tone", "👩🏻‍❤️‍💋‍👨🏼"],
    ["kiss_woman,_man,_light_skin_tone,_medium_skin_tone", "👩🏻‍❤️‍💋‍👨🏽"],
    ["kiss_woman,_man,_light_skin_tone,_medium-dark_skin_tone", "👩🏻‍❤️‍💋‍👨🏾"],
    ["kiss_woman,_man,_light_skin_tone,_dark_skin_tone", "👩🏻‍❤️‍💋‍👨🏿"],
    ["kiss_woman,_man,_medium-light_skin_tone,_light_skin_tone", "👩🏼‍❤️‍💋‍👨🏻"],
    ["kiss_woman,_man,_medium-light_skin_tone", "👩🏼‍❤️‍💋‍👨🏼"],
    ["kiss_woman,_man,_medium-light_skin_tone,_medium_skin_tone", "👩🏼‍❤️‍💋‍👨🏽"],
    ["kiss_woman,_man,_medium-light_skin_tone,_medium-dark_skin_tone", "👩🏼‍❤️‍💋‍👨🏾"],
    ["kiss_woman,_man,_medium-light_skin_tone,_dark_skin_tone", "👩🏼‍❤️‍💋‍👨🏿"],
    ["kiss_woman,_man,_medium_skin_tone,_light_skin_tone", "👩🏽‍❤️‍💋‍👨🏻"],
    ["kiss_woman,_man,_medium_skin_tone,_medium-light_skin_tone", "👩🏽‍❤️‍💋‍👨🏼"],
    ["kiss_woman,_man,_medium_skin_tone", "👩🏽‍❤️‍💋‍👨🏽"],
    ["kiss_woman,_man,_medium_skin_tone,_medium-dark_skin_tone", "👩🏽‍❤️‍💋‍👨🏾"],
    ["kiss_woman,_man,_medium_skin_tone,_dark_skin_tone", "👩🏽‍❤️‍💋‍👨🏿"],
    ["kiss_woman,_man,_medium-dark_skin_tone,_light_skin_tone", "👩🏾‍❤️‍💋‍👨🏻"],
    ["kiss_woman,_man,_medium-dark_skin_tone,_medium-light_skin_tone", "👩🏾‍❤️‍💋‍👨🏼"],
    ["kiss_woman,_man,_medium-dark_skin_tone,_medium_skin_tone", "👩🏾‍❤️‍💋‍👨🏽"],
    ["kiss_woman,_man,_medium-dark_skin_tone", "👩🏾‍❤️‍💋‍👨🏾"],
    ["kiss_woman,_man,_medium-dark_skin_tone,_dark_skin_tone", "👩🏾‍❤️‍💋‍👨🏿"],
    ["kiss_woman,_man,_dark_skin_tone,_light_skin_tone", "👩🏿‍❤️‍💋‍👨🏻"],
    ["kiss_woman,_man,_dark_skin_tone,_medium-light_skin_tone", "👩🏿‍❤️‍💋‍👨🏼"],
    ["kiss_woman,_man,_dark_skin_tone,_medium_skin_tone", "👩🏿‍❤️‍💋‍👨🏽"],
    ["kiss_woman,_man,_dark_skin_tone,_medium-dark_skin_tone", "👩🏿‍❤️‍💋‍👨🏾"],
    ["kiss_woman,_man,_dark_skin_tone", "👩🏿‍❤️‍💋‍👨🏿"],
    ["kiss_man,_man", "👨‍❤️‍💋‍👨"],
    ["kiss_man,_man,_light_skin_tone", "👨🏻‍❤️‍💋‍👨🏻"],
    ["kiss_man,_man,_light_skin_tone,_medium-light_skin_tone", "👨🏻‍❤️‍💋‍👨🏼"],
    ["kiss_man,_man,_light_skin_tone,_medium_skin_tone", "👨🏻‍❤️‍💋‍👨🏽"],
    ["kiss_man,_man,_light_skin_tone,_medium-dark_skin_tone", "👨🏻‍❤️‍💋‍👨🏾"],
    ["kiss_man,_man,_light_skin_tone,_dark_skin_tone", "👨🏻‍❤️‍💋‍👨🏿"],
    ["kiss_man,_man,_medium-light_skin_tone,_light_skin_tone", "👨🏼‍❤️‍💋‍👨🏻"],
    ["kiss_man,_man,_medium-light_skin_tone", "👨🏼‍❤️‍💋‍👨🏼"],
    ["kiss_man,_man,_medium-light_skin_tone,_medium_skin_tone", "👨🏼‍❤️‍💋‍👨🏽"],
    ["kiss_man,_man,_medium-light_skin_tone,_medium-dark_skin_tone", "👨🏼‍❤️‍💋‍👨🏾"],
    ["kiss_man,_man,_medium-light_skin_tone,_dark_skin_tone", "👨🏼‍❤️‍💋‍👨🏿"],
    ["kiss_man,_man,_medium_skin_tone,_light_skin_tone", "👨🏽‍❤️‍💋‍👨🏻"],
    ["kiss_man,_man,_medium_skin_tone,_medium-light_skin_tone", "👨🏽‍❤️‍💋‍👨🏼"],
    ["kiss_man,_man,_medium_skin_tone", "👨🏽‍❤️‍💋‍👨🏽"],
    ["kiss_man,_man,_medium_skin_tone,_medium-dark_skin_tone", "👨🏽‍❤️‍💋‍👨🏾"],
    ["kiss_man,_man,_medium_skin_tone,_dark_skin_tone", "👨🏽‍❤️‍💋‍👨🏿"],
    ["kiss_man,_man,_medium-dark_skin_tone,_light_skin_tone", "👨🏾‍❤️‍💋‍👨🏻"],
    ["kiss_man,_man,_medium-dark_skin_tone,_medium-light_skin_tone", "👨🏾‍❤️‍💋‍👨🏼"],
    ["kiss_man,_man,_medium-dark_skin_tone,_medium_skin_tone", "👨🏾‍❤️‍💋‍👨🏽"],
    ["kiss_man,_man,_medium-dark_skin_tone", "👨🏾‍❤️‍💋‍👨🏾"],
    ["kiss_man,_man,_medium-dark_skin_tone,_dark_skin_tone", "👨🏾‍❤️‍💋‍👨🏿"],
    ["kiss_man,_man,_dark_skin_tone,_light_skin_tone", "👨🏿‍❤️‍💋‍👨🏻"],
    ["kiss_man,_man,_dark_skin_tone,_medium-light_skin_tone", "👨🏿‍❤️‍💋‍👨🏼"],
    ["kiss_man,_man,_dark_skin_tone,_medium_skin_tone", "👨🏿‍❤️‍💋‍👨🏽"],
    ["kiss_man,_man,_dark_skin_tone,_medium-dark_skin_tone", "👨🏿‍❤️‍💋‍👨🏾"],
    ["kiss_man,_man,_dark_skin_tone", "👨🏿‍❤️‍💋‍👨🏿"],
    ["kiss_woman,_woman", "👩‍❤️‍💋‍👩"],
    ["kiss_woman,_woman,_light_skin_tone", "👩🏻‍❤️‍💋‍👩🏻"],
    ["kiss_woman,_woman,_light_skin_tone,_medium-light_skin_tone", "👩🏻‍❤️‍💋‍👩🏼"],
    ["kiss_woman,_woman,_light_skin_tone,_medium_skin_tone", "👩🏻‍❤️‍💋‍👩🏽"],
    ["kiss_woman,_woman,_light_skin_tone,_medium-dark_skin_tone", "👩🏻‍❤️‍💋‍👩🏾"],
    ["kiss_woman,_woman,_light_skin_tone,_dark_skin_tone", "👩🏻‍❤️‍💋‍👩🏿"],
    ["kiss_woman,_woman,_medium-light_skin_tone,_light_skin_tone", "👩🏼‍❤️‍💋‍👩🏻"],
    ["kiss_woman,_woman,_medium-light_skin_tone", "👩🏼‍❤️‍💋‍👩🏼"],
    ["kiss_woman,_woman,_medium-light_skin_tone,_medium_skin_tone", "👩🏼‍❤️‍💋‍👩🏽"],
    ["kiss_woman,_woman,_medium-light_skin_tone,_medium-dark_skin_tone", "👩🏼‍❤️‍💋‍👩🏾"],
    ["kiss_woman,_woman,_medium-light_skin_tone,_dark_skin_tone", "👩🏼‍❤️‍💋‍👩🏿"],
    ["kiss_woman,_woman,_medium_skin_tone,_light_skin_tone", "👩🏽‍❤️‍💋‍👩🏻"],
    ["kiss_woman,_woman,_medium_skin_tone,_medium-light_skin_tone", "👩🏽‍❤️‍💋‍👩🏼"],
    ["kiss_woman,_woman,_medium_skin_tone", "👩🏽‍❤️‍💋‍👩🏽"],
    ["kiss_woman,_woman,_medium_skin_tone,_medium-dark_skin_tone", "👩🏽‍❤️‍💋‍👩🏾"],
    ["kiss_woman,_woman,_medium_skin_tone,_dark_skin_tone", "👩🏽‍❤️‍💋‍👩🏿"],
    ["kiss_woman,_woman,_medium-dark_skin_tone,_light_skin_tone", "👩🏾‍❤️‍💋‍👩🏻"],
    ["kiss_woman,_woman,_medium-dark_skin_tone,_medium-light_skin_tone", "👩🏾‍❤️‍💋‍👩🏼"],
    ["kiss_woman,_woman,_medium-dark_skin_tone,_medium_skin_tone", "👩🏾‍❤️‍💋‍👩🏽"],
    ["kiss_woman,_woman,_medium-dark_skin_tone", "👩🏾‍❤️‍💋‍👩🏾"],
    ["kiss_woman,_woman,_medium-dark_skin_tone,_dark_skin_tone", "👩🏾‍❤️‍💋‍👩🏿"],
    ["kiss_woman,_woman,_dark_skin_tone,_light_skin_tone", "👩🏿‍❤️‍💋‍👩🏻"],
    ["kiss_woman,_woman,_dark_skin_tone,_medium-light_skin_tone", "👩🏿‍❤️‍💋‍👩🏼"],
    ["kiss_woman,_woman,_dark_skin_tone,_medium_skin_tone", "👩🏿‍❤️‍💋‍👩🏽"],
    ["kiss_woman,_woman,_dark_skin_tone,_medium-dark_skin_tone", "👩🏿‍❤️‍💋‍👩🏾"],
    ["kiss_woman,_woman,_dark_skin_tone", "👩🏿‍❤️‍💋‍👩🏿"],
    ["couple_with_heart", "💑"],
    ["couple_with_heart_light_skin_tone", "💑🏻"],
    ["couple_with_heart_medium-light_skin_tone", "💑🏼"],
    ["couple_with_heart_medium_skin_tone", "💑🏽"],
    ["couple_with_heart_medium-dark_skin_tone", "💑🏾"],
    ["couple_with_heart_dark_skin_tone", "💑🏿"],
    ["couple_with_heart_person,_person,_light_skin_tone,_medium-light_skin_tone", "🧑🏻‍❤️‍🧑🏼"],
    ["couple_with_heart_person,_person,_light_skin_tone,_medium_skin_tone", "🧑🏻‍❤️‍🧑🏽"],
    ["couple_with_heart_person,_person,_light_skin_tone,_medium-dark_skin_tone", "🧑🏻‍❤️‍🧑🏾"],
    ["couple_with_heart_person,_person,_light_skin_tone,_dark_skin_tone", "🧑🏻‍❤️‍🧑🏿"],
    ["couple_with_heart_person,_person,_medium-light_skin_tone,_light_skin_tone", "🧑🏼‍❤️‍🧑🏻"],
    ["couple_with_heart_person,_person,_medium-light_skin_tone,_medium_skin_tone", "🧑🏼‍❤️‍🧑🏽"],
    ["couple_with_heart_person,_person,_medium-light_skin_tone,_medium-dark_skin_tone", "🧑🏼‍❤️‍🧑🏾"],
    ["couple_with_heart_person,_person,_medium-light_skin_tone,_dark_skin_tone", "🧑🏼‍❤️‍🧑🏿"],
    ["couple_with_heart_person,_person,_medium_skin_tone,_light_skin_tone", "🧑🏽‍❤️‍🧑🏻"],
    ["couple_with_heart_person,_person,_medium_skin_tone,_medium-light_skin_tone", "🧑🏽‍❤️‍🧑🏼"],
    ["couple_with_heart_person,_person,_medium_skin_tone,_medium-dark_skin_tone", "🧑🏽‍❤️‍🧑🏾"],
    ["couple_with_heart_person,_person,_medium_skin_tone,_dark_skin_tone", "🧑🏽‍❤️‍🧑🏿"],
    ["couple_with_heart_person,_person,_medium-dark_skin_tone,_light_skin_tone", "🧑🏾‍❤️‍🧑🏻"],
    ["couple_with_heart_person,_person,_medium-dark_skin_tone,_medium-light_skin_tone", "🧑🏾‍❤️‍🧑🏼"],
    ["couple_with_heart_person,_person,_medium-dark_skin_tone,_medium_skin_tone", "🧑🏾‍❤️‍🧑🏽"],
    ["couple_with_heart_person,_person,_medium-dark_skin_tone,_dark_skin_tone", "🧑🏾‍❤️‍🧑🏿"],
    ["couple_with_heart_person,_person,_dark_skin_tone,_light_skin_tone", "🧑🏿‍❤️‍🧑🏻"],
    ["couple_with_heart_person,_person,_dark_skin_tone,_medium-light_skin_tone", "🧑🏿‍❤️‍🧑🏼"],
    ["couple_with_heart_person,_person,_dark_skin_tone,_medium_skin_tone", "🧑🏿‍❤️‍🧑🏽"],
    ["couple_with_heart_person,_person,_dark_skin_tone,_medium-dark_skin_tone", "🧑🏿‍❤️‍🧑🏾"],
    ["couple_with_heart_woman,_man", "👩‍❤️‍👨"],
    ["couple_with_heart_woman,_man,_light_skin_tone", "👩🏻‍❤️‍👨🏻"],
    ["couple_with_heart_woman,_man,_light_skin_tone,_medium-light_skin_tone", "👩🏻‍❤️‍👨🏼"],
    ["couple_with_heart_woman,_man,_light_skin_tone,_medium_skin_tone", "👩🏻‍❤️‍👨🏽"],
    ["couple_with_heart_woman,_man,_light_skin_tone,_medium-dark_skin_tone", "👩🏻‍❤️‍👨🏾"],
    ["couple_with_heart_woman,_man,_light_skin_tone,_dark_skin_tone", "👩🏻‍❤️‍👨🏿"],
    ["couple_with_heart_woman,_man,_medium-light_skin_tone,_light_skin_tone", "👩🏼‍❤️‍👨🏻"],
    ["couple_with_heart_woman,_man,_medium-light_skin_tone", "👩🏼‍❤️‍👨🏼"],
    ["couple_with_heart_woman,_man,_medium-light_skin_tone,_medium_skin_tone", "👩🏼‍❤️‍👨🏽"],
    ["couple_with_heart_woman,_man,_medium-light_skin_tone,_medium-dark_skin_tone", "👩🏼‍❤️‍👨🏾"],
    ["couple_with_heart_woman,_man,_medium-light_skin_tone,_dark_skin_tone", "👩🏼‍❤️‍👨🏿"],
    ["couple_with_heart_woman,_man,_medium_skin_tone,_light_skin_tone", "👩🏽‍❤️‍👨🏻"],
    ["couple_with_heart_woman,_man,_medium_skin_tone,_medium-light_skin_tone", "👩🏽‍❤️‍👨🏼"],
    ["couple_with_heart_woman,_man,_medium_skin_tone", "👩🏽‍❤️‍👨🏽"],
    ["couple_with_heart_woman,_man,_medium_skin_tone,_medium-dark_skin_tone", "👩🏽‍❤️‍👨🏾"],
    ["couple_with_heart_woman,_man,_medium_skin_tone,_dark_skin_tone", "👩🏽‍❤️‍👨🏿"],
    ["couple_with_heart_woman,_man,_medium-dark_skin_tone,_light_skin_tone", "👩🏾‍❤️‍👨🏻"],
    ["couple_with_heart_woman,_man,_medium-dark_skin_tone,_medium-light_skin_tone", "👩🏾‍❤️‍👨🏼"],
    ["couple_with_heart_woman,_man,_medium-dark_skin_tone,_medium_skin_tone", "👩🏾‍❤️‍👨🏽"],
    ["couple_with_heart_woman,_man,_medium-dark_skin_tone", "👩🏾‍❤️‍👨🏾"],
    ["couple_with_heart_woman,_man,_medium-dark_skin_tone,_dark_skin_tone", "👩🏾‍❤️‍👨🏿"],
    ["couple_with_heart_woman,_man,_dark_skin_tone,_light_skin_tone", "👩🏿‍❤️‍👨🏻"],
    ["couple_with_heart_woman,_man,_dark_skin_tone,_medium-light_skin_tone", "👩🏿‍❤️‍👨🏼"],
    ["couple_with_heart_woman,_man,_dark_skin_tone,_medium_skin_tone", "👩🏿‍❤️‍👨🏽"],
    ["couple_with_heart_woman,_man,_dark_skin_tone,_medium-dark_skin_tone", "👩🏿‍❤️‍👨🏾"],
    ["couple_with_heart_woman,_man,_dark_skin_tone", "👩🏿‍❤️‍👨🏿"],
    ["couple_with_heart_man,_man", "👨‍❤️‍👨"],
    ["couple_with_heart_man,_man,_light_skin_tone", "👨🏻‍❤️‍👨🏻"],
    ["couple_with_heart_man,_man,_light_skin_tone,_medium-light_skin_tone", "👨🏻‍❤️‍👨🏼"],
    ["couple_with_heart_man,_man,_light_skin_tone,_medium_skin_tone", "👨🏻‍❤️‍👨🏽"],
    ["couple_with_heart_man,_man,_light_skin_tone,_medium-dark_skin_tone", "👨🏻‍❤️‍👨🏾"],
    ["couple_with_heart_man,_man,_light_skin_tone,_dark_skin_tone", "👨🏻‍❤️‍👨🏿"],
    ["couple_with_heart_man,_man,_medium-light_skin_tone,_light_skin_tone", "👨🏼‍❤️‍👨🏻"],
    ["couple_with_heart_man,_man,_medium-light_skin_tone", "👨🏼‍❤️‍👨🏼"],
    ["couple_with_heart_man,_man,_medium-light_skin_tone,_medium_skin_tone", "👨🏼‍❤️‍👨🏽"],
    ["couple_with_heart_man,_man,_medium-light_skin_tone,_medium-dark_skin_tone", "👨🏼‍❤️‍👨🏾"],
    ["couple_with_heart_man,_man,_medium-light_skin_tone,_dark_skin_tone", "👨🏼‍❤️‍👨🏿"],
    ["couple_with_heart_man,_man,_medium_skin_tone,_light_skin_tone", "👨🏽‍❤️‍👨🏻"],
    ["couple_with_heart_man,_man,_medium_skin_tone,_medium-light_skin_tone", "👨🏽‍❤️‍👨🏼"],
    ["couple_with_heart_man,_man,_medium_skin_tone", "👨🏽‍❤️‍👨🏽"],
    ["couple_with_heart_man,_man,_medium_skin_tone,_medium-dark_skin_tone", "👨🏽‍❤️‍👨🏾"],
    ["couple_with_heart_man,_man,_medium_skin_tone,_dark_skin_tone", "👨🏽‍❤️‍👨🏿"],
    ["couple_with_heart_man,_man,_medium-dark_skin_tone,_light_skin_tone", "👨🏾‍❤️‍👨🏻"],
    ["couple_with_heart_man,_man,_medium-dark_skin_tone,_medium-light_skin_tone", "👨🏾‍❤️‍👨🏼"],
    ["couple_with_heart_man,_man,_medium-dark_skin_tone,_medium_skin_tone", "👨🏾‍❤️‍👨🏽"],
    ["couple_with_heart_man,_man,_medium-dark_skin_tone", "👨🏾‍❤️‍👨🏾"],
    ["couple_with_heart_man,_man,_medium-dark_skin_tone,_dark_skin_tone", "👨🏾‍❤️‍👨🏿"],
    ["couple_with_heart_man,_man,_dark_skin_tone,_light_skin_tone", "👨🏿‍❤️‍👨🏻"],
    ["couple_with_heart_man,_man,_dark_skin_tone,_medium-light_skin_tone", "👨🏿‍❤️‍👨🏼"],
    ["couple_with_heart_man,_man,_dark_skin_tone,_medium_skin_tone", "👨🏿‍❤️‍👨🏽"],
    ["couple_with_heart_man,_man,_dark_skin_tone,_medium-dark_skin_tone", "👨🏿‍❤️‍👨🏾"],
    ["couple_with_heart_man,_man,_dark_skin_tone", "👨🏿‍❤️‍👨🏿"],
    ["couple_with_heart_woman,_woman", "👩‍❤️‍👩"],
    ["couple_with_heart_woman,_woman,_light_skin_tone", "👩🏻‍❤️‍👩🏻"],
    ["couple_with_heart_woman,_woman,_light_skin_tone,_medium-light_skin_tone", "👩🏻‍❤️‍👩🏼"],
    ["couple_with_heart_woman,_woman,_light_skin_tone,_medium_skin_tone", "👩🏻‍❤️‍👩🏽"],
    ["couple_with_heart_woman,_woman,_light_skin_tone,_medium-dark_skin_tone", "👩🏻‍❤️‍👩🏾"],
    ["couple_with_heart_woman,_woman,_light_skin_tone,_dark_skin_tone", "👩🏻‍❤️‍👩🏿"],
    ["couple_with_heart_woman,_woman,_medium-light_skin_tone,_light_skin_tone", "👩🏼‍❤️‍👩🏻"],
    ["couple_with_heart_woman,_woman,_medium-light_skin_tone", "👩🏼‍❤️‍👩🏼"],
    ["couple_with_heart_woman,_woman,_medium-light_skin_tone,_medium_skin_tone", "👩🏼‍❤️‍👩🏽"],
    ["couple_with_heart_woman,_woman,_medium-light_skin_tone,_medium-dark_skin_tone", "👩🏼‍❤️‍👩🏾"],
    ["couple_with_heart_woman,_woman,_medium-light_skin_tone,_dark_skin_tone", "👩🏼‍❤️‍👩🏿"],
    ["couple_with_heart_woman,_woman,_medium_skin_tone,_light_skin_tone", "👩🏽‍❤️‍👩🏻"],
    ["couple_with_heart_woman,_woman,_medium_skin_tone,_medium-light_skin_tone", "👩🏽‍❤️‍👩🏼"],
    ["couple_with_heart_woman,_woman,_medium_skin_tone", "👩🏽‍❤️‍👩🏽"],
    ["couple_with_heart_woman,_woman,_medium_skin_tone,_medium-dark_skin_tone", "👩🏽‍❤️‍👩🏾"],
    ["couple_with_heart_woman,_woman,_medium_skin_tone,_dark_skin_tone", "👩🏽‍❤️‍👩🏿"],
    ["couple_with_heart_woman,_woman,_medium-dark_skin_tone,_light_skin_tone", "👩🏾‍❤️‍👩🏻"],
    ["couple_with_heart_woman,_woman,_medium-dark_skin_tone,_medium-light_skin_tone", "👩🏾‍❤️‍👩🏼"],
    ["couple_with_heart_woman,_woman,_medium-dark_skin_tone,_medium_skin_tone", "👩🏾‍❤️‍👩🏽"],
    ["couple_with_heart_woman,_woman,_medium-dark_skin_tone", "👩🏾‍❤️‍👩🏾"],
    ["couple_with_heart_woman,_woman,_medium-dark_skin_tone,_dark_skin_tone", "👩🏾‍❤️‍👩🏿"],
    ["couple_with_heart_woman,_woman,_dark_skin_tone,_light_skin_tone", "👩🏿‍❤️‍👩🏻"],
    ["couple_with_heart_woman,_woman,_dark_skin_tone,_medium-light_skin_tone", "👩🏿‍❤️‍👩🏼"],
    ["couple_with_heart_woman,_woman,_dark_skin_tone,_medium_skin_tone", "👩🏿‍❤️‍👩🏽"],
    ["couple_with_heart_woman,_woman,_dark_skin_tone,_medium-dark_skin_tone", "👩🏿‍❤️‍👩🏾"],
    ["couple_with_heart_woman,_woman,_dark_skin_tone", "👩🏿‍❤️‍👩🏿"],
    ["family", "👪"],
    ["family_man,_woman,_boy", "👨‍👩‍👦"],
    ["family_man,_woman,_girl", "👨‍👩‍👧"],
    ["family_man,_woman,_girl,_boy", "👨‍👩‍👧‍👦"],
    ["family_man,_woman,_boy,_boy", "👨‍👩‍👦‍👦"],
    ["family_man,_woman,_girl,_girl", "👨‍👩‍👧‍👧"],
    ["family_man,_man,_boy", "👨‍👨‍👦"],
    ["family_man,_man,_girl", "👨‍👨‍👧"],
    ["family_man,_man,_girl,_boy", "👨‍👨‍👧‍👦"],
    ["family_man,_man,_boy,_boy", "👨‍👨‍👦‍👦"],
    ["family_man,_man,_girl,_girl", "👨‍👨‍👧‍👧"],
    ["family_woman,_woman,_boy", "👩‍👩‍👦"],
    ["family_woman,_woman,_girl", "👩‍👩‍👧"],
    ["family_woman,_woman,_girl,_boy", "👩‍👩‍👧‍👦"],
    ["family_woman,_woman,_boy,_boy", "👩‍👩‍👦‍👦"],
    ["family_woman,_woman,_girl,_girl", "👩‍👩‍👧‍👧"],
    ["family_man,_boy", "👨‍👦"],
    ["family_man,_boy,_boy", "👨‍👦‍👦"],
    ["family_man,_girl", "👨‍👧"],
    ["family_man,_girl,_boy", "👨‍👧‍👦"],
    ["family_man,_girl,_girl", "👨‍👧‍👧"],
    ["family_woman,_boy", "👩‍👦"],
    ["family_woman,_boy,_boy", "👩‍👦‍👦"],
    ["family_woman,_girl", "👩‍👧"],
    ["family_woman,_girl,_boy", "👩‍👧‍👦"],
    ["family_woman,_girl,_girl", "👩‍👧‍👧"],
    ["speaking_head", "🗣️"],
    ["bust_in_silhouette", "👤"],
    ["busts_in_silhouette", "👥"],
    ["people_hugging", "🫂"],
    ["footprints", "👣"],
    ["monkey_face", "🐵"],
    ["monkey", "🐒"],
    ["gorilla", "🦍"],
    ["orangutan", "🦧"],
    ["dog_face", "🐶"],
    ["dog", "🐕"],
    ["guide_dog", "🦮"],
    ["service_dog", "🐕‍🦺"],
    ["poodle", "🐩"],
    ["wolf", "🐺"],
    ["fox", "🦊"],
    ["raccoon", "🦝"],
    ["cat_face", "🐱"],
    ["cat", "🐈"],
    ["black_cat", "🐈‍⬛"],
    ["lion", "🦁"],
    ["tiger_face", "🐯"],
    ["tiger", "🐅"],
    ["leopard", "🐆"],
    ["horse_face", "🐴"],
    ["horse", "🐎"],
    ["unicorn", "🦄"],
    ["zebra", "🦓"],
    ["deer", "🦌"],
    ["bison", "🦬"],
    ["cow_face", "🐮"],
    ["ox", "🐂"],
    ["water_buffalo", "🐃"],
    ["cow", "🐄"],
    ["pig_face", "🐷"],
    ["pig", "🐖"],
    ["boar", "🐗"],
    ["pig_nose", "🐽"],
    ["ram", "🐏"],
    ["ewe", "🐑"],
    ["goat", "🐐"],
    ["camel", "🐪"],
    ["two-hump_camel", "🐫"],
    ["llama", "🦙"],
    ["giraffe", "🦒"],
    ["elephant", "🐘"],
    ["mammoth", "🦣"],
    ["rhinoceros", "🦏"],
    ["hippopotamus", "🦛"],
    ["mouse_face", "🐭"],
    ["mouse", "🐁"],
    ["rat", "🐀"],
    ["hamster", "🐹"],
    ["rabbit_face", "🐰"],
    ["rabbit", "🐇"],
    ["chipmunk", "🐿️"],
    ["beaver", "🦫"],
    ["hedgehog", "🦔"],
    ["bat", "🦇"],
    ["bear", "🐻"],
    ["polar_bear", "🐻‍❄️"],
    ["koala", "🐨"],
    ["panda", "🐼"],
    ["sloth", "🦥"],
    ["otter", "🦦"],
    ["skunk", "🦨"],
    ["kangaroo", "🦘"],
    ["badger", "🦡"],
    ["paw_prints", "🐾"],
    ["turkey", "🦃"],
    ["chicken", "🐔"],
    ["rooster", "🐓"],
    ["hatching_chick", "🐣"],
    ["baby_chick", "🐤"],
    ["front-facing_baby_chick", "🐥"],
    ["bird", "🐦"],
    ["penguin", "🐧"],
    ["dove", "🕊️"],
    ["eagle", "🦅"],
    ["duck", "🦆"],
    ["swan", "🦢"],
    ["owl", "🦉"],
    ["dodo", "🦤"],
    ["feather", "🪶"],
    ["flamingo", "🦩"],
    ["peacock", "🦚"],
    ["parrot", "🦜"],
    ["frog", "🐸"],
    ["crocodile", "🐊"],
    ["turtle", "🐢"],
    ["lizard", "🦎"],
    ["snake", "🐍"],
    ["dragon_face", "🐲"],
    ["dragon", "🐉"],
    ["sauropod", "🦕"],
    ["T-Rex", "🦖"],
    ["spouting_whale", "🐳"],
    ["whale", "🐋"],
    ["dolphin", "🐬"],
    ["seal", "🦭"],
    ["fish", "🐟"],
    ["tropical_fish", "🐠"],
    ["blowfish", "🐡"],
    ["shark", "🦈"],
    ["octopus", "🐙"],
    ["spiral_shell", "🐚"],
    ["snail", "🐌"],
    ["butterfly", "🦋"],
    ["bug", "🐛"],
    ["ant", "🐜"],
    ["honeybee", "🐝"],
    ["beetle", "🪲"],
    ["lady_beetle", "🐞"],
    ["cricket", "🦗"],
    ["cockroach", "🪳"],
    ["spider", "🕷️"],
    ["spider_web", "🕸️"],
    ["scorpion", "🦂"],
    ["mosquito", "🦟"],
    ["fly", "🪰"],
    ["worm", "🪱"],
    ["microbe", "🦠"],
    ["bouquet", "💐"],
    ["cherry_blossom", "🌸"],
    ["white_flower", "💮"],
    ["rosette", "🏵️"],
    ["rose", "🌹"],
    ["wilted_flower", "🥀"],
    ["hibiscus", "🌺"],
    ["sunflower", "🌻"],
    ["blossom", "🌼"],
    ["tulip", "🌷"],
    ["seedling", "🌱"],
    ["potted_plant", "🪴"],
    ["evergreen_tree", "🌲"],
    ["deciduous_tree", "🌳"],
    ["palm_tree", "🌴"],
    ["cactus", "🌵"],
    ["sheaf_of_rice", "🌾"],
    ["herb", "🌿"],
    ["shamrock", "☘️"],
    ["four_leaf_clover", "🍀"],
    ["maple_leaf", "🍁"],
    ["fallen_leaf", "🍂"],
    ["leaf_fluttering_in_wind", "🍃"],
    ["grapes", "🍇"],
    ["melon", "🍈"],
    ["watermelon", "🍉"],
    ["tangerine", "🍊"],
    ["lemon", "🍋"],
    ["banana", "🍌"],
    ["pineapple", "🍍"],
    ["mango", "🥭"],
    ["red_apple", "🍎"],
    ["green_apple", "🍏"],
    ["pear", "🍐"],
    ["peach", "🍑"],
    ["cherries", "🍒"],
    ["strawberry", "🍓"],
    ["blueberries", "🫐"],
    ["kiwi_fruit", "🥝"],
    ["tomato", "🍅"],
    ["olive", "🫒"],
    ["coconut", "🥥"],
    ["avocado", "🥑"],
    ["eggplant", "🍆"],
    ["potato", "🥔"],
    ["carrot", "🥕"],
    ["ear_of_corn", "🌽"],
    ["hot_pepper", "🌶️"],
    ["bell_pepper", "🫑"],
    ["cucumber", "🥒"],
    ["leafy_green", "🥬"],
    ["broccoli", "🥦"],
    ["garlic", "🧄"],
    ["onion", "🧅"],
    ["mushroom", "🍄"],
    ["peanuts", "🥜"],
    ["chestnut", "🌰"],
    ["bread", "🍞"],
    ["croissant", "🥐"],
    ["baguette_bread", "🥖"],
    ["flatbread", "🫓"],
    ["pretzel", "🥨"],
    ["bagel", "🥯"],
    ["pancakes", "🥞"],
    ["waffle", "🧇"],
    ["cheese_wedge", "🧀"],
    ["meat_on_bone", "🍖"],
    ["poultry_leg", "🍗"],
    ["cut_of_meat", "🥩"],
    ["bacon", "🥓"],
    ["hamburger", "🍔"],
    ["french_fries", "🍟"],
    ["pizza", "🍕"],
    ["hot_dog", "🌭"],
    ["sandwich", "🥪"],
    ["taco", "🌮"],
    ["burrito", "🌯"],
    ["tamale", "🫔"],
    ["stuffed_flatbread", "🥙"],
    ["falafel", "🧆"],
    ["egg", "🥚"],
    ["cooking", "🍳"],
    ["shallow_pan_of_food", "🥘"],
    ["pot_of_food", "🍲"],
    ["fondue", "🫕"],
    ["bowl_with_spoon", "🥣"],
    ["green_salad", "🥗"],
    ["popcorn", "🍿"],
    ["butter", "🧈"],
    ["salt", "🧂"],
    ["canned_food", "🥫"],
    ["bento_box", "🍱"],
    ["rice_cracker", "🍘"],
    ["rice_ball", "🍙"],
    ["cooked_rice", "🍚"],
    ["curry_rice", "🍛"],
    ["steaming_bowl", "🍜"],
    ["spaghetti", "🍝"],
    ["roasted_sweet_potato", "🍠"],
    ["oden", "🍢"],
    ["sushi", "🍣"],
    ["fried_shrimp", "🍤"],
    ["fish_cake_with_swirl", "🍥"],
    ["moon_cake", "🥮"],
    ["dango", "🍡"],
    ["dumpling", "🥟"],
    ["fortune_cookie", "🥠"],
    ["takeout_box", "🥡"],
    ["crab", "🦀"],
    ["lobster", "🦞"],
    ["shrimp", "🦐"],
    ["squid", "🦑"],
    ["oyster", "🦪"],
    ["soft_ice_cream", "🍦"],
    ["shaved_ice", "🍧"],
    ["ice_cream", "🍨"],
    ["doughnut", "🍩"],
    ["cookie", "🍪"],
    ["birthday_cake", "🎂"],
    ["shortcake", "🍰"],
    ["cupcake", "🧁"],
    ["pie", "🥧"],
    ["chocolate_bar", "🍫"],
    ["candy", "🍬"],
    ["lollipop", "🍭"],
    ["custard", "🍮"],
    ["honey_pot", "🍯"],
    ["baby_bottle", "🍼"],
    ["glass_of_milk", "🥛"],
    ["hot_beverage", "☕"],
    ["teapot", "🫖"],
    ["teacup_without_handle", "🍵"],
    ["sake", "🍶"],
    ["bottle_with_popping_cork", "🍾"],
    ["wine_glass", "🍷"],
    ["cocktail_glass", "🍸"],
    ["tropical_drink", "🍹"],
    ["beer_mug", "🍺"],
    ["clinking_beer_mugs", "🍻"],
    ["clinking_glasses", "🥂"],
    ["tumbler_glass", "🥃"],
    ["cup_with_straw", "🥤"],
    ["bubble_tea", "🧋"],
    ["beverage_box", "🧃"],
    ["mate", "🧉"],
    ["ice", "🧊"],
    ["chopsticks", "🥢"],
    ["fork_and_knife_with_plate", "🍽️"],
    ["fork_and_knife", "🍴"],
    ["spoon", "🥄"],
    ["kitchen_knife", "🔪"],
    ["amphora", "🏺"],
    ["globe_showing_Europe-Africa", "🌍"],
    ["globe_showing_Americas", "🌎"],
    ["globe_showing_Asia-Australia", "🌏"],
    ["globe_with_meridians", "🌐"],
    ["world_map", "🗺️"],
    ["map_of_Japan", "🗾"],
    ["compass", "🧭"],
    ["snow-capped_mountain", "🏔️"],
    ["mountain", "⛰️"],
    ["volcano", "🌋"],
    ["mount_fuji", "🗻"],
    ["camping", "🏕️"],
    ["beach_with_umbrella", "🏖️"],
    ["desert", "🏜️"],
    ["desert_island", "🏝️"],
    ["national_park", "🏞️"],
    ["stadium", "🏟️"],
    ["classical_building", "🏛️"],
    ["building_construction", "🏗️"],
    ["brick", "🧱"],
    ["rock", "🪨"],
    ["wood", "🪵"],
    ["hut", "🛖"],
    ["houses", "🏘️"],
    ["derelict_house", "🏚️"],
    ["house", "🏠"],
    ["house_with_garden", "🏡"],
    ["office_building", "🏢"],
    ["Japanese_post_office", "🏣"],
    ["post_office", "🏤"],
    ["hospital", "🏥"],
    ["bank", "🏦"],
    ["hotel", "🏨"],
    ["love_hotel", "🏩"],
    ["convenience_store", "🏪"],
    ["school", "🏫"],
    ["department_store", "🏬"],
    ["factory", "🏭"],
    ["Japanese_castle", "🏯"],
    ["castle", "🏰"],
    ["wedding", "💒"],
    ["Tokyo_tower", "🗼"],
    ["Statue_of_Liberty", "🗽"],
    ["church", "⛪"],
    ["mosque", "🕌"],
    ["hindu_temple", "🛕"],
    ["synagogue", "🕍"],
    ["shinto_shrine", "⛩️"],
    ["kaaba", "🕋"],
    ["fountain", "⛲"],
    ["tent", "⛺"],
    ["foggy", "🌁"],
    ["night_with_stars", "🌃"],
    ["cityscape", "🏙️"],
    ["sunrise_over_mountains", "🌄"],
    ["sunrise", "🌅"],
    ["cityscape_at_dusk", "🌆"],
    ["sunset", "🌇"],
    ["bridge_at_night", "🌉"],
    ["hot_springs", "♨️"],
    ["carousel_horse", "🎠"],
    ["ferris_wheel", "🎡"],
    ["roller_coaster", "🎢"],
    ["barber_pole", "💈"],
    ["circus_tent", "🎪"],
    ["locomotive", "🚂"],
    ["railway_car", "🚃"],
    ["high-speed_train", "🚄"],
    ["bullet_train", "🚅"],
    ["train", "🚆"],
    ["metro", "🚇"],
    ["light_rail", "🚈"],
    ["station", "🚉"],
    ["tram", "🚊"],
    ["monorail", "🚝"],
    ["mountain_railway", "🚞"],
    ["tram_car", "🚋"],
    ["bus", "🚌"],
    ["oncoming_bus", "🚍"],
    ["trolleybus", "🚎"],
    ["minibus", "🚐"],
    ["ambulance", "🚑"],
    ["fire_engine", "🚒"],
    ["police_car", "🚓"],
    ["oncoming_police_car", "🚔"],
    ["taxi", "🚕"],
    ["oncoming_taxi", "🚖"],
    ["automobile", "🚗"],
    ["oncoming_automobile", "🚘"],
    ["sport_utility_vehicle", "🚙"],
    ["pickup_truck", "🛻"],
    ["delivery_truck", "🚚"],
    ["articulated_lorry", "🚛"],
    ["tractor", "🚜"],
    ["racing_car", "🏎️"],
    ["motorcycle", "🏍️"],
    ["motor_scooter", "🛵"],
    ["manual_wheelchair", "🦽"],
    ["motorized_wheelchair", "🦼"],
    ["auto_rickshaw", "🛺"],
    ["bicycle", "🚲"],
    ["kick_scooter", "🛴"],
    ["skateboard", "🛹"],
    ["roller_skate", "🛼"],
    ["bus_stop", "🚏"],
    ["motorway", "🛣️"],
    ["railway_track", "🛤️"],
    ["oil_drum", "🛢️"],
    ["fuel_pump", "⛽"],
    ["police_car_light", "🚨"],
    ["horizontal_traffic_light", "🚥"],
    ["vertical_traffic_light", "🚦"],
    ["stop_sign", "🛑"],
    ["construction", "🚧"],
    ["anchor", "⚓"],
    ["sailboat", "⛵"],
    ["canoe", "🛶"],
    ["speedboat", "🚤"],
    ["passenger_ship", "🛳️"],
    ["ferry", "⛴️"],
    ["motor_boat", "🛥️"],
    ["ship", "🚢"],
    ["airplane", "✈️"],
    ["small_airplane", "🛩️"],
    ["airplane_departure", "🛫"],
    ["airplane_arrival", "🛬"],
    ["parachute", "🪂"],
    ["seat", "💺"],
    ["helicopter", "🚁"],
    ["suspension_railway", "🚟"],
    ["mountain_cableway", "🚠"],
    ["aerial_tramway", "🚡"],
    ["satellite", "🛰️"],
    ["rocket", "🚀"],
    ["flying_saucer", "🛸"],
    ["bellhop_bell", "🛎️"],
    ["luggage", "🧳"],
    ["hourglass_done", "⌛"],
    ["hourglass_not_done", "⏳"],
    ["watch", "⌚"],
    ["alarm_clock", "⏰"],
    ["stopwatch", "⏱️"],
    ["timer_clock", "⏲️"],
    ["mantelpiece_clock", "🕰️"],
    ["twelve_o’clock", "🕛"],
    ["twelve-thirty", "🕧"],
    ["one_o’clock", "🕐"],
    ["one-thirty", "🕜"],
    ["two_o’clock", "🕑"],
    ["two-thirty", "🕝"],
    ["three_o’clock", "🕒"],
    ["three-thirty", "🕞"],
    ["four_o’clock", "🕓"],
    ["four-thirty", "🕟"],
    ["five_o’clock", "🕔"],
    ["five-thirty", "🕠"],
    ["six_o’clock", "🕕"],
    ["six-thirty", "🕡"],
    ["seven_o’clock", "🕖"],
    ["seven-thirty", "🕢"],
    ["eight_o’clock", "🕗"],
    ["eight-thirty", "🕣"],
    ["nine_o’clock", "🕘"],
    ["nine-thirty", "🕤"],
    ["ten_o’clock", "🕙"],
    ["ten-thirty", "🕥"],
    ["eleven_o’clock", "🕚"],
    ["eleven-thirty", "🕦"],
    ["new_moon", "🌑"],
    ["waxing_crescent_moon", "🌒"],
    ["first_quarter_moon", "🌓"],
    ["waxing_gibbous_moon", "🌔"],
    ["full_moon", "🌕"],
    ["waning_gibbous_moon", "🌖"],
    ["last_quarter_moon", "🌗"],
    ["waning_crescent_moon", "🌘"],
    ["crescent_moon", "🌙"],
    ["new_moon_face", "🌚"],
    ["first_quarter_moon_face", "🌛"],
    ["last_quarter_moon_face", "🌜"],
    ["thermometer", "🌡️"],
    ["sun", "☀️"],
    ["full_moon_face", "🌝"],
    ["sun_with_face", "🌞"],
    ["ringed_planet", "🪐"],
    ["star", "⭐"],
    ["glowing_star", "🌟"],
    ["shooting_star", "🌠"],
    ["milky_way", "🌌"],
    ["cloud", "☁️"],
    ["sun_behind_cloud", "⛅"],
    ["cloud_with_lightning_and_rain", "⛈️"],
    ["sun_behind_small_cloud", "🌤️"],
    ["sun_behind_large_cloud", "🌥️"],
    ["sun_behind_rain_cloud", "🌦️"],
    ["cloud_with_rain", "🌧️"],
    ["cloud_with_snow", "🌨️"],
    ["cloud_with_lightning", "🌩️"],
    ["tornado", "🌪️"],
    ["fog", "🌫️"],
    ["wind_face", "🌬️"],
    ["cyclone", "🌀"],
    ["rainbow", "🌈"],
    ["closed_umbrella", "🌂"],
    ["umbrella", "☂️"],
    ["umbrella_with_rain_drops", "☔"],
    ["umbrella_on_ground", "⛱️"],
    ["high_voltage", "⚡"],
    ["snowflake", "❄️"],
    ["snowman", "☃️"],
    ["snowman_without_snow", "⛄"],
    ["comet", "☄️"],
    ["fire", "🔥"],
    ["droplet", "💧"],
    ["water_wave", "🌊"],
    ["jack-o-lantern", "🎃"],
    ["Christmas_tree", "🎄"],
    ["fireworks", "🎆"],
    ["sparkler", "🎇"],
    ["firecracker", "🧨"],
    ["sparkles", "✨"],
    ["balloon", "🎈"],
    ["party_popper", "🎉"],
    ["confetti_ball", "🎊"],
    ["tanabata_tree", "🎋"],
    ["pine_decoration", "🎍"],
    ["Japanese_dolls", "🎎"],
    ["carp_streamer", "🎏"],
    ["wind_chime", "🎐"],
    ["moon_viewing_ceremony", "🎑"],
    ["red_envelope", "🧧"],
    ["ribbon", "🎀"],
    ["wrapped_gift", "🎁"],
    ["reminder_ribbon", "🎗️"],
    ["admission_tickets", "🎟️"],
    ["ticket", "🎫"],
    ["military_medal", "🎖️"],
    ["trophy", "🏆"],
    ["sports_medal", "🏅"],
    ["1st_place_medal", "🥇"],
    ["2nd_place_medal", "🥈"],
    ["3rd_place_medal", "🥉"],
    ["soccer_ball", "⚽"],
    ["baseball", "⚾"],
    ["softball", "🥎"],
    ["basketball", "🏀"],
    ["volleyball", "🏐"],
    ["american_football", "🏈"],
    ["rugby_football", "🏉"],
    ["tennis", "🎾"],
    ["flying_disc", "🥏"],
    ["bowling", "🎳"],
    ["cricket_game", "🏏"],
    ["field_hockey", "🏑"],
    ["ice_hockey", "🏒"],
    ["lacrosse", "🥍"],
    ["ping_pong", "🏓"],
    ["badminton", "🏸"],
    ["boxing_glove", "🥊"],
    ["martial_arts_uniform", "🥋"],
    ["goal_net", "🥅"],
    ["flag_in_hole", "⛳"],
    ["ice_skate", "⛸️"],
    ["fishing_pole", "🎣"],
    ["diving_mask", "🤿"],
    ["running_shirt", "🎽"],
    ["skis", "🎿"],
    ["sled", "🛷"],
    ["curling_stone", "🥌"],
    ["bullseye", "🎯"],
    ["yo-yo", "🪀"],
    ["kite", "🪁"],
    ["pool_8_ball", "🎱"],
    ["crystal_ball", "🔮"],
    ["magic_wand", "🪄"],
    ["nazar_amulet", "🧿"],
    ["video_game", "🎮"],
    ["joystick", "🕹️"],
    ["slot_machine", "🎰"],
    ["game_die", "🎲"],
    ["puzzle_piece", "🧩"],
    ["teddy_bear", "🧸"],
    ["piñata", "🪅"],
    ["nesting_dolls", "🪆"],
    ["spade_suit", "♠️"],
    ["heart_suit", "♥️"],
    ["diamond_suit", "♦️"],
    ["club_suit", "♣️"],
    ["chess_pawn", "♟️"],
    ["joker", "🃏"],
    ["mahjong_red_dragon", "🀄"],
    ["flower_playing_cards", "🎴"],
    ["performing_arts", "🎭"],
    ["framed_picture", "🖼️"],
    ["artist_palette", "🎨"],
    ["thread", "🧵"],
    ["sewing_needle", "🪡"],
    ["yarn", "🧶"],
    ["knot", "🪢"],
    ["glasses", "👓"],
    ["sunglasses", "🕶️"],
    ["goggles", "🥽"],
    ["lab_coat", "🥼"],
    ["safety_vest", "🦺"],
    ["necktie", "👔"],
    ["t-shirt", "👕"],
    ["jeans", "👖"],
    ["scarf", "🧣"],
    ["gloves", "🧤"],
    ["coat", "🧥"],
    ["socks", "🧦"],
    ["dress", "👗"],
    ["kimono", "👘"],
    ["sari", "🥻"],
    ["one-piece_swimsuit", "🩱"],
    ["briefs", "🩲"],
    ["shorts", "🩳"],
    ["bikini", "👙"],
    ["woman’s_clothes", "👚"],
    ["purse", "👛"],
    ["handbag", "👜"],
    ["clutch_bag", "👝"],
    ["shopping_bags", "🛍️"],
    ["backpack", "🎒"],
    ["thong_sandal", "🩴"],
    ["man’s_shoe", "👞"],
    ["running_shoe", "👟"],
    ["hiking_boot", "🥾"],
    ["flat_shoe", "🥿"],
    ["high-heeled_shoe", "👠"],
    ["woman’s_sandal", "👡"],
    ["ballet_shoes", "🩰"],
    ["woman’s_boot", "👢"],
    ["crown", "👑"],
    ["woman’s_hat", "👒"],
    ["top_hat", "🎩"],
    ["graduation_cap", "🎓"],
    ["billed_cap", "🧢"],
    ["military_helmet", "🪖"],
    ["rescue_worker’s_helmet", "⛑️"],
    ["prayer_beads", "📿"],
    ["lipstick", "💄"],
    ["ring", "💍"],
    ["gem_stone", "💎"],
    ["muted_speaker", "🔇"],
    ["speaker_low_volume", "🔈"],
    ["speaker_medium_volume", "🔉"],
    ["speaker_high_volume", "🔊"],
    ["loudspeaker", "📢"],
    ["megaphone", "📣"],
    ["postal_horn", "📯"],
    ["bell", "🔔"],
    ["bell_with_slash", "🔕"],
    ["musical_score", "🎼"],
    ["musical_note", "🎵"],
    ["musical_notes", "🎶"],
    ["studio_microphone", "🎙️"],
    ["level_slider", "🎚️"],
    ["control_knobs", "🎛️"],
    ["microphone", "🎤"],
    ["headphone", "🎧"],
    ["radio", "📻"],
    ["saxophone", "🎷"],
    ["accordion", "🪗"],
    ["guitar", "🎸"],
    ["musical_keyboard", "🎹"],
    ["trumpet", "🎺"],
    ["violin", "🎻"],
    ["banjo", "🪕"],
    ["drum", "🥁"],
    ["long_drum", "🪘"],
    ["mobile_phone", "📱"],
    ["mobile_phone_with_arrow", "📲"],
    ["telephone", "☎️"],
    ["telephone_receiver", "📞"],
    ["pager", "📟"],
    ["fax_machine", "📠"],
    ["battery", "🔋"],
    ["electric_plug", "🔌"],
    ["laptop", "💻"],
    ["desktop_computer", "🖥️"],
    ["printer", "🖨️"],
    ["keyboard", "⌨️"],
    ["computer_mouse", "🖱️"],
    ["trackball", "🖲️"],
    ["computer_disk", "💽"],
    ["floppy_disk", "💾"],
    ["optical_disk", "💿"],
    ["dvd", "📀"],
    ["abacus", "🧮"],
    ["movie_camera", "🎥"],
    ["film_frames", "🎞️"],
    ["film_projector", "📽️"],
    ["clapper_board", "🎬"],
    ["television", "📺"],
    ["camera", "📷"],
    ["camera_with_flash", "📸"],
    ["video_camera", "📹"],
    ["videocassette", "📼"],
    ["magnifying_glass_tilted_left", "🔍"],
    ["magnifying_glass_tilted_right", "🔎"],
    ["candle", "🕯️"],
    ["light_bulb", "💡"],
    ["flashlight", "🔦"],
    ["red_paper_lantern", "🏮"],
    ["diya_lamp", "🪔"],
    ["notebook_with_decorative_cover", "📔"],
    ["closed_book", "📕"],
    ["open_book", "📖"],
    ["green_book", "📗"],
    ["blue_book", "📘"],
    ["orange_book", "📙"],
    ["books", "📚"],
    ["notebook", "📓"],
    ["ledger", "📒"],
    ["page_with_curl", "📃"],
    ["scroll", "📜"],
    ["page_facing_up", "📄"],
    ["newspaper", "📰"],
    ["rolled-up_newspaper", "🗞️"],
    ["bookmark_tabs", "📑"],
    ["bookmark", "🔖"],
    ["label", "🏷️"],
    ["money_bag", "💰"],
    ["coin", "🪙"],
    ["yen_banknote", "💴"],
    ["dollar_banknote", "💵"],
    ["euro_banknote", "💶"],
    ["pound_banknote", "💷"],
    ["money_with_wings", "💸"],
    ["credit_card", "💳"],
    ["receipt", "🧾"],
    ["chart_increasing_with_yen", "💹"],
    ["envelope", "✉️"],
    ["e-mail", "📧"],
    ["incoming_envelope", "📨"],
    ["envelope_with_arrow", "📩"],
    ["outbox_tray", "📤"],
    ["inbox_tray", "📥"],
    ["package", "📦"],
    ["closed_mailbox_with_raised_flag", "📫"],
    ["closed_mailbox_with_lowered_flag", "📪"],
    ["open_mailbox_with_raised_flag", "📬"],
    ["open_mailbox_with_lowered_flag", "📭"],
    ["postbox", "📮"],
    ["ballot_box_with_ballot", "🗳️"],
    ["pencil", "✏️"],
    ["black_nib", "✒️"],
    ["fountain_pen", "🖋️"],
    ["pen", "🖊️"],
    ["paintbrush", "🖌️"],
    ["crayon", "🖍️"],
    ["memo", "📝"],
    ["briefcase", "💼"],
    ["file_folder", "📁"],
    ["open_file_folder", "📂"],
    ["card_index_dividers", "🗂️"],
    ["calendar", "📅"],
    ["tear-off_calendar", "📆"],
    ["spiral_notepad", "🗒️"],
    ["spiral_calendar", "🗓️"],
    ["card_index", "📇"],
    ["chart_increasing", "📈"],
    ["chart_decreasing", "📉"],
    ["bar_chart", "📊"],
    ["clipboard", "📋"],
    ["pushpin", "📌"],
    ["round_pushpin", "📍"],
    ["paperclip", "📎"],
    ["linked_paperclips", "🖇️"],
    ["straight_ruler", "📏"],
    ["triangular_ruler", "📐"],
    ["scissors", "✂️"],
    ["card_file_box", "🗃️"],
    ["file_cabinet", "🗄️"],
    ["wastebasket", "🗑️"],
    ["locked", "🔒"],
    ["unlocked", "🔓"],
    ["locked_with_pen", "🔏"],
    ["locked_with_key", "🔐"],
    ["key", "🔑"],
    ["old_key", "🗝️"],
    ["hammer", "🔨"],
    ["axe", "🪓"],
    ["pick", "⛏️"],
    ["hammer_and_pick", "⚒️"],
    ["hammer_and_wrench", "🛠️"],
    ["dagger", "🗡️"],
    ["crossed_swords", "⚔️"],
    ["water_pistol", "🔫"],
    ["boomerang", "🪃"],
    ["bow_and_arrow", "🏹"],
    ["shield", "🛡️"],
    ["carpentry_saw", "🪚"],
    ["wrench", "🔧"],
    ["screwdriver", "🪛"],
    ["nut_and_bolt", "🔩"],
    ["gear", "⚙️"],
    ["clamp", "🗜️"],
    ["balance_scale", "⚖️"],
    ["white_cane", "🦯"],
    ["link", "🔗"],
    ["chains", "⛓️"],
    ["hook", "🪝"],
    ["toolbox", "🧰"],
    ["magnet", "🧲"],
    ["ladder", "🪜"],
    ["alembic", "⚗️"],
    ["test_tube", "🧪"],
    ["petri_dish", "🧫"],
    ["dna", "🧬"],
    ["microscope", "🔬"],
    ["telescope", "🔭"],
    ["satellite_antenna", "📡"],
    ["syringe", "💉"],
    ["drop_of_blood", "🩸"],
    ["pill", "💊"],
    ["adhesive_bandage", "🩹"],
    ["stethoscope", "🩺"],
    ["door", "🚪"],
    ["elevator", "🛗"],
    ["mirror", "🪞"],
    ["window", "🪟"],
    ["bed", "🛏️"],
    ["couch_and_lamp", "🛋️"],
    ["chair", "🪑"],
    ["toilet", "🚽"],
    ["plunger", "🪠"],
    ["shower", "🚿"],
    ["bathtub", "🛁"],
    ["mouse_trap", "🪤"],
    ["razor", "🪒"],
    ["lotion_bottle", "🧴"],
    ["safety_pin", "🧷"],
    ["broom", "🧹"],
    ["basket", "🧺"],
    ["roll_of_paper", "🧻"],
    ["bucket", "🪣"],
    ["soap", "🧼"],
    ["toothbrush", "🪥"],
    ["sponge", "🧽"],
    ["fire_extinguisher", "🧯"],
    ["shopping_cart", "🛒"],
    ["cigarette", "🚬"],
    ["coffin", "⚰️"],
    ["headstone", "🪦"],
    ["funeral_urn", "⚱️"],
    ["moai", "🗿"],
    ["placard", "🪧"],
    ["ATM_sign", "🏧"],
    ["litter_in_bin_sign", "🚮"],
    ["potable_water", "🚰"],
    ["wheelchair_symbol", "♿"],
    ["men’s_room", "🚹"],
    ["women’s_room", "🚺"],
    ["restroom", "🚻"],
    ["baby_symbol", "🚼"],
    ["water_closet", "🚾"],
    ["passport_control", "🛂"],
    ["customs", "🛃"],
    ["baggage_claim", "🛄"],
    ["left_luggage", "🛅"],
    ["warning", "⚠️"],
    ["children_crossing", "🚸"],
    ["no_entry", "⛔"],
    ["prohibited", "🚫"],
    ["no_bicycles", "🚳"],
    ["no_smoking", "🚭"],
    ["no_littering", "🚯"],
    ["non-potable_water", "🚱"],
    ["no_pedestrians", "🚷"],
    ["no_mobile_phones", "📵"],
    ["no_one_under_eighteen", "🔞"],
    ["radioactive", "☢️"],
    ["biohazard", "☣️"],
    ["up_arrow", "⬆️"],
    ["up-right_arrow", "↗️"],
    ["right_arrow", "➡️"],
    ["down-right_arrow", "↘️"],
    ["down_arrow", "⬇️"],
    ["down-left_arrow", "↙️"],
    ["left_arrow", "⬅️"],
    ["up-left_arrow", "↖️"],
    ["up-down_arrow", "↕️"],
    ["left-right_arrow", "↔️"],
    ["right_arrow_curving_left", "↩️"],
    ["left_arrow_curving_right", "↪️"],
    ["right_arrow_curving_up", "⤴️"],
    ["right_arrow_curving_down", "⤵️"],
    ["clockwise_vertical_arrows", "🔃"],
    ["counterclockwise_arrows_button", "🔄"],
    ["BACK_arrow", "🔙"],
    ["END_arrow", "🔚"],
    ["ON!_arrow", "🔛"],
    ["SOON_arrow", "🔜"],
    ["TOP_arrow", "🔝"],
    ["place_of_worship", "🛐"],
    ["atom_symbol", "⚛️"],
    ["om", "🕉️"],
    ["star_of_David", "✡️"],
    ["wheel_of_dharma", "☸️"],
    ["yin_yang", "☯️"],
    ["latin_cross", "✝️"],
    ["orthodox_cross", "☦️"],
    ["star_and_crescent", "☪️"],
    ["peace_symbol", "☮️"],
    ["menorah", "🕎"],
    ["dotted_six-pointed_star", "🔯"],
    ["Aries", "♈"],
    ["Taurus", "♉"],
    ["Gemini", "♊"],
    ["Cancer", "♋"],
    ["Leo", "♌"],
    ["Virgo", "♍"],
    ["Libra", "♎"],
    ["Scorpio", "♏"],
    ["Sagittarius", "♐"],
    ["Capricorn", "♑"],
    ["Aquarius", "♒"],
    ["Pisces", "♓"],
    ["Ophiuchus", "⛎"],
    ["shuffle_tracks_button", "🔀"],
    ["repeat_button", "🔁"],
    ["repeat_single_button", "🔂"],
    ["play_button", "▶️"],
    ["fast-forward_button", "⏩"],
    ["next_track_button", "⏭️"],
    ["play_or_pause_button", "⏯️"],
    ["reverse_button", "◀️"],
    ["fast_reverse_button", "⏪"],
    ["last_track_button", "⏮️"],
    ["upwards_button", "🔼"],
    ["fast_up_button", "⏫"],
    ["downwards_button", "🔽"],
    ["fast_down_button", "⏬"],
    ["pause_button", "⏸️"],
    ["stop_button", "⏹️"],
    ["record_button", "⏺️"],
    ["eject_button", "⏏️"],
    ["cinema", "🎦"],
    ["dim_button", "🔅"],
    ["bright_button", "🔆"],
    ["antenna_bars", "📶"],
    ["vibration_mode", "📳"],
    ["mobile_phone_off", "📴"],
    ["female_sign", "♀️"],
    ["male_sign", "♂️"],
    ["transgender_symbol", "⚧️"],
    ["multiply", "✖️"],
    ["plus", "➕"],
    ["minus", "➖"],
    ["divide", "➗"],
    ["infinity", "♾️"],
    ["double_exclamation_mark", "‼️"],
    ["exclamation_question_mark", "⁉️"],
    ["red_question_mark", "❓"],
    ["white_question_mark", "❔"],
    ["white_exclamation_mark", "❕"],
    ["red_exclamation_mark", "❗"],
    ["wavy_dash", "〰️"],
    ["currency_exchange", "💱"],
    ["heavy_dollar_sign", "💲"],
    ["medical_symbol", "⚕️"],
    ["recycling_symbol", "♻️"],
    ["fleur-de-lis", "⚜️"],
    ["trident_emblem", "🔱"],
    ["name_badge", "📛"],
    ["Japanese_symbol_for_beginner", "🔰"],
    ["hollow_red_circle", "⭕"],
    ["check_mark_button", "✅"],
    ["check_box_with_check", "☑️"],
    ["check_mark", "✔️"],
    ["cross_mark", "❌"],
    ["cross_mark_button", "❎"],
    ["curly_loop", "➰"],
    ["double_curly_loop", "➿"],
    ["part_alternation_mark", "〽️"],
    ["eight-spoked_asterisk", "✳️"],
    ["eight-pointed_star", "✴️"],
    ["sparkle", "❇️"],
    ["copyright", "©️"],
    ["registered", "®️"],
    ["trade_mark", "™️"],
    ["keycap_#", "#️⃣"],
    ["keycap_*", "*️⃣"],
    ["keycap_0", "0️⃣"],
    ["keycap_1", "1️⃣"],
    ["keycap_2", "2️⃣"],
    ["keycap_3", "3️⃣"],
    ["keycap_4", "4️⃣"],
    ["keycap_5", "5️⃣"],
    ["keycap_6", "6️⃣"],
    ["keycap_7", "7️⃣"],
    ["keycap_8", "8️⃣"],
    ["keycap_9", "9️⃣"],
    ["keycap_10", "🔟"],
    ["input_latin_uppercase", "🔠"],
    ["input_latin_lowercase", "🔡"],
    ["input_numbers", "🔢"],
    ["input_symbols", "🔣"],
    ["input_latin_letters", "🔤"],
    ["A_button_(blood_type)", "🅰️"],
    ["AB_button_(blood_type)", "🆎"],
    ["B_button_(blood_type)", "🅱️"],
    ["CL_button", "🆑"],
    ["COOL_button", "🆒"],
    ["FREE_button", "🆓"],
    ["information", "ℹ️"],
    ["ID_button", "🆔"],
    ["circled_M", "Ⓜ️"],
    ["NEW_button", "🆕"],
    ["NG_button", "🆖"],
    ["O_button_(blood_type)", "🅾️"],
    ["OK_button", "🆗"],
    ["P_button", "🅿️"],
    ["SOS_button", "🆘"],
    ["UP!_button", "🆙"],
    ["VS_button", "🆚"],
    ["Japanese_“here”_button", "🈁"],
    ["Japanese_“service_charge”_button", "🈂️"],
    ["Japanese_“monthly_amount”_button", "🈷️"],
    ["Japanese_“not_free_of_charge”_button", "🈶"],
    ["Japanese_“reserved”_button", "🈯"],
    ["Japanese_“bargain”_button", "🉐"],
    ["Japanese_“discount”_button", "🈹"],
    ["Japanese_“free_of_charge”_button", "🈚"],
    ["Japanese_“prohibited”_button", "🈲"],
    ["Japanese_“acceptable”_button", "🉑"],
    ["Japanese_“application”_button", "🈸"],
    ["Japanese_“passing_grade”_button", "🈴"],
    ["Japanese_“vacancy”_button", "🈳"],
    ["Japanese_“congratulations”_button", "㊗️"],
    ["Japanese_“secret”_button", "㊙️"],
    ["Japanese_“open_for_business”_button", "🈺"],
    ["Japanese_“no_vacancy”_button", "🈵"],
    ["red_circle", "🔴"],
    ["orange_circle", "🟠"],
    ["yellow_circle", "🟡"],
    ["green_circle", "🟢"],
    ["blue_circle", "🔵"],
    ["purple_circle", "🟣"],
    ["brown_circle", "🟤"],
    ["black_circle", "⚫"],
    ["white_circle", "⚪"],
    ["red_square", "🟥"],
    ["orange_square", "🟧"],
    ["yellow_square", "🟨"],
    ["green_square", "🟩"],
    ["blue_square", "🟦"],
    ["purple_square", "🟪"],
    ["brown_square", "🟫"],
    ["black_large_square", "⬛"],
    ["white_large_square", "⬜"],
    ["black_medium_square", "◼️"],
    ["white_medium_square", "◻️"],
    ["black_medium-small_square", "◾"],
    ["white_medium-small_square", "◽"],
    ["black_small_square", "▪️"],
    ["white_small_square", "▫️"],
    ["large_orange_diamond", "🔶"],
    ["large_blue_diamond", "🔷"],
    ["small_orange_diamond", "🔸"],
    ["small_blue_diamond", "🔹"],
    ["red_triangle_pointed_up", "🔺"],
    ["red_triangle_pointed_down", "🔻"],
    ["diamond_with_a_dot", "💠"],
    ["radio_button", "🔘"],
    ["white_square_button", "🔳"],
    ["black_square_button", "🔲"],
    ["chequered_flag", "🏁"],
    ["triangular_flag", "🚩"],
    ["crossed_flags", "🎌"],
    ["black_flag", "🏴"],
    ["white_flag", "🏳️"],
    ["rainbow_flag", "🏳️‍🌈"],
    ["transgender_flag", "🏳️‍⚧️"],
    ["pirate_flag", "🏴‍☠️"],
    ["flag_Ascension_Island", "🇦🇨"],
    ["flag_Andorra", "🇦🇩"],
    ["flag_United_Arab_Emirates", "🇦🇪"],
    ["flag_Afghanistan", "🇦🇫"],
    ["flag_Antigua_&_Barbuda", "🇦🇬"],
    ["flag_Anguilla", "🇦🇮"],
    ["flag_Albania", "🇦🇱"],
    ["flag_Armenia", "🇦🇲"],
    ["flag_Angola", "🇦🇴"],
    ["flag_Antarctica", "🇦🇶"],
    ["flag_Argentina", "🇦🇷"],
    ["flag_American_Samoa", "🇦🇸"],
    ["flag_Austria", "🇦🇹"],
    ["flag_Australia", "🇦🇺"],
    ["flag_Aruba", "🇦🇼"],
    ["flag_Åland_Islands", "🇦🇽"],
    ["flag_Azerbaijan", "🇦🇿"],
    ["flag_Bosnia_&_Herzegovina", "🇧🇦"],
    ["flag_Barbados", "🇧🇧"],
    ["flag_Bangladesh", "🇧🇩"],
    ["flag_Belgium", "🇧🇪"],
    ["flag_Burkina_Faso", "🇧🇫"],
    ["flag_Bulgaria", "🇧🇬"],
    ["flag_Bahrain", "🇧🇭"],
    ["flag_Burundi", "🇧🇮"],
    ["flag_Benin", "🇧🇯"],
    ["flag_St._Barthélemy", "🇧🇱"],
    ["flag_Bermuda", "🇧🇲"],
    ["flag_Brunei", "🇧🇳"],
    ["flag_Bolivia", "🇧🇴"],
    ["flag_Caribbean_Netherlands", "🇧🇶"],
    ["flag_Brazil", "🇧🇷"],
    ["flag_Bahamas", "🇧🇸"],
    ["flag_Bhutan", "🇧🇹"],
    ["flag_Bouvet_Island", "🇧🇻"],
    ["flag_Botswana", "🇧🇼"],
    ["flag_Belarus", "🇧🇾"],
    ["flag_Belize", "🇧🇿"],
    ["flag_Canada", "🇨🇦"],
    ["flag_Cocos_(Keeling)_Islands", "🇨🇨"],
    ["flag_Congo_-_Kinshasa", "🇨🇩"],
    ["flag_Central_African_Republic", "🇨🇫"],
    ["flag_Congo_-_Brazzaville", "🇨🇬"],
    ["flag_Switzerland", "🇨🇭"],
    ["flag_Côte_d’Ivoire", "🇨🇮"],
    ["flag_Cook_Islands", "🇨🇰"],
    ["flag_Chile", "🇨🇱"],
    ["flag_Cameroon", "🇨🇲"],
    ["flag_China", "🇨🇳"],
    ["flag_Colombia", "🇨🇴"],
    ["flag_Clipperton_Island", "🇨🇵"],
    ["flag_Costa_Rica", "🇨🇷"],
    ["flag_Cuba", "🇨🇺"],
    ["flag_Cape_Verde", "🇨🇻"],
    ["flag_Curaçao", "🇨🇼"],
    ["flag_Christmas_Island", "🇨🇽"],
    ["flag_Cyprus", "🇨🇾"],
    ["flag_Czechia", "🇨🇿"],
    ["flag_Germany", "🇩🇪"],
    ["flag_Diego_Garcia", "🇩🇬"],
    ["flag_Djibouti", "🇩🇯"],
    ["flag_Denmark", "🇩🇰"],
    ["flag_Dominica", "🇩🇲"],
    ["flag_Dominican_Republic", "🇩🇴"],
    ["flag_Algeria", "🇩🇿"],
    ["flag_Ceuta_&_Melilla", "🇪🇦"],
    ["flag_Ecuador", "🇪🇨"],
    ["flag_Estonia", "🇪🇪"],
    ["flag_Egypt", "🇪🇬"],
    ["flag_Western_Sahara", "🇪🇭"],
    ["flag_Eritrea", "🇪🇷"],
    ["flag_Spain", "🇪🇸"],
    ["flag_Ethiopia", "🇪🇹"],
    ["flag_European_Union", "🇪🇺"],
    ["flag_Finland", "🇫🇮"],
    ["flag_Fiji", "🇫🇯"],
    ["flag_Falkland_Islands", "🇫🇰"],
    ["flag_Micronesia", "🇫🇲"],
    ["flag_Faroe_Islands", "🇫🇴"],
    ["flag_France", "🇫🇷"],
    ["flag_Gabon", "🇬🇦"],
    ["flag_United_Kingdom", "🇬🇧"],
    ["flag_Grenada", "🇬🇩"],
    ["flag_Georgia", "🇬🇪"],
    ["flag_French_Guiana", "🇬🇫"],
    ["flag_Guernsey", "🇬🇬"],
    ["flag_Ghana", "🇬🇭"],
    ["flag_Gibraltar", "🇬🇮"],
    ["flag_Greenland", "🇬🇱"],
    ["flag_Gambia", "🇬🇲"],
    ["flag_Guinea", "🇬🇳"],
    ["flag_Guadeloupe", "🇬🇵"],
    ["flag_Equatorial_Guinea", "🇬🇶"],
    ["flag_Greece", "🇬🇷"],
    ["flag_South_Georgia_&_South_Sandwich_Islands", "🇬🇸"],
    ["flag_Guatemala", "🇬🇹"],
    ["flag_Guam", "🇬🇺"],
    ["flag_Guinea-Bissau", "🇬🇼"],
    ["flag_Guyana", "🇬🇾"],
    ["flag_Hong_Kong_SAR_China", "🇭🇰"],
    ["flag_Heard_&_McDonald_Islands", "🇭🇲"],
    ["flag_Honduras", "🇭🇳"],
    ["flag_Croatia", "🇭🇷"],
    ["flag_Haiti", "🇭🇹"],
    ["flag_Hungary", "🇭🇺"],
    ["flag_Canary_Islands", "🇮🇨"],
    ["flag_Indonesia", "🇮🇩"],
    ["flag_Ireland", "🇮🇪"],
    ["flag_Israel", "🇮🇱"],
    ["flag_Isle_of_Man", "🇮🇲"],
    ["flag_India", "🇮🇳"],
    ["flag_British_Indian_Ocean_Territory", "🇮🇴"],
    ["flag_Iraq", "🇮🇶"],
    ["flag_Iran", "🇮🇷"],
    ["flag_Iceland", "🇮🇸"],
    ["flag_Italy", "🇮🇹"],
    ["flag_Jersey", "🇯🇪"],
    ["flag_Jamaica", "🇯🇲"],
    ["flag_Jordan", "🇯🇴"],
    ["flag_Japan", "🇯🇵"],
    ["flag_Kenya", "🇰🇪"],
    ["flag_Kyrgyzstan", "🇰🇬"],
    ["flag_Cambodia", "🇰🇭"],
    ["flag_Kiribati", "🇰🇮"],
    ["flag_Comoros", "🇰🇲"],
    ["flag_St._Kitts_&_Nevis", "🇰🇳"],
    ["flag_North_Korea", "🇰🇵"],
    ["flag_South_Korea", "🇰🇷"],
    ["flag_Kuwait", "🇰🇼"],
    ["flag_Cayman_Islands", "🇰🇾"],
    ["flag_Kazakhstan", "🇰🇿"],
    ["flag_Laos", "🇱🇦"],
    ["flag_Lebanon", "🇱🇧"],
    ["flag_St._Lucia", "🇱🇨"],
    ["flag_Liechtenstein", "🇱🇮"],
    ["flag_Sri_Lanka", "🇱🇰"],
    ["flag_Liberia", "🇱🇷"],
    ["flag_Lesotho", "🇱🇸"],
    ["flag_Lithuania", "🇱🇹"],
    ["flag_Luxembourg", "🇱🇺"],
    ["flag_Latvia", "🇱🇻"],
    ["flag_Libya", "🇱🇾"],
    ["flag_Morocco", "🇲🇦"],
    ["flag_Monaco", "🇲🇨"],
    ["flag_Moldova", "🇲🇩"],
    ["flag_Montenegro", "🇲🇪"],
    ["flag_St._Martin", "🇲🇫"],
    ["flag_Madagascar", "🇲🇬"],
    ["flag_Marshall_Islands", "🇲🇭"],
    ["flag_North_Macedonia", "🇲🇰"],
    ["flag_Mali", "🇲🇱"],
    ["flag_Myanmar_(Burma)", "🇲🇲"],
    ["flag_Mongolia", "🇲🇳"],
    ["flag_Macao_SAR_China", "🇲🇴"],
    ["flag_Northern_Mariana_Islands", "🇲🇵"],
    ["flag_Martinique", "🇲🇶"],
    ["flag_Mauritania", "🇲🇷"],
    ["flag_Montserrat", "🇲🇸"],
    ["flag_Malta", "🇲🇹"],
    ["flag_Mauritius", "🇲🇺"],
    ["flag_Maldives", "🇲🇻"],
    ["flag_Malawi", "🇲🇼"],
    ["flag_Mexico", "🇲🇽"],
    ["flag_Malaysia", "🇲🇾"],
    ["flag_Mozambique", "🇲🇿"],
    ["flag_Namibia", "🇳🇦"],
    ["flag_New_Caledonia", "🇳🇨"],
    ["flag_Niger", "🇳🇪"],
    ["flag_Norfolk_Island", "🇳🇫"],
    ["flag_Nigeria", "🇳🇬"],
    ["flag_Nicaragua", "🇳🇮"],
    ["flag_Netherlands", "🇳🇱"],
    ["flag_Norway", "🇳🇴"],
    ["flag_Nepal", "🇳🇵"],
    ["flag_Nauru", "🇳🇷"],
    ["flag_Niue", "🇳🇺"],
    ["flag_New_Zealand", "🇳🇿"],
    ["flag_Oman", "🇴🇲"],
    ["flag_Panama", "🇵🇦"],
    ["flag_Peru", "🇵🇪"],
    ["flag_French_Polynesia", "🇵🇫"],
    ["flag_Papua_New_Guinea", "🇵🇬"],
    ["flag_Philippines", "🇵🇭"],
    ["flag_Pakistan", "🇵🇰"],
    ["flag_Poland", "🇵🇱"],
    ["flag_St._Pierre_&_Miquelon", "🇵🇲"],
    ["flag_Pitcairn_Islands", "🇵🇳"],
    ["flag_Puerto_Rico", "🇵🇷"],
    ["flag_Palestinian_Territories", "🇵🇸"],
    ["flag_Portugal", "🇵🇹"],
    ["flag_Palau", "🇵🇼"],
    ["flag_Paraguay", "🇵🇾"],
    ["flag_Qatar", "🇶🇦"],
    ["flag_Réunion", "🇷🇪"],
    ["flag_Romania", "🇷🇴"],
    ["flag_Serbia", "🇷🇸"],
    ["flag_Russia", "🇷🇺"],
    ["flag_Rwanda", "🇷🇼"],
    ["flag_Saudi_Arabia", "🇸🇦"],
    ["flag_Solomon_Islands", "🇸🇧"],
    ["flag_Seychelles", "🇸🇨"],
    ["flag_Sudan", "🇸🇩"],
    ["flag_Sweden", "🇸🇪"],
    ["flag_Singapore", "🇸🇬"],
    ["flag_St._Helena", "🇸🇭"],
    ["flag_Slovenia", "🇸🇮"],
    ["flag_Svalbard_&_Jan_Mayen", "🇸🇯"],
    ["flag_Slovakia", "🇸🇰"],
    ["flag_Sierra_Leone", "🇸🇱"],
    ["flag_San_Marino", "🇸🇲"],
    ["flag_Senegal", "🇸🇳"],
    ["flag_Somalia", "🇸🇴"],
    ["flag_Suriname", "🇸🇷"],
    ["flag_South_Sudan", "🇸🇸"],
    ["flag_São_Tomé_&_Príncipe", "🇸🇹"],
    ["flag_El_Salvador", "🇸🇻"],
    ["flag_Sint_Maarten", "🇸🇽"],
    ["flag_Syria", "🇸🇾"],
    ["flag_Eswatini", "🇸🇿"],
    ["flag_Tristan_da_Cunha", "🇹🇦"],
    ["flag_Turks_&_Caicos_Islands", "🇹🇨"],
    ["flag_Chad", "🇹🇩"],
    ["flag_French_Southern_Territories", "🇹🇫"],
    ["flag_Togo", "🇹🇬"],
    ["flag_Thailand", "🇹🇭"],
    ["flag_Tajikistan", "🇹🇯"],
    ["flag_Tokelau", "🇹🇰"],
    ["flag_Timor-Leste", "🇹🇱"],
    ["flag_Turkmenistan", "🇹🇲"],
    ["flag_Tunisia", "🇹🇳"],
    ["flag_Tonga", "🇹🇴"],
    ["flag_Turkey", "🇹🇷"],
    ["flag_Trinidad_&_Tobago", "🇹🇹"],
    ["flag_Tuvalu", "🇹🇻"],
    ["flag_Taiwan", "🇹🇼"],
    ["flag_Tanzania", "🇹🇿"],
    ["flag_Ukraine", "🇺🇦"],
    ["flag_Uganda", "🇺🇬"],
    ["flag_U.S._Outlying_Islands", "🇺🇲"],
    ["flag_United_Nations", "🇺🇳"],
    ["flag_United_States", "🇺🇸"],
    ["flag_Uruguay", "🇺🇾"],
    ["flag_Uzbekistan", "🇺🇿"],
    ["flag_Vatican_City", "🇻🇦"],
    ["flag_St._Vincent_&_Grenadines", "🇻🇨"],
    ["flag_Venezuela", "🇻🇪"],
    ["flag_British_Virgin_Islands", "🇻🇬"],
    ["flag_U.S._Virgin_Islands", "🇻🇮"],
    ["flag_Vietnam", "🇻🇳"],
    ["flag_Vanuatu", "🇻🇺"],
    ["flag_Wallis_&_Futuna", "🇼🇫"],
    ["flag_Samoa", "🇼🇸"],
    ["flag_Kosovo", "🇽🇰"],
    ["flag_Yemen", "🇾🇪"],
    ["flag_Mayotte", "🇾🇹"],
    ["flag_South_Africa", "🇿🇦"],
    ["flag_Zambia", "🇿🇲"],
    ["flag_Zimbabwe", "🇿🇼"],
    ["flag_England", "🏴󠁧󠁢󠁥󠁮󠁧󠁿"],
    ["flag_Scotland", "🏴󠁧󠁢󠁳󠁣󠁴󠁿"],
    ["flag_Wales", "🏴󠁧󠁢󠁷󠁬󠁳󠁿"],
];
export default EMOJIS
